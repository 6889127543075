import React from "react";
import {DeleteFilled} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import api from "../../../../services/api";
import {notification} from "antd";
import {events} from "../../../../../../system/events";
import {EVENTS} from "../../../../services/constants";

function CommentItem({comment, ticket, removeComment}) {
    const message = _.get(comment, "comment", {});
    const creator = _.get(comment, "creator", {});

    function handleDelete(e) {
        e.preventDefault();
        api.deleteComment(ticket.id, message._id)
            .then(res => {
                removeComment(message._id);
                events.dispatch(EVENTS.DELETE_COMMENT);
                notification.success({message: "Xóa bình luận thành công!"});
            })
            .catch(err => {
                notification.error({message: "Xóa bình luận không thành công!"});
            });
    }

    function urlify(comment) {
        if (comment) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            const urlImgeRegex = /https?:\/\/.*?\.(?:png|jpg|jpeg|gif)/ig;
            return comment.replace(urlRegex, function (url) {
                if (url.match(urlImgeRegex) !== null) {
                    return '<br/><a target="_blank" href="' + url + '"><img class="image-comment" src="' + url + '"></a><br/>';
                }
                return '<a target="_blank" href="' + url + '">' + url + '</a>';
            });
        }
        return comment;

    }

    return (
        <div className="log-item" key={comment.id}>
            {
                creator.avatar ?
                    <span><img src={creator.avatar} alt={creator.name || creator.username}/></span> :
                    <span>{(creator.name || creator.username).substr(0, 1)}</span>
            }
            <div>
                <p>
                    <i><strong>{creator.name || creator.username}</strong>, {moment(message.created_at).format("HH:mm DD/MM/YYYY")}</i>
                    {
                        comment.canRemove &&
                        <a href="#" onClick={handleDelete}><DeleteFilled /></a>
                    }
                </p>
                <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: urlify(message.content)}}/>
            </div>
        </div>
    );
}

export default CommentItem;
