import React, {useEffect, useState} from "react";
import {Row, Col, notification} from "antd";
import TicketDetail from "./components/TicketDetail";
import FollowedMembers from "./components/FollowedMembers";
import api from "../../services/api";
import _ from "lodash";
import {events} from "../../../../system/events";
import {EVENTS} from "../../../../layouts/constants";
import {url} from "../../../../system/routing";
import {useTranslation} from "react-i18next";
import TicketComments from "./components/TicketComments";
import TicketLogs from "./components/TicketLogs";
import TicketMiddle from "./components/TicketDetail/TicketMiddle";
import useProject from "../../../Project/hooks/useProject";
import RateStar from "./components/RateStar";
import ServicesProject from "../../../Project/services/ServicesProject";

function DetailTicket(props) {
    const {t} = useTranslation();
    const {match} = props;
    const {params} = match;
    const [data, setData] = useState({});
    const [projectInfo, setProjectInfo] = useState({});
    const [updateFollower, setUpdateFollower] = useState(false);
    const { project, userCan, settings } = projectInfo;
    const {ticket} = data;

    useEffect(() => {
        getTicket();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(ticket) && !_.isEmpty(project)) {
            document.title = `#${ticket.id} ${ticket.subject} - ${project.name}`;
        }
    }, [ticket, project]);

    function getTicket() {
        api.getTicket(params.id)
            .then(res => {
                const data = _.get(res, "data", {});
                const projectId = _.get(data, "project.id", null);
                getProjectInfo(projectId);
                setData(data);
            })
            .catch(err => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    url.redirectTo('error-403');
                else if (status === 404)
                    url.redirectTo('error-404');
                else
                    notification.error({message: "Tải dữ liệu ticket không thành công!"})
            });
    }

    function getProjectInfo(project_id) {
        ServicesProject.list(project_id)
            .then(res => {
                setProjectInfo(res);
            })
    }
    const reloadFollower = () => {
        setUpdateFollower(!updateFollower)
    };

    function updateTicket(ticket) {
        setData({...data, ...ticket});
    }
    useEffect(() => {
        if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
            events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
            events.dispatch(EVENTS.HEADER_BREADCRUMB, [
                {
                    name: t('ticket:breadcrumb.manage_project'),
                    url: url.to('manage-project'),
                },
                {
                    name: t('ticket:label.project_id') + ': ' + project.name,
                    url: url.to('list-tickets', { id: project.id }),
                },
                {
                    name: t("title.detail_ticket"),
                },
            ]);
        }
    }, [project, userCan]);

    if (_.isEmpty(data) || !userCan)
        return null;

    return (
        <div className="bg-white detail-ticket">
            <Row>
                <Col xs={{span: 24}} md={{span: 12}}>
                    <div className="site-content left-detail-ticket">
                        <TicketDetail
                            data={data}
                            updateTicket={updateTicket}
                            reloadFollower={reloadFollower}
                            userCan={userCan}
                        />
                        <FollowedMembers
                            ticketId={params.id}
                            isUpdated={updateFollower}
                            userCan={userCan}
                            projectId={project.id}
                        />
                        <RateStar
                            data={data}
                            getTicket={getTicket}
                        />
                    </div>
                </Col>
                <Col xs={{span: 24}} md={{span: 6}}>
                    <TicketMiddle
                        getTicket={getTicket}
                        data={data}
                        updateTicket={updateTicket}
                        userCan={userCan}
                        settings={settings}
                    />
                </Col>
                <Col xs={{span: 24}} md={{span: 6}} className="bg-white right-detail-ticket">
                    {
                        ticket &&
                        <>
                            <TicketComments
                                ticket={ticket}
                                userCan={userCan}
                            />
                            <TicketLogs
                                ticket={ticket}
                                userCan={userCan}
                            />
                        </>
                    }
                </Col>
            </Row>
        </div>
    );
}

export default DetailTicket;
