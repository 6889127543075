import React, {useState} from 'react';
import {DingtalkCircleFilled} from "@ant-design/icons";
import {Button, notification} from "antd";
import api from '../services/api'
import lodash from 'lodash';
import {useTranslation} from "react-i18next";
import withSizes from "react-sizes";

const mapSizesToProps = ({width}) => ({
    isMobile: width < 580
});

function ConnectDingTalkAll({projects, setErrorsDingTalk, updateProjects, isMobile}) {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const project_ids = [];

    projects.forEach(item => {
        if (!item.dingTalk)
            project_ids.push(item.id)
    });

    function connectDingtalkAll() {
        setErrorsDingTalk([]);
        setLoading(true);
        api.connectDingTalk({ids: project_ids}).then((res) => {
            const errors = lodash.get(res, "data.errors", {});
            const projectUpdateInfo = lodash.get(res, "data.projects", {});
            updateProjects(projectUpdateInfo);
            setErrorsDingTalk(errors);
        }).catch(() => {
            notification.error({message: t("message.server_error")})
        }).finally(() => {
            setLoading(false);
        });
    }

    if (project_ids.length === 0)
        return null;

    return (
        <Button
            icon={<DingtalkCircleFilled style={{color: "blue"}}/>}
            onClick={() => connectDingtalkAll()}
            loading={loading}
        >
            {isMobile ? "" : "Kết nối tất cả"}
        </Button>
    );
}

export default withSizes(mapSizesToProps)(ConnectDingTalkAll);
