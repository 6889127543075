import React, {useState, useEffect} from 'react';
import {Rate, Button, Modal, Form, Input, notification} from 'antd';
import {useTranslation} from "react-i18next";
import api from '../../services/api';

function ModalRateStar(props) {
    const {visible, ticket, setVisible} = props;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [form] = Form.useForm();
    const initialValues = {
        rate_star: ticket.rate_star,
        rate_note: ticket.rate_note
    };

    useEffect(() => {
        setDisabled(ticket.rate_star <= 0);
    }, [ticket.rate_star]);

    function handleOk() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                api.addRateStar(ticket.id, values).then(res => {
                    setVisible(false);
                    props.getTicket();
                    notification.success({message: t("ticket:messages.add_rate_ticket_success")})
                }).catch(() => {
                    notification.error({message: t("message.server_error")})
                }).finally(() => {
                    setLoading(false);
                })
            });
    }

    function handleCancel() {
        setVisible(false);
        form.resetFields();
    }

    const handleOnChangeRate = (value) => {
        setDisabled(!value);
    };

    return (
        <>
            {
                visible &&
                <Modal
                    title={t("ticket:title.rate_star").toUpperCase()}
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    confirmLoading={loading}
                    footer={[
                        <Button disabled={disabled} key={"ok"} type="primary"
                                onClick={handleOk}>{t("btn.send")}</Button>,
                    ]}
                    maskClosable={false}
                >
                    <div className="logo-goticket-rate-star"/>
                    <Form size="default" form={form} className="text-center" initialValues={initialValues}>
                        <Form.Item name="rate_star" rules={[{type: "integer"}]}>
                            <Rate style={{fontSize: 36}}
                                  onChange={(value) => handleOnChangeRate(value)}/>
                        </Form.Item>
                        <Form.Item name="rate_note">
                            <Input.TextArea
                                type="text"
                                placeholder={t("ticket:placeholder.enter_comment_rate_star")}
                                autoSize={{minRows: 3}}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ModalRateStar;