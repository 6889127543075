import React, {useEffect} from "react";
import {events} from "../../../../system/events";
import {EVENTS} from "../../../../layouts/constants";
import {Link, url} from "../../../../system/routing";
import {t} from "../../../../system/i18n";
import StatusSetting from "./StatusSetting";
import TypeSetting from "./SettingItems/TypeSetting";
import ScopeSetting from "./SettingItems/ScopeSetting";
import PrioritySetting from "./SettingItems/PrioritySetting";
import useProject from "../../hooks/useProject";
import _ from "lodash";
import Error403 from "../../../App/Error/403";
import ServicesStatuses from '../../../TicketStatus/services/ServicesStatuses';
import ServicesTypes from '../../../TicketTypes/services/ServicesTypes';
import ServicesScopes from '../../../TicketScopes/services/ServicesScopes';
import ServicesPriorities from '../../../TicketPriorities/services/ServicesPriorities';

const categories = [
    {
        key: "status",
        name: t("settings:title.status")
    },
    {
        key: "type",
        name: t("settings:title.type")
    },
    {
        key: "scope",
        name: t("settings:title.scope")
    },
    {
        key: "priority",
        name: t("settings:title.priority")
    }
];

function Settings({match}) {
    const {params} = match;
    const { project, userCan, settings} = useProject(params.id);
    useEffect(() => {
        if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
            events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
            events.dispatch(EVENTS.HEADER_BREADCRUMB, [
                {
                    name: t('ticket:breadcrumb.manage_project'),
                    url: url.to('manage-project')
                },
                {
                    name: t('ticket:label.project_id') + ': ' + project.name,
                    url: url.to('list-tickets', { id: project.id })
                },
                {
                    name: t("breadcrumb.category_settings")
                }
            ]);
        }
    }, [project, userCan]);

    function updateCategoryService(categoryService) {
        categoryService.fetching = true;
    }

    function renderSettings() {
        switch (params.type) {
			case 'type':
                document.title = `${project.name} - ${t('settings:title.type')}`;
				return (
					<TypeSetting
						project_id={params.id}
						title={t('settings:title.type_list')}
						updateCategoryService={() => updateCategoryService(ServicesTypes)}
					/>
				);
			case 'scope':
                document.title = `${project.name} - ${t('settings:title.scope')}`;
				return (
					<ScopeSetting
                        settings={settings}
						project_id={params.id}
						title={t('settings:title.scope_list')}
						updateCategoryService={() => updateCategoryService(ServicesScopes)}
					/>
				);
			case 'priority':
                document.title = `${project.name} - ${t('settings:title.priority')}`;
				return (
					<PrioritySetting
                        settings={settings}
						project_id={params.id}
						title={t('settings:title.priority_list')}
						updateCategoryService={() => updateCategoryService(ServicesPriorities)}
					/>
				);
			default:
                document.title = `${project.name} - ${t('settings:title.status')}`;
				return (
					<StatusSetting
						project_id={params.id}
						updateCategoryService={() => updateCategoryService(ServicesStatuses)}
					/>
				);
        }
    }

    if (userCan === null)
        return null;

    if (userCan && !userCan.configCategory)
        return <Error403 />;

    return (
        <div className="site-content">
            <div className="mb-3 nav-settings">
                {
                    categories.map(item => {
                        return (
                            <Link
                                key={item.key}
                                className={params.type === item.key ? "active" : ""}
                                href={url.to('category-settings', {id: params.id, type: item.key})}
                            >{item.name}</Link>
                        );
                    })
                }
            </div>

            { renderSettings() }
        </div>
    );
}

export default Settings;
