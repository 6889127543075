import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServicesPriorities from "../services/ServicesPriorities";
import SelectMembers from "../../Member/components/SelectMembers";
import lodash from 'lodash';

const {Option} = Select;

export default function SelectPriorities(props) {
    let [priorities, setPriorities] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const {project_id} = props;

    useEffect(() => {
        setIsLoading(true);
        ServicesPriorities.list(project_id)
            .then(res => {
                setPriorities(res);
                if (props.action === "add") {
                    props.onChange(lodash.get(lodash.find(res, {'is_default': true}), "id", undefined));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(priorities.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                priorities.length > 0 &&
                priorities
                    .filter(priority => priority.is_active)
                    .map(opt => {
                        return <Option key={opt.id} value={opt.id}>{opt.name}</Option>;
                    })
            }
        </Select>
    );
}
