import {api} from '../../../system/api';
import {PREFIX_URL} from "../../../system/api/constants";

export default {
    createTicket: (project_id, params) => api.post(PREFIX_URL.PROJECT + `/${project_id}/tickets`, params),
    getTicket: id => api.get(PREFIX_URL.TICKET + `/${id}`),
    updateStatus: (id, params) => api.put(PREFIX_URL.TICKET + `/${id}/status`, params),
    updateTicket: (id, params) => api.put(PREFIX_URL.TICKET + `/${id}`, params),
    followTicket: (ticket_id, user_id) => api.put(`/tickets/${ticket_id}/followers/${user_id}`),
    unFollowTicket: (ticket_id, user_id) => api.delete(`/tickets/${ticket_id}/followers/${user_id}`),
    getListFollower: (ticket_id) => api.get(`/tickets/${ticket_id}/followers`),
    getTickets: (id, params) => api.get(PREFIX_URL.PROJECT + `/${id}/tickets`, {
        params: params,
        singleRequest: true,
        loading: false
    }),
    getStats: (id, params) => api.get(PREFIX_URL.PROJECT + `/${id}/tickets-stats`, {
        params: params,
        singleRequest: true,
        loading: false
    }),
    deleteAttachment: (ticket_id, id) => api.delete(PREFIX_URL.TICKET + `/${ticket_id}/attachments/${id}`, {}, {loading: false}),
    updateAttachments: (ticket_id, params) => api.post(PREFIX_URL.TICKET + `/${ticket_id}/attachments`, params),
    addComment: (ticket_id, params) => api.post(PREFIX_URL.TICKET + `/${ticket_id}/comments`, params),
    getComments: ticket_id => api.get(PREFIX_URL.TICKET + `/${ticket_id}/comments`),
    deleteComment: (ticket_id, comment_id) => api.delete(PREFIX_URL.TICKET + `/${ticket_id}/comments/${comment_id}`, {}, {loading: false}),
    getLogs: ticket_id => api.get(PREFIX_URL.TICKET + `/${ticket_id}/logs`),
    addRateStar: (ticket_id, params) => api.put(PREFIX_URL.TICKET + `/${ticket_id}/rate`, params),
    getMembersCanFollowTicket: (ticketId) => api.get(PREFIX_URL.TICKET + `/${ticketId}/users/can-follow`),
    getProjectTaiga: (ticketId) => api.get(`/tickets/${ticketId}/taiga/projects`, {singleRequest: true, loading: false}),
    createUsTaiga: (ticketId, data) => api.post(`/tickets/${ticketId}/taiga/userstories`, data),
    exportTickets: (projectId, params) => api.get(`/projects/${projectId}/tickets/export`, {params, singleRequest: true, loading: false})
};
