import React, { useState, useEffect } from 'react';
import { Button, notification } from 'antd';
import api from '../../../../services/api';
import { auth } from '../../../../../Auth/services';
import { useTranslation } from 'react-i18next';
import {LikeOutlined} from "@ant-design/icons";
import { EVENTS } from '../../../../services/constants';
import { events } from '../../../../../../system/events';

function FollowTicket({ data, reloadFollower, updateTicket }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [isFollowing, setIsFollowing] = useState(data.isFollowing);

	const ticketId = data.ticket.id;
	const userInfo = auth.user();

    useEffect(() => {
        return events.listen([EVENTS.UPDATE_FOLLOWING_STATE], (event, payload) => {
			setIsFollowing(payload);
		});
    }, []);

	const onClickFollow = () => {
		setLoading(true);

		const apiCalled = isFollowing
			? api.unFollowTicket(ticketId, userInfo.id)
			: api.followTicket(ticketId, userInfo.id);

		apiCalled
			.then(() => {
				setIsFollowing(!isFollowing);
				reloadFollower();
				events.dispatch(EVENTS.UPDATE_FOLLOWERS);
				if (updateTicket) {
					updateTicket({...data, isFollowing: !isFollowing});
				}
			})
			.catch(() => {
				notification.error({ message: t('message.server_error') });
			})
			.finally(() => setLoading(false));
	};

	return (
		<Button className="follow_btn" type="primary" onClick={onClickFollow}>
            <LikeOutlined /> {isFollowing ? t("ticket:btn.unfollow") : t("ticket:btn.follow")}
        </Button>
	);
}

export default FollowTicket;
