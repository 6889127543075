import React, {useEffect, useState} from "react";
import {Form, Select, Input, Button, Col, Row, notification} from "antd";
import UploadAvatar from "../../../App/Upload/UploadAvatar"
import {useTranslation} from "react-i18next";
import api from "../../services/api";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import {url} from "../../../../system/routing";
import _ from "lodash";
import {EditOutlined, UndoOutlined} from "@ant-design/icons";
import apiAuth from "../../../Auth/services/api";
import {auth} from "../../../Auth/services";

const { Option } = Select;
const { TextArea } = Input;

// list of rules for every input
const validatedRules = {
    name: [{required: true, max: 255, type: "string"}],
    is_public: [{required: true}]
};

function CreateProject(props) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [form] = Form.useForm();
    const validateMessages = useValidatedMessages();
    const user = auth.user();

    useEffect(() => {
        document.title = t("menu.create_project");
        form.setFields([{ name: "is_public", value: 0 }]);
    }, []);

    const updateUserInfo = () => {
        apiAuth.user().then(res => {
            auth.setUser(res.data.user);
        });
    };

    function handleSubmit() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                let formData = new FormData();
                for (const prop in values) {
                    if (values[prop] !== undefined)
                        formData.append(prop, values[prop]);
                }

                api.createProject(formData)
                    .then(res => {
                        let project = _.get(res, "data.project", {});
                        updateUserInfo();
                        notification.success({message: t("project:messages.create_success")});
                        reset();
                        setImageUrl("");
                        url.redirectTo('list-tickets', {id: project.id});
                    })
                    .catch(error => {
                        if (error.response.status === 403){
                            return notification.error({message: t("project:messages.project_limited_is_zero")});
                        }
                        const responseData = _.get(error, "response.data", undefined);
                        if (responseData && responseData.code === "INPUT_INVALID") {
                            if (responseData.data.project_limited) {
                                notification.error({message: t("project:messages.project_limited." + (_.keys(responseData.data.project_limited)[0]))})
                            }
                        } else {
                            notification.error({message: t("project:messages.create_fail")});
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    }

    function handleUpload(file) {
        form.setFields([{name: "logo", value: file}]);
    }

    function onReset() {
        reset();
        setImageUrl("");
    }

    function reset() {
        form.resetFields();
        form.setFields([{name: "is_public", value: 0}]);
    }

    return (
        <Row>
            <Col md={{offset: 4, span: 16}}>
                <h1 className="a-list--title a-text--uppercase text-center mb-3">{t('project:label.create_project').toUpperCase()}</h1>
                <Form
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    validateMessages={validateMessages}
                    size="default"
                    form={form}
                >
                    <Row gutter={{md: 20}}>
                        <Col xs={24} sm={24} md={18} lg={18}>
                            <Form.Item
                                name="name"
                                label={t('project:label.name')}
                                rules={validatedRules.name}
                            >
                                <Input
                                    placeholder={t('project:placeholder.name')}
                                    name="name"
                                />
                            </Form.Item>

                            <Form.Item
                                name="is_public"
                                label={t('project:label.is_public')}
                                rules={validatedRules.is_public}
                            >
                                <Select
                                    name="is_public"
                                    placeholder={t('project:placeholder.is_public')}
                                >
                                    <Option value={0}>Private</Option>
                                    <Option value={1}>Public</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label={t('project:label.description')}
                                rules={[{type: "string"}]}
                            >
                                <TextArea
                                    row={4}
                                    placeholder={t('project:placeholder.description')}
                                    name="description"
                                    style={{height: "150px"}}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={{offset: 8, span: 16}} md={{offset: 0, span: 6}}>
                            <Form.Item name="logo">
                                <UploadAvatar
                                    onChange={handleUpload}
                                    imageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                    accept="image/jpeg, image/png"
                                    listType="picture-card"
                                    showUploadList={false}
                                    maxFileSize={5}
                                    className="upload-logo"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={18} lg={18}>
                            <Row>
                                <Col md={{offset: 6}}>
                                    {
                                        user.project_limit > 0 && user.project_count < user.project_limit &&
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={handleSubmit}
                                                loading={loading}
                                                className="pl-4 pr-4 mr-2"
                                            >
                                                <EditOutlined /> {t("project:btn.create")}
                                            </Button>
                                    }
                                    <Button
                                        htmlType="reset"
                                        onClick={onReset}
                                        className="pl-4 pr-4"
                                    >
                                        <UndoOutlined /> {t("common:btn.reset_form")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
}

export default CreateProject;
