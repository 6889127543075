import React, {useEffect, useState} from 'react';
import lodash from 'lodash';
import {Select} from 'antd';
import ServicesDepartments from '../services/ServicesDepartments';

const {Option} = Select;

export default function SelectDepartment(props) {
    let [departments, setDepartments] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const {project_id} = props;

    useEffect(() => {
        setIsLoading(true);
        ServicesDepartments.list(project_id)
            .then((res) => {
                setDepartments(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return (
        <Select
            loading={isLoading}
            {...props}
            value={departments.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {departments.length > 0 &&
            departments
                .filter((priority) => priority.is_active)
                .map((opt) => <Option key={opt.id} value={opt.id}>{opt.name}</Option>)
            })
        </Select>
    );
}
