import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    notification, Checkbox
} from "antd";
import api from "../../services/api";
import {PlusOutlined} from "@ant-design/icons";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import UploadMulti from "../../../App/Upload/UploadMulti";
import SelectMembers from "../../../Member/components/SelectMembers";
import SelectPriorities from "../../../TicketPriorities/components/SelectPriorities";
import SelectTypes from "../../../TicketTypes/components/SelectTypes";
import SelectScopes from "../../../TicketScopes/components/SelectScopes";
import moment from "moment";
import _ from "lodash";
import {events} from '../../../../system/events';
import {EVENTS} from "../../services/constants";
import {EVENTS_MEMBERS} from "../../../Member/services/constants";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectDepartment from "../../../Department/components/SelectDepartment";
import SelectMembersDepartment from "../../../Department/components/SelectMembersDepartment";

const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};
const tailLayout = {
    wrapperCol: {offset: 6, span: 18},
};
const EditorConfig = {
    // plugins: [ Paragraph, Bold, Italic, Essentials ],
    toolbar: ['bulletedList', 'numberedList', 'bold', 'italic', 'link', 'heading'],
    height: 200
};

// list of rules for every input
const validatedRules = {
    subject: [{required: true, max: 255, type: "string"}],
    type_id: [{required: true}],
    description: [],
    project_id: [],
    scope_id: [],
    priority_id: [],
    handler_id: [],
    department: [],
    expired_at: [],
    file: [],
    is_shared_internal: [{type: "integer"}]
};

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
}

function CreateTicket(props) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [departmentId, setDepartmentId] = useState(undefined);
    const [isMemberDepartment, setIsMemberDepartment] = useState(false);
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const validateMessages = useValidatedMessages();
    const {userCan, settings} = props;

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        form.resetFields();
        setVisible(false);
    }

    function handleSubmit(e) {
        form.validateFields()
            .then(values => {
                setLoading(true);
                let formData = new FormData();
                for (const prop in values) {
                    if (values[prop] !== undefined) {
                        if (prop === "file") {
                            values[prop].forEach(item => {
                                formData.append("file[]", item);
                            });
                        } else if (prop === "is_shared_internal") {
                            formData.append(prop, values[prop] ? 1 : 0);
                        } else {
                            formData.append(prop, values[prop]);
                        }
                    }
                }

                api.createTicket(props.project_id, formData)
                    .then(res => {
                        notification.success({message: t("ticket:messages.create_success")});
                        events.dispatch(EVENTS.NEW_TICKET_CREATED, _.get(res, "data", {}));
                        form.resetFields();
                        setVisible(false);
                    })
                    .catch(err => {
                        notification.error({message: t("ticket:messages.create_fail")});
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    }

    function handleUpload(file) {
        form.setFields([{name: "file", value: file}]);
    }

    function onReset() {
        form.resetFields();
    }

    function _onChange(event, editor) {
        let data = editor.getData();
        form.setFields([{name: "description", value: data}]);
    }

    function handleIsSharedInternal(e) {
        setIsMemberDepartment(e.target.checked);
        form.setFields([{name: "handler_id", value: undefined}]);
    }

    // Nếu không có quyền tạo ticket thì ẩn đi
    if (userCan === null || (userCan && !userCan.createTicket))
        return null;

    function onChangeDepartment(value) {
        setDepartmentId(value);
    }

    return (
        <>
            <Button type="primary" className="mt-2" onClick={showModal}><PlusOutlined/> Thêm mới ticket</Button>
            <Modal
                title="TẠO MỚI TICKET"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={650}
                footer={null}
                maskClosable={false}
            >
                <Form
                    initialValues={initialValues}
                    className={"form"}
                    validateMessages={validateMessages}
                    size="default"
                    form={form}
                    labelCol={formItemLayout.labelCol}
                    wrapperCol={formItemLayout.wrapperCol}
                    colon=":"
                >
                    <Form.Item
                        className="mb-3"
                        name="subject"
                        label={t('ticket:label.subject')}
                        rules={validatedRules.subject}
                    >
                        <Input
                            name="subject"
                            placeholder={t('ticket:placeholder.subject')}
                        />
                    </Form.Item>
                    {
                        !!_.get(settings, "ACTIVE_DEPARTMENT", 1) &&
                        <Form.Item
                            name="department_id"
                            label={t('ticket:label.department')}
                        >
                            <SelectDepartment
                                action={"add"}
                                project_id={props.project_id}
                                placeholder={t('ticket:placeholder.department')}
                                onChange={onChangeDepartment}
                            />
                        </Form.Item>
                    }
                    {
                        departmentId &&
                        <Form.Item
                            {...tailLayout}
                            name="is_shared_internal"
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={(value) => handleIsSharedInternal(value)}>{t('ticket:label.is_shared_internal')}</Checkbox>
                        </Form.Item>
                    }
                    <Form.Item
                        className="mb-3"
                        name="type_id"
                        label={t('ticket:label.type_id')}
                        rules={validatedRules.type_id}
                    >
                        <SelectTypes
                            action={"add"}
                            project_id={props.project_id}
                            placeholder={t('ticket:placeholder.type_id')}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-3 p-content"
                        name="description"
                        label={t('ticket:label.description')}
                        rules={validatedRules.description}
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            config={EditorConfig}
                            onChange={_onChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="file"
                        label={t('ticket:label.file')}
                        rules={validatedRules.file}
                    >
                        <UploadMulti
                            onChange={handleUpload}
                            accept=".doc,.docx,.xls,.xlsx,.pdf,image/jpeg,image/png,.mp4"
                            fileType={[
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "application/vnd.ms-excel",
                                "image/jpeg",
                                "image/png",
                            ]}
                            maxFileSize={10}
                            listType="text"
                            showUploadList={true}
                        />
                    </Form.Item>

                    <Form.Item
                        name="handler_id"
                        label={t('ticket:label.handler_id')}
                        rules={validatedRules.handler_id}
                        // labelCol={{span: 12}}
                        wrapperCol={{span: 18}}
                    >
                        {
                            isMemberDepartment ?
                                <SelectMembersDepartment
                                    departmentId={departmentId}
                                    placeholder={t('ticket:placeholder.handler_id')}
                                /> :
                                <SelectMembers
                                    project_id={props.project_id}
                                    placeholder={t('ticket:placeholder.handler_id')}
                                />
                        }
                    </Form.Item>

                    {
                        !!_.get(settings, "ACTIVE_SCOPE", 1) &&
                        <Form.Item
                            className="mb-3"
                            name="scope_id"
                            label={t('ticket:label.scope_id')}
                            rules={validatedRules.scope_id}
                            // labelCol={{span: 11}}
                            wrapperCol={{span: 18}}
                        >
                            <SelectScopes
                                action={"add"}
                                project_id={props.project_id}
                                placeholder={t('ticket:placeholder.scope_id')}
                            />
                        </Form.Item>
                    }
                    <Form.Item
                        name="expired_at"
                        label={t('ticket:label.expired_at')}
                        rules={validatedRules.expired_at}
                        // labelCol={{span: 12}}
                        wrapperCol={{span: 18}}
                    >
                        <DatePicker
                            placeholder="Chọn ngày"
                            style={{width: "100%"}}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    {
                        !!_.get(settings, "ACTIVE_PRIORITY", 1) &&
                        <Form.Item
                            name="priority_id"
                            label={t('ticket:label.priority_id')}
                            rules={validatedRules.priority_id}
                            // labelCol={{span: 11}}
                            wrapperCol={{span: 18}}
                        >
                            <SelectPriorities
                                action={"add"}
                                project_id={props.project_id}
                                placeholder={t('ticket:placeholder.priority_id')}
                            />
                        </Form.Item>
                    }

                    <Row className="mt-3">
                        <Col span={18} offset={6}>
                            <Button
                                htmlType={"submit"}
                                type="primary"
                                onClick={handleSubmit}
                                loading={loading}
                                style={{textTransform: "uppercase"}}
                            >{t("common:btn.create")}</Button>
                            <Button
                                htmlType={"reset"}
                                className="ml-2"
                                onClick={onReset}
                                style={{textTransform: "uppercase"}}
                            >{t("common:btn.reset_form")}</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default CreateTicket;
