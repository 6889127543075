import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServicesMember from "../services/ServicesMember";
import {useTranslation} from "react-i18next";

const {Option} = Select;

export default function SelectMembers(props) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const {project_id, has_not_assigned} = props;
    const {t} = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        ServicesMember.list(project_id)
            .then(res => {
                setData(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return (
        <Select
            loading={isLoading}
            {...props}
            value={(data.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                has_not_assigned && <Option key="not_assigned" value="not_assigned">{t('ticket:label.not_assigned')}</Option>
            }
            {
                data.length > 0 &&
                    data.map(opt => {
                        let {member} = opt;
                        return <Option key={member.id} value={member.id}>{member.name || member.username || member.email}</Option>;
                    })

            }
        </Select>
    );
}
