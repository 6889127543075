import {api} from '../../../system/api';
import {PREFIX_URL} from "../../../system/api/constants";

export default {
    getMembers: (project_id) => api.get(PREFIX_URL.PROJECT + `/${project_id}/members`),
    getLoginedMembers: (project_id) => api.get(PREFIX_URL.PROJECT + `/${project_id}/members?is_handler=1`),
    addMember: (project_id, data) => api.post(PREFIX_URL.PROJECT + `/${project_id}/members`, data),
    removeMember: (project_id, data) => api.put(PREFIX_URL.PROJECT + `/${project_id}/members/remove`, data),
    searchUser: (project_id, params) => api.get(PREFIX_URL.PROJECT + `/${project_id}/search/users`, {
        params,
        singleRequest: true,
        loading: false
    }),
    getRoles: (project_id) => api.get(PREFIX_URL.PROJECT + `/${project_id}/roles`),
    setRole: (project_id, data) => api.put(PREFIX_URL.PROJECT + `/${project_id}/roles`, data),
};
