import React, {useEffect, useState} from "react";
import {Input, notification} from "antd";
import {CloseOutlined, SaveOutlined, LoadingOutlined} from "@ant-design/icons";
import {url, Link} from "../../../../system/routing";
import api from "../../services/api";
import {t} from "../../../../system/i18n";
import _ from "lodash";

export default function ListRoles({roles, currentRole, addRoles, setCurrentRole}) {
    const [newRole, setNewRole] = useState("");
    const [loading, setLoading] = useState(false);

    function addRole() {
        setLoading(true);
        api.addRole(currentRole.project_id, {name: newRole})
            .then(res => {
                addRoles(_.get(res, "data.role", {}));
                setNewRole("");
                notification.success({message: t("roles:messages.create_success")})
            })
            .catch(err => {
                notification.error({message: t("roles:messages.create_fail")})
            })
            .finally(() => setLoading(false));
    }

    function onSave(e) {
        e.preventDefault();
        if (newRole.trim() !== "")
            addRole();
    }

    function onClear(e) {
        e.preventDefault();
        setNewRole("");
    }

    function onChange(e) {
        setNewRole(e.target.value);
    }

    function onClick(e, role) {
        e.preventDefault();
        setCurrentRole(role);
    }

    return (
        <div className="bg-white list-roles">
            <ul>
                {
                    roles.length > 0 && roles.map((role, index) => {
                        return (
                            <li>
                                <a
                                    className={`p-3 ${currentRole.id === role.id ? "active" : ""}`}
                                    href="#"
                                    onClick={e => onClick(e, role)}
                                >{role.name}</a>
                            </li>
                        );
                    })
                }
            </ul>
            <p className="p-2">
                <Input
                    placeholder="+ Thêm vai trò"
                    size="large"
                    value={newRole}
                    onChange={onChange}
                    onPressEnter={onSave}
                    prefix={loading ? <LoadingOutlined /> : null}
                />
                <a onClick={onSave} href="#"><SaveOutlined /></a>
                <a onClick={onClear} href="#"><CloseOutlined /></a>
            </p>
        </div>
    );
}
