import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";

class ServicesType extends BaseServiceData {

    runApi(resolve) {
        api.getTypes(this.project_id)
            .then(res => {
                let data = _.get(res, "data.types", []);
                this.data = data;
                resolve(data);
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("ticket:label.type_id").toLowerCase()})});
            })
            .finally(() => {
                this.fetching = false;
            });
    }
}

export default new ServicesType();
