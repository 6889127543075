import React, {useState, useEffect} from 'react';
import {Modal, Button, notification, Radio, Form} from 'antd';
import api from "../../../../services/api";
import _ from 'lodash';
import {url} from "../../../../../../system/routing";
import {t} from "../../../../../../system/i18n";

function CreateUsTaiga(props) {
    const {ticket} = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchProjectTaiga();
    }, []);

    function fetchProjectTaiga() {
        api.getProjectTaiga(ticket.id)
            .then(res => {
                setProjects(_.get(res, "data.taigaProjects", []));
            }).catch(err => {
            const status = _.get(err, "response.status", null);
            if (status === 403)
                url.redirectTo('error-403');
            else if (status === 404)
                url.redirectTo('error-404');
            else
                notification.error({message: t('project:messages.load_fail')});
        })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setLoading(true);
        form.validateFields()
            .then(values => {
                api.createUsTaiga(ticket.id, values).then(res => {
                    fetchProjectTaiga();
                    notification.success({message: t("ticket:messages.create_us_taiga_success")});
                });
            }).catch(err => {
            notification.success({message: t("ticket:messages.create_us_taiga_fail")});
        }).finally(() => {
            handleCancel();
            setLoading(false)
        });
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    return (
        <>
            <Button className="create_us_taiga" onClick={showModal} type="primary">
                Tạo US trên Taiga
            </Button>
            {
                isModalVisible &&
                <Modal
                    title={t("ticket:title.choose_project")}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="cancel" onClick={handleCancel}>{t("btn.cancel")}</Button>,
                        <Button key="ok" type="primary" onClick={handleOk} loading={loading}>{t("btn.ok")}</Button>
                    ]}
                >
                    <Form
                        form={form}
                    >
                        <Form.Item
                            name="project_id"
                            rules={[{required: true, message: t("ticket:messages.choose_project_required")}]}
                        >
                            <Radio.Group>
                                {
                                    projects.map(item => <Radio className="d-block mb-3"
                                                                value={item.id}>{item.name} {item.us_created &&
                                    <i>(Đã tạo)</i>}</Radio>)
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    );
}

export default CreateUsTaiga;