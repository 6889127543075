import React, {useState, useEffect} from 'react';
import {Modal, Form, Input, notification, Checkbox, Col, Row, Button} from "antd";
import {useTranslation} from "react-i18next";
import useValidatedMessages from "../../../system/hooks/useValidatedMessages";
import api from '../services/api';
import lodash from 'lodash';

const Texarea = Input.TextArea;

export default function ModalUpdateTicketType(props) {
    const {t} = useTranslation();
    const validateMessages = useValidatedMessages();
    const {typeForm, visible, currentData, projectId, onSucess, handleModal, hasDefault, updateCategoryService} = props;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const isOpenConfirm = lodash.isEmpty(hasDefault) ? false : (hasDefault.id !== currentData.id);

    useEffect(() => {
        if (typeForm === "update") {
            const {name, description, is_active, is_default} = currentData;
            form.setFieldsValue({name, description, is_active, is_default});
        }
    }, [typeForm]);

    function apiUpdateTicketType(params) {
        if (typeForm === "create") {
            return api.createType(projectId, params)
        } else {
            return api.updateType(currentData.id, params);
        }
    }

    const updateTicketType = (params) => {
        apiUpdateTicketType(params).then(res => {
            updateCategoryService();
            onSucess();
            notification.success({message: t("settings:messages.ticket_type.success", {action: t(`btn.${typeForm}`)})});
        }).catch((error) => {
            notification.error({message: t("settings:messages.ticket_type.error", {action: t(`btn.${typeForm}`)})});
        }).finally(() => {
            handleModal();
            setLoading(false);
        })
    };

    function handleOk() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                if (isOpenConfirm && values["is_default"]) {
                    return Modal.confirm({
                        title: t("settings:messages.confirm_set_default", {name: hasDefault.name}),
                        onOk: () => updateTicketType(values),
                        onCancel: () => cancelConfirm(),
                        confirmLoading: true,
                        okText: t("btn.ok"),
                        cancelText: t("btn.cancel"),
                        maskClosable: false
                    });
                } else {
                    updateTicketType(values);
                }

            });
    }

    function handleCancel() {
        handleModal();
    }

    function cancelConfirm() {
        setLoading(false);
        form.setFieldsValue({is_default: false});
    }

    function handleReset() {
        if (typeForm === "update") {
            const {name, description, is_active, is_default} = currentData;
            form.setFieldsValue({name, description, is_active, is_default});
        }else {
            form.resetFields();
        }
    }

    return (
        <Modal
            title={t(`settings:title.update_ticket_type`, {action: t(`btn.${typeForm}`)}).toUpperCase()}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose
            confirmLoading={loading}
            okText={t("common:btn.save")}
            cancelText={t("common:btn.cancel")}
            footer={[
                <Button key="back" onClick={() => handleReset()}>
                    {t(`btn.reset_form`)}
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => handleOk()}>
                    {t(`btn.${typeForm}`)}
                </Button>,
            ]}
            maskClosable={false}
        >
            <Form
                name="update_ticket_type"
                form={form}
                validateMessages={validateMessages}
                preserve={false}
                layout="vertical"
            >
                <Form.Item
                    className="mb-3"
                    label={t("settings:label.name", {attribute: t("settings:title.type_name")})}
                    name="name"
                    rules={[{max: 255, required: true}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    label={t("settings:label.description")}
                    name="description"
                >
                    <Texarea rows={5}/>
                </Form.Item>

                <Row>
                    {
                        typeForm === "update" &&
                        <Col xs={{span: 10}} md={{span: 6}}>
                            <Form.Item
                                name="is_active"
                                valuePropName="checked"
                            >
                                <Checkbox>{t("settings:label.active")}</Checkbox>
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={{span: 12}} md={{span: 8}}>
                        <Form.Item
                            name="is_default"
                            valuePropName="checked"
                        >
                            <Checkbox>{t("settings:label.is_default")}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
