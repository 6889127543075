import React, {useEffect, useState} from "react";
import useProject from "../../../Project/hooks/useProject";
import {events} from "../../../../system/events";
import {EVENTS} from "../../../../layouts/constants";
import {url} from "../../../../system/routing";
import _ from "lodash";
import {Button, Col, Layout, notification, Row, Table} from "antd";
import AddMember from "./AddMember";
import {useTranslation} from "react-i18next";
import {auth} from "../../../Auth/services";
import {CloseSquareOutlined} from "@ant-design/icons";
import api from "../../services/api";
import lodash from 'lodash';
import Error404 from "../../../App/Error/404";

const {Header, Content} = Layout;

export default function DepartmentDetail({match}) {
    const {params} = match;
    const departmentId = params.department_id;
    const {project, userCan} = useProject(params.id);
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState(false);
    const [selectedRowKeys, setSelectRowKeys] = useState([]);
    const [error, setError] = useState(false);
    const {t} = useTranslation();
    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
            document.title = `${project.name} - ${t('title.department')}`;
            events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
            events.dispatch(EVENTS.HEADER_BREADCRUMB, [
                {
                    name: t('ticket:breadcrumb.manage_project'),
                    url: url.to('manage-project')
                },
                {
                    name: t('ticket:label.project_id') + ': ' + project.name,
                    url: url.to('list-tickets', {id: project.id})
                },
                {
                    name: t("breadcrumb.departments"),
                    url: url.to('departments', {id: project.id})
                },
                {
                    name: t("breadcrumb.department_detail")
                }
            ]);
        }
    }, [project, userCan]);
    useEffect(() => {
        if (project && userCan && project.id && userCan.configDepartment) {
            getMembersOfDepartment();
        }
    }, [project, userCan]);

    const columns = [
        {
            title: t("member:label.account_name"),
            dataIndex: 'name',
            width: "33%",
            render: (dataIndex, record) => <p>{record.name || record.user_name}</p>
        },
        {
            title: t("member:label.email"),
            dataIndex: 'email',
            width: "40%",
        },
        {
            title: t("member:label.remove"),
            dataIndex: 'id',
            width: "25%",
            render: (dataIndex, record) => {
                return (
                    <div className="">

                        <a onClick={(e) => removeMemberOfDepartment([record.id])} style={{color: "red"}}>
                            <CloseSquareOutlined/>
                        </a>
                    </div>
                )
            },
        },
    ];

    function getMembersOfDepartment() {
        setLoading(true);
        api.getMembersOfDepartment(departmentId).then(res => {
            const members = lodash.orderBy(lodash.get(res.data, "members", []), ['member.id'], ['desc']);
            setMembers(members);
        }).catch((error) => {
            let responseData = lodash.get(error, "response", undefined);
            if (responseData) {
                switch (responseData.data.code) {
                    case 403:
                        setError(403);
                        break;
                    case 404:
                        setError(404);
                        break;
                    default:
                        notification.error({message: t("message.server_connection_error")});
                        break;
                }
            } else {
                notification.error({message: t("message.server_connection_error")})
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const removeMemberOfDepartment = (userIds = []) => {
        let data = userIds.length > 0 ? {userIds} : {userIds: selectedRowKeys};
        setLoading(true);
        api.removeMembersOfDepartment(departmentId, data).then(res => {
            setSelectRowKeys([]);
            getMembersOfDepartment();
            notification.success({message: t("member:message.remove_member_department_success")})
        }).catch((error) => {
            const responseData = lodash.get(error, "response.data", undefined);
            if (responseData && responseData.code === "INPUT_INVALID") {
                notification.error({message: t("member:error." + (lodash.keys(responseData.data.user_id)[0]).toLowerCase())})
            } else {
                notification.error({message: t("message.server_error")})
            }
        }).finally(() => {
            setLoading(false);
        })
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectRowKeys(selectedRowKeys);
        }
    };
    if (userCan === null)
        return null;

    if (!userCan || !userCan.configDepartment)
        return <Error404/>;
    const renderManagerMember =
        <div className="site-content">
            <Header
                className='title-page bg-white border-bottom d-flex justify-content-between align-items-center pl-4 pr-4'>
                <h2>{t('title.department')}</h2>
            </Header>
            <Content className='department-page'>
                <Col className="pt-3 pb-3" md={{offset: 6, span: 10}} xs={{offset: 0, span: 24}}>
                    <AddMember
                        getMembersOfDepartment={getMembersOfDepartment}
                        departmentId={departmentId}
                        projectId={project.id}
                    />
                </Col>

                <Col md={{offset: 2, span: 20}}>
                    {
                        <Row style={{marginBottom: 16}}>
                            <Button type="primary" onClick={() => removeMemberOfDepartment()} disabled={!hasSelected}
                                    loading={loading}>
                                {t("member:label.remove_member")}
                            </Button>
                        </Row>
                    }
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={members}
                        pagination={false}
                        loading={loading}
                    />
                </Col>
            </Content>

        </div>

    return (
        <div className="site-content">
            {
                error ? <Error404/> : renderManagerMember
            }
        </div>
    );
}
