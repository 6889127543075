import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {DragDropContext} from 'react-beautiful-dnd';
import {Modal, Input, notification, Layout} from 'antd';
import KanbanColumn from './KanbanColumn';
import {events} from '../../../../../system/events';
import useStatus from "../../../../TicketStatus/hooks/useStatus";
import {EVENTS} from "../../../services/constants";
import api from "../../../services/api";
import _ from "lodash";
import AssignTo from "./AssignTo";
import EditFastly from "./EditFastly";
import ModalRateStar from "../../../components/ModalRateStar";

const {Content} = Layout;

function KanbanTickets({filters, project_id, isKanban, userCan, addFilter, settings}) {
    const {t} = useTranslation();
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [dataTicket, setDataTicket] = useState({});
    const [openRateStar, setOpenRateStar] = useState(false);
    const [note, setNote] = useState('');
    const {loading: loadingStatuses, statuses} = useStatus(project_id);
    const dragedRef = useRef({});
    const ticketsRef = useRef([]);

    useEffect(() => {
        fetchTickets();
    }, [project_id, filters, isKanban]);

    useEffect(() => {
        ticketsRef.current = tickets;
    }, [tickets]);

    useEffect(() => {
        return events.listen([EVENTS.NEW_TICKET_CREATED], (event, payload) => {
            setTickets([{...payload}, ...ticketsRef.current]);
        });
    }, []);

    function fetchTickets() {
        setIsLoading(true);
        api.getTickets(project_id, {...filters, is_kanban: 1})
            .then((res) => {
                setTickets(_.get(res, 'data.tickets', []));
            })
            .catch((err) => {
                notification.error({
                    message: t('ticket:messages.fetch_fail'),
                });
            })
            .finally(() => setIsLoading(false));
    }

    function onDragEnd(result) {
        if (!userCan.updateTicket) {
            Modal.warn({
                title: t('ticket:messages.update_kanban_status'),
                content: t('ticket:messages.403'),
                maskClosable: false
            });
            return;
        }

        let oldStatusId = parseInt(_.get(result, "source.droppableId", 0)),
            newStatusId = parseInt(_.get(result, "destination.droppableId", 0)),
            ticketId = parseInt(_.get(result, "draggableId", 0)),
            newStatus = statuses.find(item => item.id === newStatusId),
            oldStatus = statuses.find(item => item.id === oldStatusId);

        if (newStatusId === oldStatusId)
            return;

        dragedRef.current = {ticketId, oldStatus, newStatus};
        updatePositionTicket(ticketId, oldStatus, newStatus);
        if (newStatus.position < oldStatus.position)
            setIsVisible(true);
        else
            updateStatus();
    }

    function updatePositionTicket(ticketId, oldStatus, newStatus) {
        setTickets(tickets.map(item => {
            let {ticket} = item;
            if (ticket.id === ticketId) {
                return {
                    ...item,
                    ticket: {...ticket, status_id: newStatus.id},
                    ticketStatus: {...newStatus}
                };
            } else {
                return {...item};
            }
        }));
    }

    function handleCancel() {
        const {ticketId, oldStatus, newStatus} = dragedRef.current;
        updatePositionTicket(ticketId, newStatus, oldStatus);
        setIsVisible(false);
    }

    function handleOk() {
        updateStatus();
        setIsVisible(false);
    }

    function handleOnChange(event) {
        setNote(event.target.value);
    }

    function updateStatus() {
        const {ticketId, oldStatus, newStatus} = dragedRef.current;
        const data = {status_id: newStatus.id};
        if (note)
            data.note = note;

        api.updateStatus(ticketId, data)
            .then(res => {
                updatePositionTicket(ticketId, oldStatus, newStatus);
                const dataTicket = _.get(res, "data.ticket", {});
                setDataTicket(dataTicket);
                const canRateStar = _.get(res, "data.canRateStar", {});
                if (canRateStar) {
                    setOpenRateStar(true);
                }
                notification.success({message: "Thay đổi trạng thái ticket thành công!"});
            })
            .catch(err => {
                updatePositionTicket(ticketId, newStatus, oldStatus);
                notification.error({message: "Thay đổi trạng thái ticket không thành công!"});
            })
            .finally(() => {
                dragedRef.current = {};
                setNote("");
                setIsVisible(false);
            });
    }

    function updateTickets(data) {
        let {ticket} = data;
        if (ticket) {
            setTickets(tickets.map(item => {
                if (item.ticket.id === ticket.id)
                    return {...item, ...data};
                else
                    return item;
            }));
        }
    }

    if (loadingStatuses || !statuses)
        return null;

    return (
        <Content className="mt-3">
            <DragDropContext onDragEnd={onDragEnd}>
                <div id="layout-kanban">
                    {
                        statuses && tickets &&
                        statuses
                            .filter(status => status.is_show)
                            .map((status, index) => {
                                return (
                                    <KanbanColumn
                                        key={index}
                                        status={status}
                                        tickets={tickets}
                                        isLoading={isLoading}
                                        project_id={project_id}
                                        filters={filters}
                                        addFilter={addFilter}
                                    />
                                );
                            })
                    }
                </div>
            </DragDropContext>

            {
                isVisible &&
                <Modal
                    title='Lý do thay đổi trạng thái'
                    visible={isVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={t('common:btn.save')}
                    cancelText={t('common:btn.cancel')}
                    okButtonProps={{disabled: !note}}
                    maskClosable={false}
                >
                    <Input.TextArea onChange={handleOnChange}/>
                </Modal>
            }
            <AssignTo
                project_id={project_id}
                updateTickets={updateTickets}
            />
            <EditFastly
                project_id={project_id}
                updateTickets={updateTickets}
                userCan={userCan}
                settings={settings}
            />
            {
                openRateStar &&
                <ModalRateStar
                    visible={openRateStar}
                    ticket={dataTicket}
                    setVisible={setOpenRateStar}
                    getTicket={() => {setDataTicket({})}}
                />
            }

        </Content>
    );
}

export default KanbanTickets;
