import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServicesScopes from "../services/ServicesScopes";
import lodash from "lodash";

const {Option} = Select;

export default function SelectScopes(props) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const {project_id} = props;


    useEffect(() => {
        setIsLoading(true);
        ServicesScopes.list(project_id)
            .then(res => {
                setData(res);
                if (props.action === "add") {
                    props.onChange(lodash.get(lodash.find(res, {'is_default': true}), "id", undefined));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    return (
        <Select
            loading={isLoading}
            {...props}
            value={(data.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                data.length > 0 &&
                data
                    .filter(scope => scope.is_active)
                    .map(opt => {
                        return <Option key={opt.id} value={opt.id}>{opt.name}</Option>;
                    })
            }
        </Select>
    );
}
