import React, {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import {Spin, Tooltip, Popover, notification} from "antd";
import api from "../../../../services/api";
import {useTranslation} from "react-i18next";
import AddFollower from "../AddFollower";
import { auth } from "../../../../../Auth/services";
import { events } from "../../../../../../system/events";
import { EVENTS } from "../../../../services/constants";

function FollowedMembers(props) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [isVisible, setIsVisibleAddFollower] = useState(false);
    const isUpdated = props.isUpdated;
    const ticketId = props.ticketId;
    const projectId = props.projectId;

    const authUser = auth.user();

    useEffect(() => {
        fetchListFollower();
    }, [isUpdated]);

    useEffect(() => {
        events.listen(EVENTS.RELOAD_FOLLOWERS, fetchListFollower);
    }, []);

    function fetchListFollower() {
        setLoading(true);
        api.getListFollower(ticketId).then(res => {
            if (res.data.ticketFollowers) {
                setFollowers(res.data.ticketFollowers);
            }
        }).catch(error => {

        }).finally(() => {
            setLoading(false)
        })
    }

    const handleShowModal = () => setIsVisibleAddFollower(!isVisible);

    const updateFollower = (user) => setFollowers([user].concat(followers));

    const removeFollower = (user) => {
        api.unFollowTicket(ticketId, user.id)
            .then(() => removeFollowerSuccess(user))
            .catch(() => notification.error({ message: t('message.server_error') }))
            .finally(() => setLoading(false));
    }

    const removeFollowerSuccess = (user) => {
        setFollowers(followers => followers.filter(fl => fl.id !== user.id));
        events.dispatch(EVENTS.UPDATE_FOLLOWERS);
        if (user.id === authUser.id) {
            events.dispatch(EVENTS.UPDATE_FOLLOWING_STATE, false)
        }
    }

    const renderFollowerMenu = (user) => {
        return (
            <div>
                <p className="antd-menu-item" onClick={() => removeFollower(user)}>
                    <a href="#">
                        {t('ticket:label.remove_follower')}
                    </a>
                </p>
            </div>
        )
    }

    return (
        <div className="followed-members">
            <h3 className="sperator pt-2 pb-2 pl-3 pr-3 mt-3 mb-2 d-flex justify-content-between align-items-center">
                <div className="text-uppercase">{t("ticket:label.followers")}</div>
                <div className="add-member" onClick={handleShowModal}>
                    <PlusOutlined /> THÊM
                </div>
            </h3>
            <Spin spinning={loading}>
				{Array.isArray(followers) && followers.length > 0 ? (
					<ul>
						{followers.map((item, index) => {
							const name = item.name || item.username || item.email;
							return (
								<li className='member' key={index}>
									<Tooltip placement='top' title={name}>
										<Popover placement='bottom' content={renderFollowerMenu(item)} trigger='hover'>
											{<img src={item.avatar} alt={name} />}
										</Popover>
									</Tooltip>
								</li>
							);
						})}
					</ul>
				) : loading ? (
					<div className='sperator pt-2 pb-2 pl-3 pr-3 mt-3 mb-2 ' />
				) : (
					<p className='text-center mt-3 mb-4'>{t('ticket:label.ticket_has_no_follower')}</p>
				)}
				{!loading && isVisible && (
					<AddFollower
						projectId={projectId}
						ticketId={ticketId}
						isVisible={isVisible}
						followers={followers}
						handleShowModal={handleShowModal}
						updateFollower={updateFollower}
					/>
				)}
            </Spin>
        </div>

    );
}

export default FollowedMembers;
