import React, {useState} from "react";
import {Input, notification, Row, Form, Spin} from "antd";
import api from "../../../../services/api";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import UploadMulti from "../../../../../App/Upload/UploadMulti";
import {CameraOutlined} from "@ant-design/icons";

const acceptFile = ["image/jpeg", "image/png"];
const maxFileSize = 5;
function    AddComment({ticket, pushComment, userCan}) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const {t} = useTranslation();

    function addComment() {
        form.validateFields()
            .then(values => {
                let formData = new FormData();
                for (let prop in values) {
                    if (prop === "images" && values[prop]){
                        values[prop].forEach(image => formData.append("images[]", image))
                    }else {
                        if (values[prop]) {
                            formData.append(prop, values[prop]);
                        }
                    }
                }
                if (formData.has("content") || formData.has("images[]")) {
                    setLoading(true);
                    api.addComment(ticket.id, formData)
                        .then(res => {
                            pushComment(_.get(res, "data.comment", {}));
                            form.resetFields();
                        })
                        .catch(err => {
                            notification.error({message: "Thêm mới bình luận không thành công!"});
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            });
    }

    function beforeUpload(file) {
        if (acceptFile) {
            if (acceptFile.indexOf(file.type.toLowerCase()) < 0) {
                return false;
            }
        }

        if (maxFileSize) {
            const validMaxSize = (file.size / 1024 / 1024) > maxFileSize;
            if (validMaxSize) {
                return false;
            }
        }

        return true;
    }


    function handleEnter(e) {
        if (e.shiftKey) {
            form.setFields([{name: "content", value: e.target.value}]);
        } else {
            addComment();
        }
    }


    function handleUpload(files) {
        let images = [];
        let error = false;
        files.forEach(file => {
            const validFile = beforeUpload(file);
            if (validFile) {
                images.push(file);
            }else{
                error = true;
            }
        });
        if (!error) {
            if (images.length > 0){
                form.setFields([{name: "images", value: images}]);
            }
            addComment();
        }else {
            form.resetFields();
        }
    }


    return (
        <Form
            size="default"
            form={form}
            className="mb-2"
        >
            <Row>
                <Form.Item name="content" className="add-comment mb-0">
                    <Input.TextArea
                        type="text"
                        placeholder={t("ticket:placeholder.enter_comment")}
                        onPressEnter={(e) => handleEnter(e)}
                        autoSize={{minRows: 1}}
                        size={"middle"}
                        style={{resize: "none"}}
                    />
                </Form.Item>
                <Form.Item name="images" className="mb-0">
                    <UploadMulti
                        className="upload-image-comment"
                        onChange={handleUpload}
                        accept={acceptFile.join(",")}
                        fileType={acceptFile}
                        maxFileSize={maxFileSize}
                        showUploadList={false}
                        loading={loading}
                        name={"Ảnh bình luận"}
                        children={<CameraOutlined className="justify-content-center" style={{fontSize: '20px'}}/>}
                    />

                </Form.Item>
                {
                    loading ? <div className="icon_loading"><Spin spinning={loading} size={"small"}/></div> : null
                }
            </Row>
        </Form>

    );
}

export default AddComment;
