import React, {useEffect} from "react";
import {Pagination, Select} from "antd";
import _ from "lodash";

const {Option} = Select;
const sizeSettings = [5, 10, 20, 30, 40, 50, 80, 100];

function CustomizedPagination({pagination, filters, addFilter}) {

    useEffect(() => {
        if (pagination && filters && filters.page && filters.page > pagination.page_total) {
            addFilter({page: 1});
        }

    }, [pagination, filters]);

    function handleChange(page, pageSize) {
        addFilter({page, per_page: pageSize});
    }

    function handleSelect(size) {
        addFilter({per_page: size});
    }

    return !_.isEmpty(pagination) && pagination.page_total > 1 ?
        (
            <div className="customized-pagination p-3 d-flex justify-content-end align-items-center">
                <Pagination
                    current={pagination.current_page}
                    defaultCurrent={1}
                    total={pagination.total}
                    pageSize={pagination.per_page}
                    onChange={handleChange}
                />

                <span className="ml-3 mr-2">Hiển thị</span>

                <Select
                    onSelect={handleSelect}
                    defaultValue={pagination.per_page}
                >
                    {
                        sizeSettings.map(size => {
                            return <Option key={size} value={size}>{size}</Option>
                        })
                    }
                </Select>
            </div>
        ) : null;

}

export default CustomizedPagination;
