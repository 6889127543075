import React, {useState} from 'react';
import Icon, {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {notification, Tooltip, Badge} from "antd";
import api from "../services/api";
import lodash from "lodash";
import {useTranslation} from "react-i18next";

const DingTalk = () => {
    return <svg t="1607309763375" className="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2649" width="28" height="28">
        <path
            d="M512 0C229.257143 0 0 229.257143 0 512s229.257143 512 512 512 512-229.257143 512-512S794.742857 0 512 0z m259.428571 440.342857c-1.142857 4.8-4 11.885714-8 20.342857h0.114286l-0.457143 0.8c-23.2 49.257143-83.542857 145.942857-83.542857 145.942857s-0.114286-0.228571-0.342857-0.571428l-17.714286 30.628571h85.142857L584.114286 852.571429l36.914285-146.285715h-66.971428l23.314286-96.8c-18.857143 4.457143-41.028571 10.742857-67.428572 19.2 0 0-35.657143 20.8-102.742857-40 0 0-45.257143-39.657143-18.971429-49.6 11.2-4.228571 54.171429-9.6 88-14.057143 45.714286-6.171429 73.828571-9.371429 73.828572-9.371428S409.142857 517.714286 375.657143 512.571429c-33.485714-5.257143-75.885714-60.685714-84.914286-109.485715 0 0-13.942857-26.742857 30.057143-14.057143 44 12.685714 226.171429 49.371429 226.171429 49.371429s-237.028571-72.342857-252.8-89.942857c-15.771429-17.6-46.4-96.228571-42.4-144.571429 0 0 1.714286-12 14.171428-8.8 0 0 175.2 79.657143 294.971429 123.314286 119.771429 43.314286 223.885714 65.485714 210.514285 121.942857z"
            p-id="2650" fill="#0061b2"></path>
    </svg>
};
const DingTalkIcon = props => <Icon component={DingTalk} {...props} />;

function ConnectDingTalk(props) {
    const {t} = useTranslation();
    const {projectInfo, errorsDingTalk, updateProjects} = props;
    const [loading, setLoading] = useState(false);
    const error = lodash.get(errorsDingTalk, projectInfo.id, undefined);
    const [danger, setDanger] = useState(!!error);
    const dingUserId = lodash.get(projectInfo, "projectMember.ding_user_id", null);

    function connectDingTalk() {
        const ids = [projectInfo.id];
        setLoading(true);
        api.connectDingTalk({ids}).then((res) => {
            const errors = lodash.get(lodash.get(res, "data.errors", []), projectInfo.id, undefined);
            if (errors) {
                notification.error({message: renderMessageErrors(errors)});
            } else {
                const projectsUpdateInfo = lodash.get(res, "data.projects", []);
                const projectUpdateInfo = lodash.find(projectsUpdateInfo, ['id', projectInfo.id]);
                updateProjects(projectUpdateInfo);
                notification.success({message: t("project:messages.dingtalk.success")})
            }
        }).catch(() => {
            notification.error({message: t("message.server_error")})
        }).finally(() => {
            setLoading(false);
        });
    }

    function disConnectDingtalk() {
        api.disConnectDingTalk(projectInfo.id).then((res) => {
            const projectUpdateInfo = lodash.get(res, "data.project", {});
            updateProjects(projectUpdateInfo);
            notification.success({message: t("project:messages.dingtalk.cancel_success")})
        }).catch(() => {
            notification.error({message: t("message.server_error")})
        })
    }

    function renderMessageErrors(errors) {
        let messageError = "";
        if (lodash.get(errors, "project", undefined)) {
            switch (lodash.get(errors, "project", undefined)) {
                case "ALREADY_EXIST":
                    messageError = t("project:messages.dingtalk.ALREADY_EXIST");
                    break;
                case "NOT_FOUND":
                    messageError = t("project:messages.dingtalk.NOT_FOUND");
                    break;
                default:
                    messageError = t("project:messages.dingtalk.fail");
            }
        }else {
            switch (lodash.get(errors, "app_key", undefined)) {
                case "REQUIRED":
                    messageError = t("project:messages.dingtalk.REQUIRED");
                    break;
                default:
                    messageError = t("project:messages.dingtalk.fail");
            }
        }
        return messageError;
    }

    const renderButton = <DingTalkIcon
        onFocus={() => setDanger(false)}
        danger={danger}
        spin={loading}
        onClick={() => (dingUserId ? disConnectDingtalk() : connectDingTalk())}/>;


    const statusConnectDingTalk = dingUserId ?
        <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>;

    return (
        <Badge count={statusConnectDingTalk}>
            {
                danger ? <Tooltip title={renderMessageErrors(error)}>{renderButton}</Tooltip>
                    :
                    <Tooltip
                        title={dingUserId ? t("project:messages.dingtalk.disconnect") : t("project:messages.dingtalk.connect")}
                    >
                        {renderButton}
                    </Tooltip>
            }
        </Badge>
    );
}

export default ConnectDingTalk;
