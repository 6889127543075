import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {EditOutlined, LoadingOutlined, SaveFilled, CloseOutlined} from "@ant-design/icons";
import api from "../../../../services/api";
import {DatePicker, notification, Tooltip} from "antd";
import _ from "lodash";
import moment from "moment";


export default function TicketDateTime(props) {
    const {t} = useTranslation();
    const {ticket, field, updateTicket, userCan} = props;
    const [isEdited, setIsEdited] = useState(false);
    const [value, setValue] = useState(ticket[field]);

    function handleClick(e) {
        e.preventDefault();
        setIsEdited(true);
    }

    function handleChange(value, dateString) {
        if (!_.isEmpty(value)) {
            setValue(value);
            api.updateTicket(ticket.id, {[field]: value})
                .then(res => {
                    let newTicket = _.get(res, "data.ticket", {});
                    notification.success({message: t("ticket:messages.update_success", {attribute: t(`ticket:label.${field}`)})});
                    updateTicket({ticket: newTicket});
                })
                .catch(err => {
                    notification.error({message: t("ticket:messages.update_fail", {attribute: t(`ticket:label.${field}`)})});
                })
                .finally(() => {
                    setIsEdited(false);
                });
        }
    }

    function handleBlur(e) {
        e.preventDefault();
        setIsEdited(false);
        setValue(ticket[field]);
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().subtract(1, 'days').endOf('day');
    }
    function renderDateTime(type) {
        return <DatePicker
            defaultValue={value ? moment(value) : undefined}
            placeholder="Chọn ngày"
            disabledDate={disabledDate}
            onChange={handleChange}
            onBlur={handleBlur}
            format={"DD/MM/YYYY"}
            style={{width: "100%"}}
        />
    }

    return (
        <>
            {
                isEdited && userCan && userCan.updateTicket ?
                    renderDateTime(props.type) :
                    <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                        <p
                            onClick={handleClick}
                            className="p-value"
                        >{ticket[field] ? moment(ticket[field]).format('DD/MM/YYYY') : t("common:noText")}</p>
                    </Tooltip>
            }
        </>
    );
}
