import Navbar from "../Navbar";
import React from "react";
import {Layout} from "antd";
import Navigator from "../Navigator";
import app from "../../../config/app";
import Logo from "../../../resources/img/logo.png";
import {url} from "../../../system/routing";
import {Link} from "react-router-dom";

const {Header} = Layout;

function PageHeader({toggleCollapsed, toggleExpandMobile, collapsed, expandMobile, dashboard}) {

    function onClick() {
        toggleCollapsed();
    }

    function onClickMobile() {
        toggleExpandMobile();
    }

    return (
        <Header className="page-header">
            <div className="d-flex align-items-center">
                {
                    dashboard ? null :
                    <>
                        <p className={`toggle-menu d-none d-md-flex${collapsed ? "" : " expanded"}`} onClick={onClick}><span></span></p>
                        <p className={`toggle-menu d-md-none${expandMobile ? " expanded" : ""}`} onClick={onClickMobile}><span></span></p>
                    </>
                }
                <h2 className={dashboard ? "pl-4" : ""}>
                    <Link to={url.to("home")}><img src={Logo} height={34} alt={app.projectName}/></Link>
                </h2>
                <Navigator />
            </div>
            <Navbar/>
        </Header>
    );
}

export default PageHeader;
