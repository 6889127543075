import React, { useEffect, useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Select, Button, Col, Row, notification, Checkbox } from 'antd';

import useProject from '../../hooks/useProject';
import Error403 from '../../../App/Error/403';

import { events } from '../../../../system/events';
import { url } from '../../../../system/routing';
import { EVENTS } from '../../../../layouts/constants';
import api from '../../services/api';
import ServicesProject from '../../services/ServicesProject';

const { Option } = Select;

function ConfigNotification({ match }) {
	const { t } = useTranslation();
	const { params } = match;
	const {
		projectData: { settings, project, userCan, noticeEvents },
		projectData,
		setProjectData,
	} = useProject(params.id);
	const { NOTICE_EVENTS, NOTICE_TURN_ON, NOTICE_BEFORE_DAYS } = settings || {};

	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [turnOnNotice, setTurnOnNotice] = useState(false);

	useLayoutEffect(() => {
		setTurnOnNotice(!!NOTICE_TURN_ON);
		const defaultEvents = Array.isArray(noticeEvents) && noticeEvents.length === 1 && noticeEvents;

		form.setFields([
			{ name: 'NOTICE_EVENTS', value: NOTICE_EVENTS || defaultEvents },
			{ name: 'NOTICE_TURN_ON', value: !!NOTICE_TURN_ON },
			{ name: 'NOTICE_BEFORE_DAYS', value: NOTICE_BEFORE_DAYS || undefined },
		]);
	}, [settings]);

	useEffect(() => {
		if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
			document.title = `${project.name} - ${t('menu.notification_settings')}`;
			events.dispatch(EVENTS.MENU_LEFT, { project, userCan });
			events.dispatch(EVENTS.HEADER_BREADCRUMB, [
				{
					name: t('ticket:breadcrumb.manage_project'),
					url: url.to('manage-project'),
				},
				{
					name: t('ticket:label.project_id') + ': ' + project.name,
					url: url.to('list-tickets', { id: project.id }),
				},
				{
					name: t('breadcrumb.notification_settings'),
				},
			]);
		}
	}, [project, userCan]);

	if (userCan === null) return null;

	if (userCan && !userCan.configProject) return <Error403 />;

	function handleSubmit() {
		form.validateFields().then((values) => {
			setIsSubmitting(true);

			for (const prop in values) {
				if (values[prop] === undefined) {
					delete values[prop];
				}
				if (values[prop] === 'NOTICE_TURN_ON') {
					values[prop] = values[prop] ? 1 : 0;
				}
			}

			api
				.updateSettings(project.id, values)
				.then((response) => {
					const responseData = _.get(response, 'data', {});
					ServicesProject.updateData({ ...ServicesProject.data, ...responseData });
					setProjectData({ ...projectData, ...responseData });

					notification.success({ message: t('project:messages.update_success') });
				})
				.catch(() => notification.error({ message: t('project:messages.update_fail') }))
				.finally(() => setIsSubmitting(false));
		});
	}

	return (
		<div className='site-content'>
			<div className='project-settings'>
				<h1 className='a-list--title a-text--uppercase text-center mb-5'>
					{t('breadcrumb.notification_settings')}: {project.name}
				</h1>

				<Form
					labelCol={{ span: 6 }}
					labelAlign='left'
					wrapperCol={{ span: 18 }}
					size='default'
					form={form}
				>
					<Row type='flex' justify='center'>
						<Col xs={{ span: 24 }} md={{ span: 16 }}>
							<Form.Item name='NOTICE_EVENTS' label={t('project:label.notice_event')} colon={false}>
								<Select mode='multiple' placeholder={t('project:placeholder.notice_event')}>
									{noticeEvents.map((event) => (
										<Option value={event}>{t(`project:event.${event}`)}</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								name='NOTICE_TURN_ON'
								label={t('project:label.notice_allow')}
								colon={false}
								valuePropName='checked'
							>
								<Checkbox onChange={(e) => setTurnOnNotice(e.target.checked)} />
							</Form.Item>

							<Form.Item
								name='NOTICE_BEFORE_DAYS'
								label={t('project:label.notice_frequency')}
								colon={false}
							>
								<Select
									placeholder={t('project:placeholder.notice_frequency')}
									disabled={!turnOnNotice}
								>
									{[1, 2, 3, 4, 5, 6, 7].map((frequency) => (
										<Option value={frequency}>
											{frequency === 1
												? 'Tại ngày hết hạn'
												: `Trong vòng ${frequency} ngày: tính cả ngày hết hạn`}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Row>
								<Col xs={{ span: 24 }} md={{ span: 18, offset: 6 }}>
									<Button
										type='primary'
										htmlType='submit'
										onClick={handleSubmit}
										loading={isSubmitting}
										className='pl-4 pr-4'
									>
										{t('btn.update')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
}

export default ConfigNotification;
