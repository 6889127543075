import React, {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import {t} from "../../../../../system/i18n";
import {Button, Modal, Form, Input, Checkbox, Row, Col, notification} from "antd";
import useValidatedMessages from "../../../../../system/hooks/useValidatedMessages";
import _ from "lodash";
import api from "../../../../TicketStatus/services/api";
import {events} from "../../../../../system/events";
import {EVENTS} from "../../../services/constants";
import {GithubPicker} from "react-color";

const {TextArea} = Input;

// list of rules for every input
const validatedRules = {
    name: [{required: true, max: 255, type: "string"}],
    description: [],
    is_show: [],
    is_closed: [],
    color: ""
};

export default function StatusForm({project_id, addNewItem, updateItem, updateCategoryService}) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentColor, setCurrentColor] = useState(null);
    const validateMessages = useValidatedMessages();
    const [form] = Form.useForm();

    // Nếu cập nhật status thì lưu trạng thái đang cập nhật
    const [updatedStatus, setUpdatedStatus] = useState(null);

    useEffect(() => {
        resetNewForm();
        return events.listen(EVENTS.UPDATE_STATUS, (event, payload) => handleUpdateStatus(payload));
    }, []);

    // Quản lý sự kiện cập nhật trạng thái
    function handleUpdateStatus(payload) {
        showModal();
        setCurrentColor(payload.color);
        setUpdatedStatus(payload);
        resetUpdatedForm(payload);
    }

    // Reset form tạo mới
    function resetNewForm() {
        form.setFields([
            {name: "is_show", value: true},
            {name: "is_closed", value: false}
        ]);
        setCurrentColor(null);
    }

    // Reset form cập nhật về dữ liệu trạng thái hiện tại
    function resetUpdatedForm(payload) {
        let newPayload = _.pick(payload, ["is_show", "is_closed", "name", "description", "color"]);

        form.setFields(Object.keys(newPayload).map(prop => ({
            name: prop,
            value: newPayload[prop]
        })));
        setCurrentColor(newPayload.color);
    }

    function showModal() {
        setVisible(true);
    }

    function handleCancel() {
        setVisible(false);
        onResetNew();
    }

    function handleSubmit(e) {
        form.validateFields()
            .then(values => {
                setLoading(true);
                if (updatedStatus === null)
                    addStatus(values);
                else
                    updateStatus(values);
            });
    }

    function addStatus(formData) {
        api.addStatus(project_id, formData)
            .then(res => {
                updateCategoryService();
                addNewItem(_.get(res, "data.ticketStatus", {}));
                notification.success({message: t("common:message.create_success", {attribute: t("status:label.status").toLowerCase()})});
                setVisible(false);
                onResetNew();
            })
            .catch(err => {
                notification.error({message: t("common:message.create_fail", {attribute: t("status:label.status").toLowerCase()})});
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function updateStatus(formData) {
        api.updateStatus(project_id, updatedStatus.id, formData)
            .then(res => {
                updateCategoryService();
                updateItem(_.get(res, "data.ticketStatus", {}));
                notification.success({message: t("common:message.update_success_by_attribute", {attribute: t("status:label.status").toLowerCase()})});
                setVisible(false);
                setUpdatedStatus(null);
                onResetNew();
            })
            .catch(err => {
                notification.error({message: t("common:message.update_failed_by_attribute", {attribute: t("status:label.status").toLowerCase()})});
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function onResetNew() {
        form.resetFields();
        resetNewForm();
    }

    function onResetUpdate() {
        form.resetFields();
        resetUpdatedForm(updatedStatus);
    }

    function changeColor(color) {
        form.setFields([{name: "color", value: color.hex}]);
        setCurrentColor(color.hex);
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                <PlusOutlined />{t("common:btn.create")}
            </Button>
            <Modal
                title={updatedStatus === null ? t("status:label.create_status") : t("status:label.update_status")}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <Form
                    className={"form"}
                    validateMessages={validateMessages}
                    size="default"
                    form={form}
                    forceRender
                    colon=":"
                    layout="vertical"
                >
                    <Form.Item
                        className="mb-3"
                        name="name"
                        label={t('status:label.name')}
                        rules={validatedRules.name}
                    >
                        <Input
                            name="subject"
                            placeholder={t('status:placeholder.name', {attribute: t("settings:title.status_name")})}
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={t('status:label.description')}
                        rules={validatedRules.description}
                        className="mb-3"
                    >
                        <TextArea
                            style={{height: "100px"}}
                            placeholder={t('status:placeholder.description')}
                        />
                    </Form.Item>
                    <div>
                        <Form.Item
                            name="color"
                            label={t('status:label.color')}
                            rules={validatedRules.color}
                            className="mb-3"
                        >
                            <Input
                                placeholder={t('status:placeholder.color')}
                                suffix={<span style={{background: currentColor ? currentColor : "transparent", width: "50px", height: "100%", display: "block"}}></span>}
                            />
                        </Form.Item>
                    </div>
                    <GithubPicker
                        colors={['#FF6900', '#f27b00', '#FCB900', '#7BDCB5', '#25b8b4', '#00D084', '#349700', '#8ED1FC', '#0693E3', '#0780FF', '#ABB8C3', '#EB144C', '#ff2725', '#b83700', '#F78DA7', '#9900EF', '#FF256B', '#777777']}
                        width="100%"
                        color={'#FF6900'}
                        onChange={changeColor}
                    />
                    <Row className="mt-3">
                        <Col xs={{span: 10}} md={{span: 6}}>
                            <Form.Item
                                name="is_show"
                                className="mb-1"
                                rules={validatedRules.is_show}
                                valuePropName="checked"
                            >
                                <Checkbox>{t('status:label.is_show')}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={{span: 12}} md={{span: 8}}>
                            <Form.Item
                                name="is_closed"
                                className="mb-1"
                                rules={validatedRules.is_closed}
                                valuePropName="checked"
                            >
                                <Checkbox>{t('status:label.is_closed')}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="mt-4">
                        <Button
                            htmlType={"submit"}
                            type="primary"
                            onClick={handleSubmit}
                            loading={loading}
                            style={{textTransform: "uppercase"}}
                        >{updatedStatus === null ? t("common:btn.create") : t("common:btn.update")}</Button>
                        <Button
                            htmlType={"reset"}
                            className="ml-2"
                            onClick={updatedStatus === null ? onResetNew : onResetUpdate}
                            style={{textTransform: "uppercase"}}
                        >{t("common:btn.reset_form")}</Button>
                    </div>
                </Form>
            </Modal>
        </>
    );

}
