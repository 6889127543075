import React, {useState} from 'react';
import {Layout, Menu, Button} from 'antd';
import PageHeader from "../modules/App/Header";
import MenuLeft from "../modules/App/MenuLeft";

const { Content } = Layout;

export default function DefaultLayout({children}) {
    const [collapsed, setCollapsed] = useState(true);
    const [expandMobile, setExpandMobile] = useState(false);

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    function toggleExpandMobile() {
        setExpandMobile(!expandMobile);
    }

    return (
        <Layout>
            <PageHeader
                collapsed={collapsed}
                expandMobile={expandMobile}
                toggleCollapsed={toggleCollapsed}
                toggleExpandMobile={toggleExpandMobile}
            />

            <Layout id="page-body">
                <MenuLeft
                    collapsed={collapsed}
                    expandMobile={expandMobile}
                    toggleCollapsed={toggleCollapsed}
                    toggleExpandMobile={toggleExpandMobile}
                />
                <Content>{children}</Content>
            </Layout>
        </Layout>
    );
}
