import React, {useState} from "react";
import SelectStatus from "../../../../../TicketStatus/components/SelectStatus";
import {useTranslation} from "react-i18next";
import useStatus from "../../../../../TicketStatus/hooks/useStatus";
import api from "../../../../services/api";
import TextArea from "antd/es/input/TextArea";
import {Modal, notification, Tooltip} from "antd";
import _ from "lodash";
import ModalRateStar from "../../../../components/ModalRateStar";

export default function ChangeStatus({ticket, project_id, updateTicket, ticketStatus, userCan, getTicket}) {
    const {t} = useTranslation();
    const [isEdited, setIsEdited] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openRateStar, setOpenRateStar] = useState(false);
    const [note, setNote] = useState("");
    const [value, setValue] = useState(ticket.status_id);
    const {statuses} = useStatus(project_id);

    function handleClick(e) {
        e.preventDefault();
        setIsEdited(true);
    }

    function handleBlur() {
        if (!isVisible)
            setIsEdited(false);
    }

    function handleSelect(status_id) {
        setValue(status_id);
        const selectedStatus = statuses.find(item => item.id === status_id);
        const currentStatus = statuses.find(item => item.id === ticket.status_id);

        if (selectedStatus.id === currentStatus.id) {
            setIsEdited(false);
            return;
        }

        if (currentStatus.position < selectedStatus.position)
            updateStatus(status_id, () => setIsEdited(false));
        else
            setIsVisible(true);
    }

    function updateStatus(status_id, callback) {
        setLoading(true);
        let data = {status_id};
        if (note) data.note = note;

        api.updateStatus(ticket.id, data)
            .then(res => {
                const canRateStar = _.get(res, "data.canRateStar", false);
                setOpenRateStar(canRateStar);
                getTicket();
                notification.success({message: "Thay đổi trạng thái ticket thành công!"});
            })
            .catch(err => {
                notification.error({message: "Thay đổi trạng thái ticket không thành công!"});
            })
            .finally(() => {
                setLoading(false);
                callback();
            });
    }

    function handleOk() {
        updateStatus(value, () => {
            setIsVisible(false);
            setIsEdited(false);
            setNote("");
        });
    }

    function handleCancel() {
        setValue(ticket.status_id);
        setIsVisible(false);
        setIsEdited(false)
        setNote("");
    }

    function handleChange(e) {
        setNote(e.target.value);
    }

    return (
        <>
            {
                isEdited && userCan && userCan.updateTicket ?
                    <div>
                        <SelectStatus
                            project_id={project_id}
                            style={{width: "100%"}}
                            placeholder={t('status:placeholder.choose_status')}
                            onBlur={handleBlur}
                            onSelect={handleSelect}
                            value={ticketStatus.is_show ? value : undefined}
                        />
                        <Modal
                            title="Lý do thay đổi trạng thái"
                            visible={isVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okText={t("common:btn.save")}
                            cancelText={t("common:btn.cancel")}
                            okButtonProps={{disabled: note.trim() === "", loading: loading}}
                            maskClosable={false}
                        >
                            <TextArea onChange={handleChange}/>
                        </Modal>
                    </div> :
                    <>
                        {
                            (userCan && userCan.updateTicket) ?
                                <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                                    <p
                                        className="p-value p-status"
                                        onClick={handleClick}
                                        style={{backgroundColor: ticketStatus && ticketStatus.color ? ticketStatus.color : "#026DAA"}}
                                    >{ticketStatus ? ticketStatus.name : ticket.status_id}</p>
                                </Tooltip> :
                                <p
                                    className="p-value p-status"
                                    style={{backgroundColor: ticketStatus && ticketStatus.color ? ticketStatus.color : "#026DAA"}}
                                >{ticketStatus ? ticketStatus.name : ticket.status_id}</p>
                        }
                    </>
            }
            {
                openRateStar &&
                <ModalRateStar
                    visible={openRateStar}
                    setVisible={setOpenRateStar}
                    ticket={ticket}
                    getTicket={getTicket}
                />
            }
        </>
    );
}
