import {Button, Col, Collapse, Modal, notification, Row, Switch} from "antd";
import EditName from "./EditName";
import React, {useEffect, useState} from "react";
import {t} from "../../../../system/i18n";
import api from "../../services/api";
import _ from "lodash";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";

const {Panel} = Collapse;

const HeaderPanel = function({title, active, total}) {
    return (
        <h3 className="title-panel">{title} <span className="ml-2">{active}/{total}</span></h3>
    );
}

export default function RoleDetail({currentRole, updateRoles, setCurrentRole}) {
    const [projectPermissions, setProjectPermissions] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentRole) {
            setLoading(true);
            api.getPermission(currentRole.id)
                .then(res => {
                    formatProjectPermissions(_.get(res, "data.projectPermissions", []))
                    setPermissions(_.get(res, "data.role.permissions", []));
                })
                .catch(err => {
                    notification.error({message: t("roles:messages.load_permissions")})
                })
                .finally(() => setLoading(false));
        }
    }, [currentRole.id])

    function changePermission(permission) {
        let data = {[permission]: 1};
        if (permissions !== null)
            data = {[permission]: permissions.includes(permission) ? 0 : 1};

        api.changePermission(currentRole.id, data)
            .then(res => {
                setPermissions(_.get(res, "data.role.permissions", []));
                notification.success({message: t("roles:messages.update_permissions_success")})
            })
            .catch(err => {
                notification.error({message: t("roles:messages.update_permissions_fail")})
            });
    }

    function cantChange() {
        Modal.warning({
            title: t("roles:messages.is_owner", {owner_role_name: currentRole.name}),
            content: t("roles:messages.cantchange", {owner_role_name: currentRole.name}),
            maskClosable: false
        });
    }

    function formatProjectPermissions(permissions) {
        let permissionArr = {};
        permissions.forEach(item => {
            let itemArr = item.split(":");
            if (permissionArr[itemArr[0]])
                permissionArr[itemArr[0]].push(item);
            else
                permissionArr[itemArr[0]] = [item];
        });
        setProjectPermissions(permissionArr);
    }

    function hasPermission(permission) {
        if (permissions === null)
            return false;

        if (permissions.length > 0 && permissions[0] === "*")
            return true;

        return permissions.includes(permission);
    }

    function onDelete() {
        Modal.confirm({
            title: t("roles:messages.confirm_delete"),
            okText: t("btn.confirm"),
            cancelText: t("btn.cancel"),
            onOk: function () {
                api.deleteRole(currentRole.id)
                    .then(res => {
                        updateRoles(currentRole, false);
                        notification.success({message: t("roles:messages.delete_success")});
                    })
                    .catch(err => {
                        notification.error({message: t("roles:messages.delete_fail")});
                    });
            },
            maskClosable: false
        });
    }

    return (
        <>
            <div className="title-list-permission bg-white">
                <Row className="pt-2 pb-2 pl-3 pr-3">
                    <Col span={20} className="d-flex align-items-center">
                        <EditName
                            currentRole={currentRole}
                            updateRoles={updateRoles}
                            setCurrentRole={setCurrentRole}
                        />
                    </Col>
                    {
                        !currentRole.is_owner &&
                        <Col span={4} className="text-right">
                            <Button
                                type="primary"
                                danger
                                onClick={onDelete}
                            >Xóa</Button>
                        </Col>
                    }
                </Row>
            </div>
            {
                loading &&
                    <div className="mt-3 mb-3 text-center"><LoadingOutlined /></div>
            }
            {
                !loading && projectPermissions &&
                <Collapse style={{backgroundColor: "transparent", border: "none"}} >
                    {
                        Object.keys(projectPermissions).map((item, index) => {
                            let total = projectPermissions[item].length,
                                active = 0;

                            if (permissions && permissions.length > 0) {
                                if (permissions[0] === "*")
                                    active = total;
                                else
                                    active = projectPermissions[item].reduce((sum, current) => permissions.includes(current) ? sum+1 : sum, 0);
                            }

                            return (
                                <Panel
                                    key={index}
                                    header={<HeaderPanel title={t(`roles:group.${item}`)} active={active} total={total} />}
                                    className="panel-permission mt-3"
                                    style={{backgroundColor: "#fafafa", border: "1px solid #d9d9d9"}}
                                >
                                    <ul>
                                        {
                                            projectPermissions[item].map(prop => {
                                                return (
                                                    <li className="pt-3 pb-3">
                                                        <span>{t(`roles:permissions.${prop}`)}</span>
                                                        <Switch
                                                            size="small"
                                                            checked={hasPermission(prop)}
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            onChange={e => currentRole.is_owner ? cantChange() : changePermission(prop)}
                                                        />
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </Panel>
                            );
                        })
                    }
                </Collapse>
            }
        </>
    );
}
