import React, {useEffect, useState} from "react";
import LogItem from "./LogItem";
import api from "../../../../services/api";
import {notification} from "antd";
import _ from "lodash"
import {LoadingOutlined} from "@ant-design/icons";
import {EVENTS} from "../../../../services/constants";
import {events} from "../../../../../../system/events";
import {t} from "../../../../../../system/i18n";

function TicketLogs({ticket, userCan}) {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        return events.listen([
            EVENTS.DELETE_COMMENT,
            EVENTS.UPLOAD_FILE,
            EVENTS.DELETE_FILE,
            EVENTS.UPDATE_FOLLOWERS,
        ], fetchLogs);
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchLogs();
    }, [ticket]);

    function fetchLogs() {
        api.getLogs(ticket.id)
            .then(res => {
                setLogs(_.get(res, "data.logs", []));
            })
            .catch(err => {
                const status = _.get(err, "response.status", null);
                if (![403, 404].includes(status))
                    notification.error({message: "Tải dữ liệu lịch sử thao tác không thành công!"});
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="log-ticket actions-ticket">
            <p>Lịch sử thao tác</p>
            {
                userCan && userCan.showLogTicket ?
                    <div className="wrapper-logs-ticket">
                        {
                            loading ? <p className="text-center"><LoadingOutlined/></p> : null
                        }
                        {
                            logs.length > 0 &&
                            logs.map(log => {
                                return (
                                    <LogItem
                                        key={log.id}
                                        log={log}
                                        ticket={ticket}
                                    />
                                );
                            })
                        }
                    </div> :
                    <div>{t("ticket:messages.not_permission_log")}</div>
            }
        </div>
    );
}

export default TicketLogs;
