class BaseServiceData {

    constructor() {
        this.data = null;
        this.promise = null;
        this.fetching = false;
        this.project_id = null;
    }

    list(project_id) {
        if (this.fetching && this.project_id) {
            return this.fetchData();
        }

        if (this.data && this.project_id === project_id)
            return Promise.resolve(this.data);

        if (this.fetching && this.project_id === project_id)
            return this.promise;

        this.project_id = project_id;
        return this.fetchData();
    }

    updateData(data) {
        this.data = data;
    }

    fetchData() {
        this.fetching = true;
        this.promise = new Promise(this.runApi.bind(this));

        return this.promise;
    }

    runApi(resolve) {}

}

export default BaseServiceData;
