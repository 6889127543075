import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {Layout, Button, Tooltip, Row, Col} from 'antd';
import {
	ProjectOutlined,
	UnorderedListOutlined,
	PlusOutlined,
	MinusOutlined
} from '@ant-design/icons';
import TableTickets from './TableTickets';
import KanbanTickets from './KanbanTickets';
import CreateTicket from '../../components/CreateTicket';
import { events } from '../../../../system/events';
import { EVENTS } from '../../../../layouts/constants';
import { url } from '../../../../system/routing';
import useFilter from "../../hooks/useFilter";
import FormSearch from "./TableTickets/FormSearch";
import useProject from "../../../Project/hooks/useProject";
import Error403 from "../../../App/Error/403";
import withSizes from "react-sizes";

const { Header } = Layout;
const mapSizesToProps = ({width}) => ({
	isMobile: width < 580
});

function ListTickets({location, match, history, isMobile}) {
	const { params } = match;
	const { t } = useTranslation();
	const { project, userCan, settings } = useProject(params.id);
	const { filters, addFilter } = useFilter(history, location);
	const [isKanban, setIsKanban] = useState(null);
	const kanbanMode = localStorage.getItem('kanbanMode') ? JSON.parse(localStorage.getItem('kanbanMode')) : false;
	const [filterBtn, setFilterBtn] = useState({filter: false, status: false});

	function setKanbanMode() {
		setIsKanban(!isKanban);
		localStorage.setItem("kanbanMode", !isKanban);
	}

	function toggleFilter(tab) {
		setFilterBtn({...filterBtn, [tab]: !filterBtn[tab]});
	}

	useEffect(() => {
		if (isKanban)
			addFilter({page: "", per_page: "", status_id: "", is_mine: "", show_closed: ""});
	}, [isKanban]);

	useEffect(() => {
		setIsKanban(kanbanMode);
	}, [kanbanMode]);

	useEffect(() => {
		if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
			document.title = `${project.name} - ${t('menu.manage_list')}`;
			events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
			events.dispatch(EVENTS.HEADER_BREADCRUMB, [
				{
					name: t('ticket:breadcrumb.manage_project'),
					url: url.to('manage-project'),
				},
				{
					name: t('ticket:label.project_id') + ': ' + project.name,
					url: url.to('list-tickets', { id: project.id }),
				},
				{
					name: t('breadcrumb.manage_ticket'),
				},
			]);
		}
	}, [project, userCan]);

	if (isKanban === null || userCan === null)
		return null;

	if (userCan && !userCan.showListTicket)
		return (<Error403 />);

	return (
		<div className='site-content'>
			<Header className='title-page bg-white border-bottom d-sm-flex justify-content-between align-items-center pl-4 pr-4'>
				<h2>{!_.isEmpty(project) ? project.name + ' - ' : ''}Danh sách ticket</h2>
				<div className="d-flex">
					{
						!isMobile &&
						<>
							<Tooltip title="Hiển thị dạng Kanban">
								<Button
									onClick={setKanbanMode}
									type={isKanban ? 'primary' : 'default'}
									className="mt-2 mr-2"
								>
									<ProjectOutlined />
								</Button>
							</Tooltip>
							<Tooltip title="Hiển thị dạng Bảng">
								<Button
									onClick={setKanbanMode}
									type={isKanban ? 'default' : 'primary'}
									className="mt-2 mr-3"
								>
									<UnorderedListOutlined />
								</Button>
							</Tooltip>
						</>
					}
					<CreateTicket
						settings={settings}
						project_id={params.id}
						userCan={userCan}
					/>
				</div>
			</Header>

			<Layout>
				<Row className="filter-btn d-md-none" gutter={10}>
					<Col span={12}>
						<p
							className={filterBtn.filter ? "active" : ""}
							onClick={() => toggleFilter("filter")}
						>Bộ lọc ticket {filterBtn.filter ? <MinusOutlined /> : <PlusOutlined />}</p>
					</Col>
					<Col span={12}>
						<p
							className={filterBtn.status ? "active" : ""}
							onClick={() => toggleFilter("status")}
						>Lọc theo trạng thái {filterBtn.status ? <MinusOutlined /> : <PlusOutlined />}</p>
					</Col>
				</Row>
				<FormSearch
					settings={settings}
					project_id={params.id}
					addFilter={addFilter}
					filters={filters}
					expand={filterBtn.filter}
				/>
				{
					isKanban ?
					<KanbanTickets
						project_id={params.id}
						addFilter={addFilter}
						filters={filters}
						isKanban={isKanban}
						userCan={userCan}
						settings={settings}
					/> :
					<TableTickets
						project_id={params.id}
						addFilter={addFilter}
						filters={filters}
						isKanban={isKanban}
						expand={filterBtn.status}
						settings={settings}
					/>
				}
			</Layout>
		</div>
	);
}

export default withSizes(mapSizesToProps)(ListTickets);
