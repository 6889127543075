import React, {useEffect, useState} from "react";
import {Input, notification} from "antd";
import api from "../../services/api";
import {useTranslation} from "react-i18next";
import {CloseOutlined, EditOutlined, SaveOutlined, LoadingOutlined} from "@ant-design/icons";
import _ from "lodash";

export default function EditName({currentRole, updateRoles, setCurrentRole}) {
    const {t} = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {
        setIsEdit(false);
        setName(currentRole.name);
    }, [currentRole.id]);

    function onClick() {
        setIsEdit(true);
    }

    function onClear(e) {
        e.preventDefault();
        setIsEdit(false);
        setName(currentRole.name);
    }

    function onChange(e) {
        setName(e.target.value);
    }

    function onEnter(e) {
        e.preventDefault();
        if (name.trim() !== "") {
            setLoading(true);
            api.updateRole(currentRole.id, {name})
                .then(res => {
                    let role = _.get(res, "data.role", {});
                    updateRoles(role);
                    setCurrentRole(role);
                    notification.success({message: t("roles:messages.update_name_success")});
                })
                .catch(err => {
                    notification.error({message: t("roles:messages.update_name_success")});
                })
                .finally(() => {
                    setIsEdit(false);
                    setLoading(false);
                });
        }
    }

    if (!currentRole)
        return null;

    return (
        <>
            {
                isEdit ?
                    <p className="edit-name" style={{width: "100%"}}>
                        <Input
                            value={name}
                            onPressEnter={onEnter}
                            onChange={onChange}
                            style={{width: "100%"}}
                            prefix={loading ? <LoadingOutlined /> : null}
                        />
                        <a onClick={onEnter} href="#"><SaveOutlined /></a>
                        <a onClick={onClear} href="#"><CloseOutlined /></a>
                    </p> :
                    <h3 onClick={onClick}>{currentRole.name} <EditOutlined className="ml-1" /></h3>
            }
        </>
    );
}
