import {Link} from "react-router-dom";
import {url} from "../../../../system/routing";
import {Avatar, Col, Row, Tooltip} from "antd";
import ConnectDingTalk from "../../components/ConnectDingTalk";
import ConnectZalo from "../../components/ConnectZalo";
import React from "react";
import {SortableElement} from "react-sortable-hoc";
import _ from "lodash";
import {auth} from "../../../Auth/services";
import {PlusCircleOutlined,  UserAddOutlined, EyeOutlined} from "@ant-design/icons";

function SortableItem({project, updateProjects, errorsDingTalk, is_public}) {
    let {zalo_oa_id, canConnectDingTalk} = project;
    const user = auth.user();

    return (
        <li className="item-project p-3">
            <p>
                <Link to={url.to("list-tickets", {id: project.id})}>
                    <Avatar shape="square" size={64} src={project.logo}>{project.name}</Avatar>
                </Link>
            </p>
            <Row>
                <Col xs={{span: 20}} lg={{span: 22}}>
                    <Row>
                        <Col xs={{span: 24}} md={{span: 21}} className="brief-project">
                            <Row>
                                <Col xs={{span: 12}} md={{span: 10}}>
                                    <Link to={url.to("list-tickets", {id: project.id})}>{project.name}</Link>
                                </Col>
                                <Col xs={{span: 12}} md={{span: 11}}>
                                    <Tooltip title="Ticket đã tạo (không gồm trạng thái cuối)">
                                        <Link to={url.to("list-tickets", {id: project.id}) + "?creator_id=" + user.id}>
                                            <PlusCircleOutlined style={{color: "blue", fontSize: "16px"}}/>
                                            {" " + _.get(project, "statTicket.authCreated", 0)}
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Ticket được phân công (không gồm trạng thái cuối)">
                                        <Link to={url.to("list-tickets", {id: project.id}) + "?handler_id=" + user.id}>
                                            <UserAddOutlined className="pl-3" style={{color: "orange", fontSize: "16px"}}/>
                                            {" " + _.get(project, "statTicket.authAssigned", 0)}
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Ticket đang theo dõi (không gồm trạng thái cuối)">
                                        <Link to={url.to("list-tickets", {id: project.id}) + "?follower_id=" + user.id}>
                                            <EyeOutlined className="pl-3"
                                                         style={{color: "green", fontSize: "16px"}}/>
                                            {" " + _.get(project, "statTicket.authFollowing", 0)}
                                        </Link>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <p>{project.description}</p>
                        </Col>
                        <Col xs={{span:24}} md={{span: 3}} className="pt-2 pt-md-3">
                            {
                                canConnectDingTalk &&
                                <ConnectDingTalk
                                    projectInfo={project}
                                    updateProjects={updateProjects}
                                    errorsDingTalk={errorsDingTalk}
                                />
                            }
                            {
                                zalo_oa_id &&
                                <ConnectZalo
                                    projectInfo={project}
                                    updateProjects={updateProjects}
                                    zalo_oa_id={zalo_oa_id}
                                />
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={{span: 4}} md={{span: 2}} className="d-flex align-items-center justify-content-end">
                    <span className="pointer-sort"></span>
                </Col>
            </Row>
        </li>
    )
}

export default SortableElement(SortableItem);
