import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {arrayMove} from 'react-sortable-hoc';
import {Button, Layout, Col, Row, notification, Checkbox} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import SortableList from "./SortableList";
import {t} from '../../../../system/i18n';
import api from '../../services/api';
import ModalUpdateDepartment from '../../components/ModalUpdateDepartment';
import useProject from "../../../Project/hooks/useProject";
import {events} from "../../../../system/events";
import {EVENTS} from "../../../../layouts/constants";
import {url} from "../../../../system/routing";
import {EVENTS_DEPARTMENT} from "../../services/constants";
import Error404 from "../../../App/Error/404";
import apiProject from "../../../Project/services/api";
import {EVENTS as EVENTS_PROJECT} from "../../../Project/services/constants";

const {Header, Content} = Layout;

export default function ListDepartment({match}) {
    const {params} = match;
    const {project, userCan, settings} = useProject(params.id);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasDefault, setHasDefault] = useState(false);
    const [currentData, setCurrentData] = useState(false);
    const [typeForm, setTypeForm] = useState('create');
    const [isOpenModal, setIsOpenModal] = useState(false);
    useEffect(() => {
        if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
            document.title = `${project.name} - ${t('title.department')}`;
            events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
            events.dispatch(EVENTS.HEADER_BREADCRUMB, [
                {
                    name: t('ticket:breadcrumb.manage_project'),
                    url: url.to('manage-project')
                },
                {
                    name: t('ticket:label.project_id') + ': ' + project.name,
                    url: url.to('list-tickets', {id: project.id})
                },
                {
                    name: t("breadcrumb.departments")
                }
            ]);
        }
    }, [project, userCan]);

    function refeshDepartmentData() {
        events.dispatch(EVENTS_DEPARTMENT.FETCH_DEPARTMENT_AGAIN, {projectId: project.id});
    }

    useEffect(() => {
        fetchDepartments();
    }, [params.id]);

    function fetchDepartments() {
        setLoading(true);
        api.getDepartments(params.id)
            .then((res) => {
                const departments = _.get(res, 'data.departments', []);
                const indexHasDefault = _.findIndex(departments, 'is_default');
                setHasDefault(indexHasDefault >= 0 ? departments[indexHasDefault] : false);
                setDepartments(departments);
            })
            .catch((err) => {
                notification.error({
                    message: t('common:message.fetch_fail', {
                        attribute: t('ticket:label.department_id').toLowerCase(),
                    }),
                });
            })
            .finally(() => setLoading(false));
    }

    function updateItem(updatedItem) {
        let newItems = departments.map((item) => {
            if (item.id === updatedItem.id) return updatedItem;
            else return updatedItem.is_default ? {...item, is_default: false} : item;
        });

        setDepartments(newItems);
    }

    function updateDepartment(department_id, params) {
        api
            .updateDepartment(department_id, params)
            .then((res) => {
                updateItem(_.get(res, 'data.department', {}));
                // updateDepartmentService();
                refeshDepartmentData();
                notification.success({
                    message: t('common:message.update_success_by_attribute', {
                        attribute: t('ticket:label.department_id').toLowerCase(),
                    }),
                });
            })
            .catch((err) => {
                notification.error({
                    message: t('common:message.update_failed_by_attribute', {
                        attribute: t('ticket:label.department_id').toLowerCase(),
                    }),
                });
            });
    }

    function onSortEnd({oldIndex, newIndex}) {
        if (departments && departments.length === 1) return false;

        let newItems = arrayMove(departments, oldIndex, newIndex);
        let params = newItems.map((item, index) => ({id: item.id, position: index}));
        setDepartments(newItems);
        api
            .updatePosition(params.id, params)
            .then((res) => {
                // updateDepartmentService();
                refeshDepartmentData();
                notification.success({
                    message: t('common:message.update_success_by_attribute', {
                        attribute: t('ticket:label.type_id').toLowerCase(),
                    }),
                });
            })
            .catch((err) => {
                notification.error({
                    message: t('common:message.update_failed_by_attribute', {
                        attribute: t('ticket:label.type_id').toLowerCase(),
                    }),
                });
            });
    }

    function shouldCancelStart(e) {
        if (e.target.tagName === 'SPAN' && e.target.className === 'pointer-sort') return false;

        return true;
    }

    const openModal = (type = 'create', record = false) => {
        setIsOpenModal(!isOpenModal);
        setTypeForm(type);
        setCurrentData(record);
    };

    function handleOnChangeHiddenOnTicket(e) {
        apiProject.updateSettings(params.id, {ACTIVE_DEPARTMENT: e.target.checked ? 0 : 1})
            .then((res) => {
                events.dispatch(EVENTS_PROJECT.FETCH_PROJECT_INFO_AGAIN);
                notification.success({message: t("common:message.update_success_by_attribute", {attribute: t("ticket:label.department_id").toLowerCase()})});
            }).catch(() => notification.error({message: t("common:message.update_failed_by_attribute", {attribute: t("ticket:label.department_id").toLowerCase()})}))
    }

    if ((userCan && !userCan.configDepartment))
        return <Error404/>;
    return (
        <div className="site-content">
            <Header
                className='title-page bg-white border-bottom d-flex align-items-center pl-4 pr-4'>
                <h2>{t('title.department')}</h2>
                {
                    !_.isEmpty(settings) &&
                    <Checkbox
                        className="ml-4"
                        onChange={handleOnChangeHiddenOnTicket}
                        defaultChecked={!_.get(settings, "ACTIVE_DEPARTMENT", 1)}
                    >
                        <i>{t("settings:btn.hidden_on_ticket")}</i>
                    </Checkbox>
                }
                <Button className="ml-auto" type='primary' onClick={() => openModal('create')}>
                    <PlusOutlined/>
                    {t('common:btn.create')}
                </Button>
            </Header>
            <Content className='category-page'>
                <div className='category-header'>
                    <Row>
                        <Col span={3}>{t('settings:label.active')}</Col>
                        <Col span={8}>
                            {t('settings:label.name', {attribute: t('settings:title.department_name')})}
                        </Col>
                        <Col span={8} className='d-none d-md-flex'>
                            {t('settings:label.description')}
                        </Col>
                        <Col span={5} className='text-center'>{t('settings:label.members')}</Col>
                    </Row>
                </div>

                {
                    loading ?
                        <div className='p-4 text-center' style={{fontSize: '24px'}}>
                            <LoadingOutlined/>
                        </div> :
                        <SortableList
                            items={departments}
                            onSortEnd={onSortEnd}
                            shouldCancelStart={shouldCancelStart}
                            onShow={updateDepartment}
                            openModalUpdate={openModal}
                            project_id={params.id}
                        />
                }
            </Content>
            <ModalUpdateDepartment
                visible={isOpenModal}
                handleModal={openModal}
                typeForm={typeForm}
                currentData={currentData}
                projectId={params.id}
                onSuccess={fetchDepartments}
                hasDefault={hasDefault}
                refeshDepartmentData={refeshDepartmentData}
            />
        </div>
    );
}
