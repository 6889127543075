import lodash from 'lodash';

/**
 * Get the value of env param
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getEnv = (param, defaultValue = null) => lodash.get(process.env, param, defaultValue);

/**
 * Get global config
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getConfig = (param, defaultValue = null) => lodash.get(window.APP_CONFIG, param, defaultValue);

/**
 * Create number sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const numberSorter = (param) => (a, b) => a[param] - b[param];

/**
 * Create string sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const stringSorter = (param) => (a, b) => {
    let aValue = a[param].toUpperCase();
    let bValue = b[param].toUpperCase();

    if (aValue < bValue) {
        return -1;
    }

    if (aValue > bValue) {
        return 1;
    }

    return 0;
};
