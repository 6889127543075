import React from "react";
import {SortableElement} from "react-sortable-hoc";
import {Checkbox, Col, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {events} from "../../../../../system/events";
import {EVENTS} from "../../../services/constants";

function SortableItem({status, onShow, onClosed}) {
    const {t} = useTranslation();

    function onChange(e, item_id) {
        onShow(item_id, {is_show: e.target.checked ? 1 : 0});
    }

    function onClick(e, item_id) {
        onClosed(item_id, {is_closed: 1});
    }

    function onUpdate(e) {
        e.preventDefault();
        events.dispatch(EVENTS.UPDATE_STATUS, status);
    }

    return (
        <li className="setting-item">
            <Row>
                <Col xs={{span: 2}} md={{span: 2}}>
                    <Checkbox
                        checked={status.is_show}
                        onChange={e => onChange(e, status.id)}
                    />
                </Col>
                <Col xs={{span: 4}} md={{span: 2}} className="d-none d-md-flex align-items-center">
                    <span style={{
                        display: "block",
                        background: status.color ? status.color : "transparent",
                        width: "30px",
                        height: "18px"
                    }}></span>
                </Col>
                <Col xs={{span: 13}} md={{span: 5}}>
                    <a href="#" onClick={onUpdate}>{status.name}</a>
                </Col>
                <Col className="d-none d-md-block" md={{span: 6}}>{status.description ? status.description : t("common:noText")}</Col>
                <Col xs={{span: 5}} md={{span: 3}} className="text-center">
                    <Radio
                        checked={status.is_closed}
                        onClick={e => onClick(e, status.id)}
                    />
                </Col>
                <Col xs={{span: 4}} md={{span: 6}} className="text-right pr-4">
                    <span className="pointer-sort"></span>
                </Col>
            </Row>
        </li>
    );
}

export default SortableElement(SortableItem);
