import React from "react";
import {SortableElement} from "react-sortable-hoc";
import {Checkbox, Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import {UsergroupAddOutlined} from "@ant-design/icons";
import {Link, url} from "../../../../system/routing";
function SortableItem({item, onShow, openModalUpdate, project_id}) {
    const {t} = useTranslation();

    function onChange(e) {
        onShow(item.id, {is_active: e.target.checked ? 1 : 0});
    }

    return (
        <li className="setting-item">
            <Row>
                <Col span={3}>
                    <Checkbox
                        checked={item.is_active}
                        onChange={onChange}
                    />
                </Col>
                <Col span={8}><a className="pl-0" type="link" onClick={() => openModalUpdate("update", item)}>{item.name}</a></Col>
                <Col span={8} className="d-none d-md-flex">{item.description ? item.description : t("common:noText")}</Col>
                <Col span={5} className="text-center">
                    <Link style={{fontSize: "18px"}} href={url.to("department-detail", {id: project_id, department_id: item.id})}><UsergroupAddOutlined /></Link>
                </Col>
            </Row>
        </li>
    );
}

export default SortableElement(SortableItem);
