import {api} from "../../../system/api";
import {PREFIX_URL} from "../../../system/api/constants";

export default {
    getStatuses: project_id => api.get(PREFIX_URL.PROJECT + `/${project_id}/ticket-statuses`),
    updateStatus: (project_id, status_id, params) => api.put(`/ticket-statuses/${status_id}`, params),
    updatePosition: (project_id, params) => api.put(PREFIX_URL.PROJECT + `/${project_id}/ticket-statuses/positions`, params),
    isShow: (project_id, status_id, params) => api.put(`/ticket-statuses/${status_id}/show`, params),
    isClosed: (project_id, status_id, params) => api.put(`/ticket-statuses/${status_id}/is-closed`, params),
    addStatus: (project_id, params) => api.post(PREFIX_URL.PROJECT + `/${project_id}/ticket-statuses`, params),
}
