import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServicesTypes from "../services/ServicesTypes";
import lodash from "lodash";

const {Option} = Select;

export default function SelectTypes(props) {
    let [types, setTypes] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const {project_id} = props;

    useEffect(() => {
        setIsLoading(true);
        ServicesTypes.list(project_id)
            .then(res => {
                setTypes(res);
                if (props.action === "add") {
                    const typeDefault = lodash.get(lodash.find(res, {'is_default': true}), "id", undefined);
                    if (typeDefault) {
                        props.onChange(typeDefault);
                    }
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(types.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                types.length > 0 &&
                types
                    .filter(type => type.is_active)
                    .map(opt => {
                        return <Option key={opt.id} value={opt.id}>{opt.name}</Option>;
                    })
            }
        </Select>
    );
}
