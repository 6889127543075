import React, {useEffect, useState} from "react";
import {Modal, Input} from "antd";
import ListMember from "./ListMember";
import {events} from "../../../../../../system/events";
import {EVENTS} from "../../../../services/constants";

export default function AssignTo({project_id, updateTickets}) {
    const [ticket, setTicket] = useState(null);
    const [keyword, setKeyword] = useState("");

    const handleOk = () => {
        setTicket(null);
    };

    const handleCancel = () => {
        setTicket(null);
    };

    useEffect(() => {
        return events.listen([EVENTS.ASSIGN_MEMBER_TO_TICKET], (event, payload) => setTicket(payload));
    }, []);

    return (
        <Modal
            title="THÊM NGƯỜI XỬ LÝ"
            visible={ticket !== null}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
        >
            <Input
                className="member-modal"
                placeholder="Từ khóa thành viên..."
                size={"large"}
                onChange={e => setKeyword(e.target.value)}
            />
            <ListMember
                project_id={project_id}
                ticket={ticket}
                updateTickets={updateTickets}
                setTicket={setTicket}
                keyword={keyword}
            />
        </Modal>
    );
}
