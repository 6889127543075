import React from "react";
import {useTranslation} from "react-i18next";
import api from "../../../../services/api";
import {Checkbox, notification} from "antd";
import _ from "lodash";
import {events} from "../../../../../../system/events";
import {EVENTS_MEMBERS} from "../../../../../Member/services/constants";
import {EVENTS} from "../../../../services/constants";

export default function TicketCheckbox(props) {
    const {t} = useTranslation();
    const {ticket, field, updateTicket, userCan} = props;

    function refreshData() {
        events.dispatch(EVENTS_MEMBERS.FETCH_MEMBERS_AGAIN, {projectId: ticket.project_id});
        events.dispatch(EVENTS.RELOAD_FOLLOWERS);
    }

    function handleOnChange(e) {
        const value = e.target.checked;
        if (userCan && userCan.updateTicket) {
            api.updateTicket(ticket.id, {[field]: (value ? 1 : 0)})
                .then(res => {
                    let newTicket = _.get(res, "data.ticket", {});
                    notification.success({message: t("ticket:messages.update_success", {attribute: t(`ticket:label.${field}`)})});
                    updateTicket({ticket: newTicket});
                    if (field === "is_shared_internal") {
                        refreshData();
                    }
                })
                .catch(err => {
                    notification.error({message: t("ticket:messages.update_fail", {attribute: t(`ticket:label.${field}`)})});
                })
        }
        return false;
    }

    return (
        <Checkbox checked={!!ticket[field]} onChange={handleOnChange}>{t("ticket:label." + field)}</Checkbox>
    );
}
