import React, {useEffect, useState} from 'react';
import {Dropdown, Menu, notification, Tooltip} from 'antd';
import {url} from '../../../system/routing';
import {auth} from '../../Auth/services';
import {useTranslation} from 'react-i18next';
import {PlusSquareOutlined, AppstoreOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import api from "../../Project/services/api";
import lodash from "lodash";

export default function Navbar() {
    const user = auth.user();
    const [avatar, setAvatar] = useState(user.avatar);
    const [projects, setProjects] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        api.getListProject({is_public: 0})
            .then((res) => {
                setProjects(lodash.get(res, "data.projects", []));
            })
            .catch((error) => {
                notification.error({message: t("message.server_error")})
            });
    }, []);

    function onClick({key}) {
        switch (key) {
            case 'logout':
                return auth.logout();
            default:
                return url.redirectTo(key);
        }
    }

    const menuProfile = (
        <Menu>
            {
                auth.isLogged &&
                    <>
                        <Menu.Item>
                            <Link to={url.to("account-setting")}>{t("menu.account_setting")}</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <a rel="noopener noreferrer" onClick={(e) => onClick({key: "logout"})}>{t("menu.logout")}</a>
                        </Menu.Item>
                    </>
            }
        </Menu>
    );

    function renderProjects() {
        return (
            <Menu>
                {
                    projects.map((project, index) => {
                        return (
                            <Menu.Item key={index}>
                                <Link to={url.to("list-tickets", {id: project.id})}>{project.name}</Link>
                            </Menu.Item>
                        );
                    })
                }
            </Menu>
        );
    }

    return (
        <div className="navbar">
            <div className="main-navbar">
                {
                    projects.length > 0 ?
                        <Dropdown overlay={renderProjects()} placement="bottomRight" arrow overlayClassName={`projects-navbar${projects.length > 6 ? " scroller" : ""}`}>
                            <Tooltip title={t("menu.project_management")} placement={"left"}>
                                <Link to={url.to("manage-project")}>
                                    <AppstoreOutlined />
                                </Link>
                            </Tooltip>
                        </Dropdown> :
                        <Tooltip title={t("menu.project_management")} placement={"left"}>
                            <Link to={url.to("manage-project")}>
                                <AppstoreOutlined />
                            </Link>
                        </Tooltip>
                }
                {
                    user.project_limit > 0 && user.project_count < user.project_limit &&
                    <Tooltip title={t("menu.create_project")}>
                        <Link to={url.to("create-project")}>
                            <PlusSquareOutlined />
                        </Link>
                    </Tooltip>
                }
            </div>
            <div className="member-navbar d-sm-block">
                <Dropdown overlay={menuProfile} placement="bottomRight" arrow overlayClassName="sub-navbar">
                    <a href="#">
                        <img src={avatar} />
                    </a>
                </Dropdown>
            </div>
        </div>
    );
}
