import React, {useEffect, useState} from "react";
import {Breadcrumb} from "antd";
import {events} from "../../../system/events";
import {EVENTS} from "../../../layouts/constants";
import {url} from "../../../system/routing";

function Navigator(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        return events.listen([EVENTS.HEADER_BREADCRUMB], (event, payload) => setData(payload))
    }, []);

    function onClick(e, path) {
        e.preventDefault();
        url.redirect(path);
    }

    return (
        <Breadcrumb className="breadcrumb-header align-items-center d-none d-sm-flex">
            {
                data.length > 0 &&
                data.map((item, index) => {
                    return (
                        <Breadcrumb.Item key={index}>
                            {
                                item.url ?
                                    <a href="#" onClick={e => onClick(e, item.url)}>{item.name}</a> :
                                    <span key={index}>{item.name}</span>
                            }
                        </Breadcrumb.Item>
                    );
                })
            }
        </Breadcrumb>
    );
}

export default Navigator;
