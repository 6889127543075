import React, {useState, useEffect} from 'react';
import {Rate, Button} from 'antd';
import {useTranslation} from "react-i18next";
import ModalRateStar from './../../../../components/ModalRateStar';

function RateStar(props) {
    const {getTicket, data: {ticket, canRateStar}} = props;
    const {t} = useTranslation();
    const [visible, setVisible] = useState(false);

    function openRateStar() {
        setVisible(true);
    }

    return (
        <div>
            <h3 className="sperator pt-2 pb-2 pl-3 pr-3 mt-3 mb-2 d-flex justify-content-between align-items-center">
                <span className="text-uppercase">{t("ticket:label.rate")}</span>
                <Rate value={ticket.rate_star} disabled={true} style={{fontSize: 30}}/>
                {
                    canRateStar && <Button type="primary" onClick={openRateStar}>Đánh giá</Button>
                }
            </h3>
            <div>
                <p className="groove">{ticket.rate_note}</p>
            </div>
            {
                <ModalRateStar
                    visible={visible}
                    ticket={ticket}
                    setVisible={setVisible}
                    getTicket={getTicket}
                />
            }
        </div>
    )
}

export default RateStar;