export const EVENTS = {
    DELETE_COMMENT: "TICKET.DELETE_COMMENT",
    UPLOAD_FILE: "TICKET.UPLOAD_FILE",
    DELETE_FILE: "TICKET.DELETE_FILE",
    NEW_TICKET_CREATED: "TICKET.NEW_TICKET_CREATED",
    ASSIGN_MEMBER_TO_TICKET: "TICKET.ASSIGN_MEMBER_TO_TICKET",
    EDIT_TICKET_FASTLY: "TICKET.EDIT_TICKET_FASTLY",
    UPDATE_FOLLOWING_STATE: "TICKET.UPDATE_FOLLOWING_STATE",
    UPDATE_FOLLOWERS: "TICKET.UPDATE_FOLLOWERS",
    RELOAD_FOLLOWERS: "TICKET.RELOAD_FOLLOWERS",
};
