import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Input, Col, notification, Spin } from 'antd';
import api from '../../../../services/api';
import { auth } from '../../../../../Auth/services';
import { events } from '../../../../../../system/events';
import { EVENTS } from '../../../../services/constants';
import { trans } from '../../../../../../system/i18n';

function AddFollower({
	projectId,
	ticketId,
	isVisible,
	followers,
	handleShowModal,
	updateFollower,
}) {
	const { t } = useTranslation();
	const authUser = auth.user();
	const followerIds = followers.map((follower) => follower.id);

	const [loading, setLoading] = useState(false);
	const [loadingAdd, setLoadingAdd] = useState(false);
	const [members, setMembers] = useState([]);
	const [keyword, setKeyword] = useState('');
	const [memberSearchings, setMemberSearchings] = useState([]);

	const _isMounted = useRef(true);
	const _idMemberAdded = useRef();

	useEffect(() => () => (_isMounted.current = false), []);

	useEffect(() => {
		fetchMemberCanFollow();
	}, []);

	function fetchMemberCanFollow() {
		setLoading(true);
		api.getMembersCanFollowTicket(ticketId)
			.then((response) => {
				const user = _.get(response, "data.users", []);
				if (_isMounted.current) {
					setMembers(user);
				}
			})
			.finally(() => {
				if (_isMounted.current) {
					setLoading(false);
				}
			});
	}

	function addFollower(user) {
		_idMemberAdded.current = user.id;
		setLoadingAdd(true);
		api
			.followTicket(ticketId, user.id)
			.then(() => addFollowerSuccess(user))
			.catch((error) => {
				const response = _.get(error, 'response') || {}
				if (response.status === 403) {
					notification.error({ message: trans('ticket:messages.user_has_no_permission', {
						username: user.name || user.username || user.email,
						action: t('ticket:btn.follow').toLocaleLowerCase()
					}) });
				} else {
					notification.error({ message: t('message.server_error') });
				}
			})
			.finally(() => {
				if (_isMounted.current) {
					setLoadingAdd(false);
				}
			});
	}

	function addFollowerSuccess(user) {
		events.dispatch(EVENTS.UPDATE_FOLLOWERS);
		if (authUser.id === user.id) {
			events.dispatch(EVENTS.UPDATE_FOLLOWING_STATE, true);
		}
		if (_isMounted.current) {
			updateFollower(user);
			notification.success({ message: t('ticket:messages.add_follower_success') });
		}
	}

	function onSearch(event) {
		let { value } = event.target;
		value = value.trim();
		setKeyword(value);

		const keyword = value.toLowerCase();
		const memberSearchings = members.filter(
			(member) =>
				(member.name && member.name.toLowerCase().includes(keyword)) ||
				(member.username && member.username.toLowerCase().includes(keyword)) ||
				(member.email && member.email.toLowerCase().includes(keyword))
		);
		setMemberSearchings(memberSearchings);
	}

	return (
		<Modal
			title={t('ticket:label.add_follower')}
			visible={isVisible}
			footer={null}
			onCancel={handleShowModal}
			maskClosable={false}
		>
			<Row>
				<Input.Search
					allowClear
					placeholder={t('ticket:placeholder.enter_email_name_user_name')}
					enterButton
					onChange={onSearch}
					size='large'
				/>
			</Row>
			<div className='add-follower'>
				{loading ? (
					<Row type='flex' align='center' justify='center'>
						<Spin />
					</Row>
				) : (
					<>
						{(keyword ? memberSearchings : members)
							.filter((member) => !followerIds.includes(member.id))
							.map((member) => (
								<Row className='member' key={member.id} onClick={() => addFollower(member)}>
									<Col span={4}>
										<img src={member.avatar} />
									</Col>
									<Col span={19}>{member.name || member.username || member.email}</Col>
									<Col span={1}>
										{member.id === _idMemberAdded.current && loadingAdd && <Spin />}
									</Col>
								</Row>
							))}
					</>
				)}
			</div>
		</Modal>
	);
}

export default AddFollower;
