import React, {useEffect, useState} from 'react';
import {Button, Col, Input, notification, Row, Select, Form, Modal} from "antd";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import UploadAvatar from "../../../App/Upload/UploadAvatar";
import {useTranslation} from "react-i18next";
import api from "../../services/api"
import {auth} from "../../../Auth/services";
import {COUNTRY_TELEPHONE_CODE} from "../../../../system/api/constants";
import lodash from 'lodash';
import {EVENTS} from "../../../../layouts/constants";
import {events} from '../../../../system/events';
import ServicesProject from '../../../Project/services/ServicesProject';

const Option = Select.Option;
const TextArea = Input.TextArea;

function getPhoneNumber(phone) {
    let countryCode = COUNTRY_TELEPHONE_CODE[0].code;
    let phoneNumber = phone;
    if (phone) {
        COUNTRY_TELEPHONE_CODE.filter(item => {
            if (phone.indexOf(item.code) === 0) {
                countryCode = item.code;
                if (countryCode && countryCode.length > 0) {
                    phoneNumber = phone.substring(countryCode.length)
                }
                return true;
            }
        })
    }
    return {countryCode, phoneNumber};
}

function AccountSettings() {
    const {t} = useTranslation();
    const user = auth.user();
    const validateMessages = useValidatedMessages();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(user.avatar || "");
    const defaultPhone = getPhoneNumber(user.phone);
    const [form] = Form.useForm();
    useEffect(() => {
        document.title = t("menu.account_setting");
    }, []);

    const checkPhone = (rule, value) => {
        const regex = /^[]?\d*$/;
        if (regex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(t("validation:numeric", {attribute: t('user:label.phone')}));
    };

    const validatedRules = {
        name: [{max: 255}],
        phone: [{max: 20, validator: checkPhone}],
        note: [{max: 255}],
    };

    function handleUpload(file) {
        form.setFields([{name: "avatar", value: file}]);
    }

    function updateUserInfo(formData) {
        setLoading(true);
        if (formData.values())
            api.updateInfoUser(formData)
                .then(res => {
                    const userUpdate = lodash.get(res, "data.user", {});
                    notification.success({message: t("user:messages.update_success")});
                    auth.setUser(userUpdate);
                    events.dispatch(EVENTS.UPDATE_ACCOUNT_INFO, userUpdate);

                    updateProjectCreator(userUpdate);
                })
                .catch(err => {
                    notification.error({message: t("user:messages.update_fail")});
                })
                .finally(() => {
                    setLoading(false);
                });
    }

    function handleSubmit() {
        form.validateFields()
            .then(values => {
                let isUpdatePhone = false;
                let hasDataUpdate = false;
                let formData = new FormData();
                for (let prop in values) {
                    if (values[prop] !== undefined && values[prop] !== user[prop]) {
                        if (prop === "phone" || prop === "region_code") {
                            isUpdatePhone = true;
                        }
                        formData.append(prop, values[prop]);
                        hasDataUpdate = true;
                    }
                }
                if (hasDataUpdate) {
                    if (isUpdatePhone) {
                        return Modal.confirm({
                            title: t("user:modal.confirm_title_update_phone"),
                            onOk: () => updateUserInfo(formData),
                            confirmLoading: true,
                            okText: t("user:btn.yes"),
                            cancelText: t("user:btn.cancel"),
                            maskClosable: false
                        });
                    }
                    return updateUserInfo(formData);
                }
                return false;
            });
    }

    function updateProjectCreator(user) {
        if (!ServicesProject.data) return;
        
        if (user.id === ServicesProject.data.creator.id) {
            ServicesProject.updateData({ ...ServicesProject.data, creator: user });
        }
    }

    const prefixSelector = (
        <Form.Item
            name="region_code"
            noStyle
        >
            <Select
                showSearch
                optionFilterProp={"label"}
                optionLabelProp={"value"}
                placeholder={"Chon ma dien thoai"}
                dropdownMatchSelectWidth={200}
                style={{width: "100px"}}
                defaultValue={user.region_code}
            >
                {
                    COUNTRY_TELEPHONE_CODE.map(item => {
                        return <Option key={item.code} label={item.code + " " + item.name}
                                       value={item.code}>({item.code}) {item.name}</Option>
                    })
                }
            </Select>
        </Form.Item>
    );

    return (
        <Row>
            <Col md={{offset: 4, span: 16}}>
                <h1 className="a-list--title a-text--uppercase text-center mb-3">{t('menu.account_setting').toUpperCase()}</h1>
                <Form
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    validateMessages={validateMessages}
                    size="default"
                    form={form}
                    initialValues={{
                        name: user.name,
                        region_code: user.region_code,
                        phone: user.phone,
                        note: user.note
                    }}
                >
                    <Row gutter={{md: 20}}>
                        <Col xs={24} sm={24} md={18} lg={18}>
                            <Form.Item label={t('user:label.username')}>
                                <span><strong>{user.username}</strong></span>
                            </Form.Item>
                            <Form.Item label={t('user:label.email')}>
                                <span><strong>{user.email}</strong></span>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={t('user:label.name')}
                                rules={validatedRules.name}
                            >
                                <Input name="name"/>
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label={t('user:label.phone')}
                                rules={validatedRules.phone}
                            >
                                <Input
                                    addonBefore={prefixSelector}
                                    style={{width: '100%'}}
                                    name="phone"
                                />
                            </Form.Item>
                            <Form.Item
                                name="note"
                                label={t('user:label.note')}
                                rules={validatedRules.note}
                            >
                                <TextArea rows={4} name="note"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={{offset: 6, span: 18}} md={{offset: 0, span: 6}}>
                            <Form.Item name="avatar">
                                <UploadAvatar
                                    onChange={handleUpload}
                                    imageUrl={imageUrl}
                                    setImageUrl={setImageUrl}
                                    accept="image/jpeg, image/png"
                                    listType="picture-card"
                                    showUploadList={false}
                                    maxFileSize={5}
                                    className="upload-logo"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={18} lg={18}>
                            <Row>
                                <Col md={{offset: 6}} sm={{offset: 6, span: 18}}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={handleSubmit}
                                        loading={loading}
                                        className="pl-4 pr-4"
                                    >
                                        {t("btn.update")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
}

export default AccountSettings;