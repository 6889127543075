import {SortableContainer} from "react-sortable-hoc";
import React from "react";
import SortableItem from "./SortableItem";

function SortableList({projects, updateProjects, errorsDingTalk, is_public}) {
    return (
        <ul>
            {
                projects &&
                projects.map((project, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            project={project}
                            updateProjects={updateProjects}
                            errorsDingTalk={errorsDingTalk}
                            is_public={is_public}
                        />
                    );
                })
            }
        </ul>
    );
}

export default SortableContainer(SortableList);
