import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {auth} from '../services';
import {url} from '../../../system/routing';
import notification from '../../../system/notification';

export default function LoginCallback() {
    const {t} = useTranslation();

    useEffect(loginCallback, []);

    function loginCallback() {
        auth.loginSuccess(url.getQuery('token'));
        notification.success(t('auth:message.auth_success'));
    }

    return null;
}
