import React, {useEffect, useState} from "react";
import {events} from "../../../system/events";
import {Menu, Layout} from "antd";
import {EVENTS} from "../../../layouts/constants";
import {url} from "../../../system/routing";
import formatMenu from "./configs";
import {Link} from "react-router-dom";

const {Sider} = Layout;
const {SubMenu} = Menu;

function MenuLeft({collapsed, expandMobile, toggleCollapsed, toggleExpandMobile}) {
    const [menu, setMenu] = useState([]);
    const [can, setCan] = useState({}); // Quyền thành viên được thực hiện trên project này

    function onClick(path) {
        url.redirect(path);

        if (!collapsed)
            toggleCollapsed();

        if (expandMobile)
            toggleExpandMobile();
    }

    function hasPermission(permissions) {
        return permissions.some(permission => can[permission]);
    }

    useEffect(() => {
        return events.listen([EVENTS.MENU_LEFT], (event, payload) => {
            const { project, userCan } = payload;
            setMenu(formatMenu(project.id));
            setCan(userCan);
        });
    }, []);

    return (
        <Sider width={240} className={"main-menu" + (expandMobile ? " expanded-mobile" : "")} trigger={null} collapsible
               collapsed={collapsed}>
            <div className="left-menu">
                <p className="text-center d-sm-none" style={{lineHeight: "60px"}}>MENU</p>
                <Menu
                    mode="inline"
                    theme="dark"
                    collapsed={`${collapsed}`}
                >
                    {
                        menu.length > 0 &&
                        menu.map((item, index) => {
                            // Nếu không có quyền thì ẩn menu (phải có ít nhất 1 quyền thì hiển thị)
                            if (item.permissions && item.permissions.length > 0 && !hasPermission(item.permissions))
                                return null;

                            if (item.submenu && item.submenu.length > 0)
                                return (
                                    <SubMenu key={index} icon={item.icon} title={item.name}>
                                        {
                                            item.submenu.map((sub, indexSub) => {
                                                // Nếu không có quyền thì ẩn menu (phải có ít nhất 1 quyền thì hiển thị)
                                                if (sub.permissions && sub.permissions.length > 0 && !hasPermission(sub.permissions))
                                                    return null;

                                                return (
                                                    <Menu.Item
                                                        key={index + "_" +indexSub}
                                                        icon={sub.icon}
                                                        onClick={() => {
                                                            onClick(sub.url)
                                                        }}
                                                    >{sub.name}</Menu.Item>
                                                );
                                            })
                                        }
                                    </SubMenu>
                                );
                            else
                                return (
                                    <Menu.Item
                                        key={index}
                                        icon={item.icon}
                                        onClick={() => {
                                            onClick(item.url)
                                        }}
                                    >{item.name}</Menu.Item>
                                );
                        })
                    }
                </Menu>
            </div>
        </Sider>
    );
}

export default MenuLeft;
