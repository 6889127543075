import React, {useEffect, useState} from "react";
import api from "../../../../services/api";
import _ from "lodash";
import {notification} from "antd";
import AddComment from "./AddComment";
import {LoadingOutlined} from "@ant-design/icons";
import CommentItem from "./CommentItem";
import {t} from "../../../../../../system/i18n";

function TicketComments({ticket, userCan}) {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.getComments(ticket.id)
            .then(res => {
                setComments(_.get(res, "data.comments", []));
            })
            .catch(err => {
                const status = _.get(err, "response.status", null);
                if (![403, 404].includes(status))
                    notification.error({message: "Tải dữ liệu bình luận không thành công!"});
            })
            .finally(() => setLoading(false))
    }, [ticket]);

    function pushComment(comment) {
        setComments([comment, ...comments]);
    }

    function removeComment(id) {
        let new_comments = comments.filter(comment => comment.comment._id !== id);
        setComments(new_comments);
    }

    return (
        <div className="log-ticket comments-ticket">
            <p>Nội dung bình luận</p>
            {
                userCan && userCan.commentTicket ?
                    <>
                        <div className="wrapper-logs-ticket">
                            <AddComment
                                ticket={ticket}
                                pushComment={pushComment}
                            />
                            {
                                loading ? <p className="text-center"><LoadingOutlined /></p> : null
                            }
                            {
                                comments.length > 0 &&
                                comments.map(comment => {
                                    return (
                                        <CommentItem
                                            key={comment.id}
                                            comment={comment}
                                            ticket={ticket}
                                            removeComment={removeComment}
                                        />
                                    );
                                })
                            }
                        </div>
                    </> :
                    <div>{t("ticket:messages.not_permission_comment")}</div>
            }
        </div>
    );
}

export default TicketComments;
