import React, {useEffect, useState} from "react";
import { Layout, Table } from "antd";
import _ from 'lodash';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {url, Link} from "../../../../../system/routing";
import {
    SortDescendingOutlined,
    SortAscendingOutlined
} from "@ant-design/icons";
import CustomizedPagination from "../../../../App/CustomizedPagination";
import withSizes from "react-sizes";

const {Content} = Layout;
const mapSizesToProps = ({width}) => ({
    isMobile: width < 580
});

function TicketList({tickets, isLoading, addFilter, filters, pagination, isMobile, settings}) {
    const {t} = useTranslation();
    const {sortBy, sort} = filters || {};
    const [sortField, setSortField] = useState(sortBy);
    const [sortValue, setSortValue] = useState(sort);

    useEffect(() => {
        setSortField(sortBy);
        setSortValue(sort);
    }, [filters]);

    function handleClick(e, field) {
        e.preventDefault();
        if (sortField === field)
            addFilter({sortBy: field, sort: sortValue === "desc" ? "asc" : "desc"});
        else
            addFilter({sortBy: field, sort: "desc"});
    }

    function sortTitle(title, field) {
        return (
            <p className={sortField === field ? "text-danger" : ""}>
                {title}
                <a
                    href="#"
                    onClick={e => handleClick(e, field)}
                    className={"ml-1 " + (sortField === field ? "text-danger" : "text-dark")}
                >
                    { sort === "asc" ? <SortAscendingOutlined /> : <SortDescendingOutlined /> }
                </a>
            </p>
        );
    }

    let columns = isMobile ?
        [
            {
                title: 'Mã',
                dataIndex: 'ticket.id',
                key: 'ticket.id',
                render: function (text, record) {
                    let {ticket, ticketStatus} = record;
                    return (
                        <div className="d-flex align-items-center">
                            <span className="status-ticket-item mr-1" style={{margin: 0, backgroundColor: ticketStatus ? ticketStatus.color : null}}></span>
                            <span>{ticket.id}</span>
                        </div>
                    );
                }
            },
            {
                title: 'Tiêu đề',
                className: 'max-title',
                dataIndex: 'ticket.subject',
                key: 'ticket.subject',
                render: function (text, record) {
                    let {ticket} = record;
                    return <Link href={url.to("detail-ticket", {project_id: ticket.project_id, id: ticket.id})}>{ticket.subject}</Link>;
                }
            },
            {
                title: sortTitle('Loại ticket', "type"),
                dataIndex: 'type',
                key: 'type',
                render: function (text, record) {
                    let {type} = record;
                    return _.isEmpty(type) ? t("common:noText") : type.name;
                }
            }
        ] :
        [
            {
                title: sortTitle('', "status"),
                dataIndex: 'ticket.status',
                key: 'ticket.status',
                render: function (text, record) {
                    let {ticketStatus} = record;
                    return (
                        <span className="status-ticket-item" style={{backgroundColor: ticketStatus ? ticketStatus.color : null}}></span>
                    );
                }
            },
            {
                title: 'Mã',
                dataIndex: 'ticket.id',
                key: 'ticket.id',
                render: function (text, record) {
                    let {ticket} = record;
                    return ticket.id;
                }
            },
            {
                title: sortTitle('Loại ticket', "type"),
                dataIndex: 'type',
                key: 'type',
                render: function (text, record) {
                    let {type} = record;
                    return _.isEmpty(type) ? t("common:noText") : type.name;
                }
            },
            {
                title: 'Tiêu đề',
                className: 'max-title',
                dataIndex: 'ticket.subject',
                key: 'ticket.subject',
                render: function (text, record) {
                    let {ticket} = record;
                    return <Link href={url.to("detail-ticket", {project_id: ticket.project_id, id: ticket.id})}>{ticket.subject}</Link>;
                }
            },
            {
                title: 'Người xử lý',
                dataIndex: 'handler',
                key: 'handler',
                render: function (text, record) {
                    let {handler} = record;
                    return _.isEmpty(handler) ? t("common:noText") : handler.name;
                }
            },
            {
                title: sortTitle('Hết hạn', "expired_at"),
                dataIndex: 'ticket.expired_at',
                key: 'ticket.expired_at',
                render: function (text, record) {
                    let {ticket} = record;
                    return _.isEmpty(ticket.expired_at) ? t("common:noText") : moment(ticket.expired_at).format("DD/MM/YYYY");
                }
            },
            {
                title: sortTitle('Độ ưu tiên', "priority"),
                dataIndex: 'priority',
                key: 'priority',
                render: function (text, record) {
                    let {priority} = record;
                    return _.isEmpty(priority) ? t("common:noText") : priority.name;
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'creator',
                key: 'creator',
                render: function (text, record) {
                    let {creator} = record;
                    return _.isEmpty(creator) ? t("common:noText") : creator.name;
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'ticket.created_at',
                key: 'ticket.created_at',
                render: function (text, record) {
                    let {ticket} = record;
                    return _.isEmpty(ticket.created_at) ? t("common:noText") : moment(ticket.created_at).format("DD/MM/YYYY HH:mm");
                }
            }
        ];
    if (!isMobile) {
            if (!_.get(settings, "ACTIVE_PRIORITY", 1)) {
                columns.splice(6, 1);
            }
    }
    return (
        <Content>
            <Table
                className="ticket-list-table"
                dataSource={tickets}
                columns={columns}
                pagination={false}
                loading={isLoading}
            />
            <CustomizedPagination
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
            />
        </Content>
    );
}

export default withSizes(mapSizesToProps)(TicketList);
