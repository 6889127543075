import React, {useState, useEffect} from 'react';
import {Select, notification, Space, Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {arrayMove} from 'react-sortable-hoc';
import api from "../../services/api";
import {url} from '../../../../system/routing';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ConnectDingTalkAll from "../../components/ConnectDingTalkAll";
import {auth} from "../../../Auth/services";
import lodash from "lodash";
import SortableList from "./SortableList";
import withSizes from "react-sizes";

const Option = Select.Option;

const mapSizesToProps = ({width}) => ({
    isMobile: width < 580
});

function ManageProject({isMobile}) {
    const {t} = useTranslation();
    const user = auth.user();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [is_public, setIsPublic] = useState(0);
    const [errorsDingTalk, setErrorsDingTalk] = useState([]);
    const [project_priorities, setProjectPriorities] = useState([]);

    useEffect(() => {
        document.title = t("menu.project_management");
    }, []);

    useEffect(() => {
        setLoading(true);
        api.getListProject({is_public}).then((res) => {
            const projects = lodash.get(res, "data.projects", []);
            setProjects(projects);
        }).catch((error) => {
            notification.error({message: t("message.server_error")})
        }).finally(() => {
            setLoading(false)
        })
    }, [is_public]);

    useEffect(() => {
        if (project_priorities.length > 0) {
            api.setProjectPriorities({project_priorities}).then((res) => {
            }).catch((error) => {
                notification.error({message: t("message.update_failed")})
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [project_priorities]);

    function onSortEnd({oldIndex, newIndex}) {
        if (!is_public) {
            const newProjects = arrayMove(projects, oldIndex, newIndex);
            const newProjectId = newProjects.map(item => item.id);
            setProjects(newProjects);
            setProjectPriorities(newProjectId);
        }
    }

    function updateProjects(infoUpdate) {
        let projectsInfo = [];
        if (Array.isArray(infoUpdate)) {
            projectsInfo = infoUpdate;
        } else {
            projectsInfo.push(infoUpdate);
        }
        let newProjects = projects.slice();
        projectsInfo.forEach(projectInfo => {
            const foundIndex = newProjects.findIndex(project => project.id === projectInfo.id);
            if (foundIndex > -1) {
                newProjects[foundIndex] = projectInfo;
            }
        });
        setProjects(newProjects);
    }

    function shouldCancelStart(e) {
        if (e.target.tagName === "SPAN" && e.target.className === "pointer-sort")
            return false;

        return true;
    }

    return (
        <div className="m-md-4">
            <div className="mb-3 border pt-3 pb-3 pl-2 pr-2 pl-sm-2 pr-sm-2 header-list-project">
                <Space>
                    <Select
                        defaultValue={is_public}
                        bordered={false}
                        onChange={(value) => setIsPublic(value)}
                    >
                        <Option value={0}>{t("project:label.my_project").toUpperCase()}</Option>
                        <Option value={1}>{t("project:label.public_project").toUpperCase()}</Option>
                    </Select>
                    <ConnectDingTalkAll
                        projects={projects}
                        updateProjects={updateProjects}
                        setErrorsDingTalk={setErrorsDingTalk}
                    />
                </Space>
                {
                    (user.project_limit > 0 && user.project_count < user.project_limit) ?
                        <Link
                            to={url.to("create-project")}
                            className="ant-btn ant-btn-primary float-right text-uppercase"
                            shape="square"
                        >
                            <PlusOutlined className="mr-1"/>
                            {t(isMobile ? "project:btn.create" : "project:label.create_project")}
                        </Link> : null
                }
            </div>

            <SortableList
                useDragHandle={!!is_public}
                projects={projects}
                onSortEnd={onSortEnd}
                shouldCancelStart={shouldCancelStart}
                updateProjects={updateProjects}
                errorsDingTalk={errorsDingTalk}
                is_public={is_public}
            />
        </div>
    );
}

export default withSizes(mapSizesToProps)(ManageProject);
