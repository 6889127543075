import { api } from '../../../system/api';
import { PREFIX_URL } from '../../../system/api/constants';

export default {
	getDepartments: (projectId) => api.get(PREFIX_URL.PROJECT + `/${projectId}/departments`),
	updateDepartment: (departmentId, params) => api.put(`/departments/${departmentId}`, params),
	createDepartment: (projectId, params) => api.post(`/projects/${projectId}/departments`, params),
    updatePosition: (projectId, params) => api.put(PREFIX_URL.PROJECT + `/${projectId}/departments/positions`, params),
	searchUser: (id, params) => api.get(`/departments/${id}/users`, {
		params: params,
		singleRequest: true,
		loading: false
	}),
	addMemberToDepartment: (departmentId, data) => api.post(`/departments/${departmentId}/members`, data),
	getMembersOfDepartment: (departmentId, params) => api.get(`/departments/${departmentId}/members`, params),
	removeMembersOfDepartment: (departmentId, data) => api.put(`/departments/${departmentId}/members/remove`, data),
};
