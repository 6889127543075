import React, {useEffect, useState} from "react";
import {Select} from "antd";
import useStatus from "../hooks/useStatus";

const {Option} = Select;

export default function SelectStatus(props) {
    const {project_id} = props;
    const {statuses} = useStatus(project_id);

    return (
        <Select
            loading={statuses === null ? true : false}
            {...props}
            value={(statuses && statuses.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                statuses &&
                statuses
                    .filter(status => status.is_show)
                    .map(opt => {
                    return <Option key={opt.id} value={opt.id}>{opt.name}</Option>;
                })
            }
        </Select>
    );
}
