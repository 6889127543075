import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";

class ServicesPriorities extends BaseServiceData{
    runApi(resolve) {
        api.getPriorities(this.project_id)
            .then(res => {
                let data = _.get(res, "data.priorities", []);
                this.data = data;
                resolve(data);
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("ticket:label.priority_id").toLowerCase()})});
            })
            .finally(() => {
                this.fetching = false;
            });
    }
}

export default new ServicesPriorities();
