import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TagOutlined} from "@ant-design/icons";
import api from "../../../../services/api";
import {notification, Tooltip} from "antd";
import _ from "lodash";
import SelectTypes from "../../../../../TicketTypes/components/SelectTypes";
import SelectPriorities from "../../../../../TicketPriorities/components/SelectPriorities";
import SelectMembers from "../../../../../Member/components/SelectMembers";
import SelectScopes from "../../../../../TicketScopes/components/SelectScopes";
import SelectDepartment from "../../../../../Department/components/SelectDepartment";
import {events} from "../../../../../../system/events";
import {EVENTS} from "../../../../services/constants";
import SelectMembersDepartment from "../../../../../Department/components/SelectMembersDepartment";

export default function TicketSelect(props) {
    const {t} = useTranslation();
    const {ticket, field, updateTicket, current, type, userCan} = props;
    const [isEdited, setIsEdited] = useState(false);
    const [updating, setUpdating] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        setIsEdited(true);
    }

    function refreshData() {
        events.dispatch(EVENTS.RELOAD_FOLLOWERS);
    }

    function handleChange(value) {
        setUpdating(true);
        api.updateTicket(ticket.id, {[field]: value})
            .then(res => {
                let data = _.get(res, "data", {});
                let newTicket = _.get(data, "ticket", {});
                let newFieldData = _.get(data, type, {});
                notification.success({message: t("ticket:messages.update_success", {attribute: t(`ticket:label.${field}`)})});
                updateTicket({ticket: newTicket, [type]: newFieldData});
                if (field === "department_id") {
                    refreshData();
                }
            })
            .catch(err => {
                notification.error({message: t("ticket:messages.update_fail", {attribute: t(`ticket:label.${field}`)})});
            })
            .finally(() => {
                setIsEdited(false);
                setUpdating(false);
            });
    }

    function handleBlur(e) {
        e.preventDefault();
        setIsEdited(false);
    }

    function renderSelect() {
        const settings = {
            style: {width: "100%"},
            project_id: ticket.project_id,
            value: props.value,
            onBlur: handleBlur,
            onChange: handleChange,
            loading: updating
        };
        switch (field) {
            case "priority_id":
                return <SelectPriorities {...settings} placeholder={t("ticket:placeholder.priority_id")}/>;
            case "handler_id":
                if (ticket.is_shared_internal) {
                    return <SelectMembersDepartment
                        departmentId={ticket.department_id}
                        {...settings}
                        placeholder={t("ticket:placeholder.handler_id")}
                    />;
                } else {
                    return <SelectMembers {...settings} placeholder={t("ticket:placeholder.handler_id")}/>;
                }
            case "scope_id":
                return <SelectScopes {...settings} placeholder={t("ticket:placeholder.scope_id")}/>;
            case "department_id":
                return <SelectDepartment {...settings} placeholder={t("ticket:placeholder.department_id")}/>;
            default:
                return <SelectTypes {...settings} placeholder={t("ticket:placeholder.type_id")}/>;
        }
    }

    return (
        <>
            {
                isEdited && userCan && userCan.updateTicket ?
                    renderSelect(props.field) :
                    <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                        <p
                            onClick={handleClick}
                            className={`p-value${field === "type_id" ? " p-type" : ""}`}
                        >
                            {field === "type_id" ? <TagOutlined className="mr-2"/> : null}
                            {_.isEmpty(current) ? t("common:noText") : (current.name || current.username)}
                        </p>
                    </Tooltip>
            }
        </>
    );
}
