import React, {useState} from 'react';
import {Layout} from 'antd';
import PageHeader from "../modules/App/Header";

const {Content} = Layout;

export default function Dashboard({children}) {

    return (
        <Layout>
            <PageHeader dashboard={true} />
            <Layout id="dashboard-body">
                <Content className="site-content">{children}</Content>
            </Layout>
        </Layout>
    );
}
