import React, { useEffect, useState, useRef } from 'react';
import { Layout, notification } from 'antd';
import StatusFilter from './StatusFilter';
import TicketList from './TicketList';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import _ from 'lodash';
import {events} from "../../../../../system/events";
import { EVENTS } from "../../../services/constants";

const { Content } = Layout;

function TableTickets({addFilter, filters, project_id, isKanban, expand, settings}) {
    const { t } = useTranslation();
    const [tickets, setTickets] = useState([]);
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchTickets();
    }, [project_id, filters, isKanban]);

    useEffect(() => {
        return events.listen([EVENTS.NEW_TICKET_CREATED], (event, payload) => {
            let {ticket} = payload;
            addFilter({ status_id: [ticket.status_id], is_mine: "", t: Math.random()});
        });
    }, []);

    function fetchTickets() {
        setIsLoading(true);
        api.getTickets(project_id, filters)
            .then((res) => {
                setTickets(_.get(res, 'data.tickets', []));
                setPagination(_.get(res, 'data.pagination', {}));
            })
            .catch((err) => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    notification.error({message: t('ticket:messages.403')});
                else if (status === 404)
                    notification.error({message: t('ticket:messages.404')});
                else
                    notification.error({message: t('ticket:messages.fetch_fail')});
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Content>
            <Layout className='table-ticket-list'>
                <StatusFilter
                    project_id={project_id}
                    addFilter={addFilter}
                    filters={filters}
                    expand={expand}
                />

                <TicketList
                    settings={settings}
                    tickets={tickets}
                    isLoading={isLoading}
                    addFilter={addFilter}
                    filters={filters}
                    pagination={pagination}
                />
            </Layout>
        </Content>
    );
}

export default TableTickets;
