import lodash from 'lodash';
import {t} from '../../../system/i18n';

/**
 * Merge data collection item
 *
 * @param {object} currentData
 * @param {object|function} newData
 * @return {object}
 */
const mergeCollectionItemData = (currentData, newData) => {
    let data = lodash.isFunction(newData) ? newData(currentData) : newData;

    return {...currentData, ...data};
};

/**
 * Update data của item trong collection
 *
 * @param {Array} collection
 * @param {string} itemId
 * @param {object|function} data
 * @param {string} idParam
 * @returns {Array}
 */
export const updateCollectionItem = (collection, itemId, data, idParam = 'id') => {
    itemId = lodash.isArray(itemId) ? itemId : [itemId];

    return collection.map(item => itemId.includes(item[idParam]) ? mergeCollectionItemData(item, data) : item);
};

/**
 * Gán data cho item trong collection (nếu collection không tồn tại item thì sẽ thêm mới)
 *
 * @param {Array} collection
 * @param {string} itemId
 * @param {object|function} data
 * @param {string} idParam
 * @returns {Array}
 */
export const setCollectionItem = (collection, itemId, data, idParam = 'id') => {
    let item = lodash.find(collection, {[idParam]: itemId});

    return item
        ? updateCollectionItem(collection, itemId, data, idParam)
        : [...collection, mergeCollectionItemData({[idParam]: itemId}, data)];
};

/**
 * Translate validation errors
 *
 * @param {string} attribute
 * @param {object} errors
 * @param {boolean} ignoreCustomRule
 * @return {object}
 */
export const translateValidationErrors = (attribute, errors, ignoreCustomRule = false) => {
    return lodash.mapValues(errors, (data, rule) => {
        let messageKey = 'validation:' + rule;
        let message = t(messageKey, {...data, attribute});

        return ignoreCustomRule && message === messageKey ? null : message;
    });
};

export const  urlifyContent = (content) => {
    if (content) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urlImgeRegex = /https?:\/\/.*?\.(?:png|jpg|jpeg|gif)/ig;
        return content.replace(urlRegex, function (url) {
            if (url.match(urlImgeRegex) !== null) {
                return '<br/><img style="height: 80px" src="' + url + '"><br/>';
            }
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        });
    }
    return content;

};
