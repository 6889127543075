import React, { useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined, UserAddOutlined, ClockCircleOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import {Link, url} from "../../../../../system/routing";
import moment from "moment";
import {events} from "../../../../../system/events";
import {EVENTS} from "../../../services/constants";
import {t} from "../../../../../system/i18n";

function TicketKanban({ ticketData }) {
    const { handler, ticket } = ticketData;

    function handleClick() {
        events.dispatch(EVENTS.ASSIGN_MEMBER_TO_TICKET, ticket);
    }

    function handleEditTicket() {
        events.dispatch(EVENTS.EDIT_TICKET_FASTLY, ticketData);
    }

    return (
        <div className="ticket-kanban">
            <div className="ticket-kanban-header">
                <div className="ticket-kanban-header-action">
                    <div onClick={handleClick}>
                        <UserAddOutlined />
                        <span>Giao cho</span>
                    </div>
                    <p>
                        <span onClick={handleEditTicket}><EditOutlined /></span>
                    </p>
                </div>

                <div className='ticket-kanban-header-content'>
                    <Avatar
                    shape='square'
                    {...(handler
                        ? { src: handler.avatar }
                        : { icon: <UserOutlined /> })}
                    size={40}
                />
                    <div className='assign-name'>
                        {handler ? (handler.name || handler.username) : 'Chưa tiếp nhận'}
                    </div>
                </div>
            </div>
            <div className="ticket-kanban-body p-2">
                <Link href={url.to("detail-ticket", {project_id: ticket.project_id, id: ticket.id})}>#{ticket.id} {ticket.subject}</Link>
                {
                    ticket.expired_at &&
                    <p className="mt-1">
                        {
                            ticketData.isFollowing &&
                            <strong className="mr-1"><CheckOutlined /> {t("ticket:label.follow")}</strong>
                        }
                        <span><ClockCircleOutlined /> {moment(ticket.expired_at).format("DD/MM")}</span>
                    </p>
                }
            </div>
        </div>
    );
}

export default TicketKanban;
