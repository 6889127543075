import _ from 'lodash';
import {notification} from 'antd';
import {t} from '../../../system/i18n';
import api from './api';
import BaseServiceData from '../../App/services/BaseServiceData';
import {EVENTS_DEPARTMENT} from "./constants";
import {events} from "../../../system/events";

class ServicesDepartments extends BaseServiceData {
    constructor() {
        super();
        events.listen(EVENTS_DEPARTMENT.FETCH_DEPARTMENT_AGAIN, (event, payload) => {
            const {projectId} = payload;
            if (this.project_id === null) {
                this.project_id = projectId;
            }
            this.fetchData()
        });
    }

    runApi(resolve) {
        api.getDepartments(this.project_id)
            .then((res) => {
                let data = _.get(res, 'data.departments', []);
                this.data = data;
                resolve(data);
            })
            .catch((err) => {
                notification.error({
                    message: t('common:message.fetch_fail', {
                        attribute: t('ticket:label.department_id').toLowerCase(),
                    }),
                });
            })
            .finally(() => {
                this.fetching = false;
            });
    }
}

export default new ServicesDepartments();
