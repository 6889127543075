import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingOutlined, SaveFilled, CloseOutlined} from "@ant-design/icons";
import api from "../../../../services/api";
import {Input, notification, Tooltip} from "antd";
import _ from "lodash";
import {urlifyContent} from "../../../../../App/services/helpers";

const {TextArea} = Input;

export default function TicketInput(props) {
    const {t} = useTranslation();
    const {ticket, field, updateTicket, userCan} = props;
    const [isEdited, setIsEdited] = useState(false);
    const [value, setValue] = useState(ticket[field]);
    const [updating, setUpdating] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        setIsEdited(true);
    }

    function handleChange(e) {
        setValue(e.target.value.trim());
    }

    function handleBlur(e) {
        e.preventDefault();
        setIsEdited(false);
        setValue(ticket[field]);
    }

    function handleEnter(e) {
        e.preventDefault();
        if (!_.isEmpty(value)) {
            setUpdating(true);
            api.updateTicket(ticket.id, {[field]: value})
                .then(res => {
                    let newTicket = _.get(res, "data.ticket", {});
                    notification.success({message: t("ticket:messages.update_success", {attribute: t(`ticket:label.${field}`)})});
                    updateTicket({ticket: newTicket});
                })
                .catch(err => {
                    notification.error({message: t("ticket:messages.update_fail", {attribute: t(`ticket:label.${field}`)})});
                })
                .finally(() => {
                    setIsEdited(false);
                    setUpdating(false);
                });
        }
    }

    function renderInput(type) {
        switch (type) {
            case "textarea":
                return <>
                    <TextArea
                        defaultValue={value}
                        onChange={handleChange}
                        suffix={updating ? <LoadingOutlined/> : ""}
                        style={{height: "250px"}}
                    />
                    <a href="#" onClick={handleEnter} style={{fontSize: "18px"}} className="mr-1 text-primary"><SaveFilled /></a>
                    <a href="#" onClick={handleBlur} style={{fontSize: "18px"}} className="text-danger" ><CloseOutlined /></a>
                </>;
            default:
				const suffix = updating ? (
					<LoadingOutlined />
				) : (
					<>
						<SaveFilled
							className='mr-1 text-primary'
							style={{ fontSize: 18, cursor: 'pointer' }}
							onClick={handleEnter}
						/>
						<CloseOutlined
                            className='text-danger'
                            style={{ fontSize: 18, cursor: 'pointer' }}
                            onClick={handleBlur}
                        />
					</>
				);
                return <Input
                    defaultValue={value}
                    onPressEnter={handleEnter}
                    onChange={handleChange}
                    suffix={suffix}
                    size={field === "subject" ? "large" : "middle"}
                    style={field === "subject" ? {fontSize: "20px", fontWeight: "500"} : {}}
                />;
        }
    }

    function renderText(type) {
        switch (type) {
            case "textarea":
                return (
                    <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                        {
                            ticket[field] ?
                                <div
                                    onClick={handleClick}
                                    dangerouslySetInnerHTML={{ __html: urlifyContent(ticket[field]) }}
                                    style={{ whiteSpace: 'break-spaces', lineHeight: 2 }}
                                /> :
                                <p className="p-value" onClick={handleClick}>{t("common:noText")}</p>
                        }
                    </Tooltip>
                );
            default:
                if (field === "subject")
                    return (
                        <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                            <h1 style={{lineHeight: "32px", fontSize: "20px"}} className="p-value" onClick={handleClick}>[#{ticket.id}] - {ticket[field]}</h1>
                        </Tooltip>
                    );
                else
                    return (
                        <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                            <p className="p-value" onClick={handleClick}>{ticket[field] ? ticket[field] : t("common:noText")}</p>
                        </Tooltip>
                    );
        }
    }

    return (
        <>
            {
                isEdited && userCan && userCan.updateTicket ?
                    renderInput(props.type) :
                    <div>
                        {
                            userCan && userCan.updateTicket ?
                                renderText(props.type) :
                                <p className="p-value">{ticket[field] ? ticket[field] : t("common:noText")}</p>
                        }
                    </div>
            }
        </>
    );
}
