import React, {useState} from "react";
import {Button, notification, Tooltip} from "antd";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CloseOutlined, SaveFilled} from "@ant-design/icons";
import _ from "lodash";
import api from "../../services/api"
import {t} from "../../../../system/i18n";

const EditorConfig = {
    // plugins: [ Paragraph, Bold, Italic, Essentials ],
    toolbar: ['bulletedList', 'numberedList', 'bold', 'italic', 'link', 'heading'],
    basicEntities : false,
    entities : false,
    htmlEncodeOutput: false,
    forceSimpleAmpersand : true
};

export default function RichEditor({field, updateTicket, ticket, userCan}) {
    const [value, setValue] = useState(ticket.description);
    const [isEdited, setIsEdited] = useState(false);

    function _onChange(event, editor) {
        let data = editor.getData();
        setValue(data);
    }

    function _onClick(e) {
        e.preventDefault();
        if (e.target.tagName !== "A")
            setIsEdited(true);
        else
            window.open(e.target.href, "_blank");
    }

    function _onCancel(e) {
        e.preventDefault();
        setIsEdited(false);
        setValue(ticket[field]);
    }

    function _onSave(e) {
        e.preventDefault();
        if (!_.isEmpty(value)) {
            api.updateTicket(ticket.id, {[field]: value})
                .then(res => {
                    let newTicket = _.get(res, "data.ticket", {});
                    notification.success({message: t("ticket:messages.update_success", {attribute: t(`ticket:label.${field}`)})});
                    updateTicket({ticket: newTicket});
                })
                .catch(err => {
                    notification.error({message: t("ticket:messages.update_fail", {attribute: t(`ticket:label.${field}`)})});
                })
                .finally(() => {
                    setIsEdited(false);
                });
        }
    }

    return (
        <div className="p-content">
            {
                isEdited && userCan && userCan.updateTicket ?
                <div>
                    <CKEditor
                        editor={ ClassicEditor }
                        data={value}
                        config={EditorConfig}
                        onChange={_onChange}
                    />
                    <a href="#" onClick={_onSave} style={{fontSize: "18px"}} className="mr-1 text-primary"><SaveFilled /></a>
                    <a href="#" onClick={_onCancel} style={{fontSize: "18px"}} className="text-danger" ><CloseOutlined /></a>
                </div> :
                <div>
                    {
                        userCan && userCan.updateTicket ?
                            <Tooltip placement="topLeft" title={t("ticket:messages.click_to_edit")}>
                                <div
                                    className="p-value"
                                    dangerouslySetInnerHTML={{
                                        __html: ticket[field] ? ticket[field] : t("common:noText")
                                    }}
                                    onClick={_onClick}></div>
                            </Tooltip> :
                            <div className="p-value">{ticket[field] ? ticket[field] : t("common:noText")}</div>
                    }
                </div>
            }
        </div>
    );
}

