import { useEffect, useState } from 'react';
import ServicesProject from '../services/ServicesProject';

export default function useProject(project_id) {
	const [state, setState] = useState({
		project: null,
		userCan: null,
		creator: {},
		settings: {},
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		ServicesProject.list(project_id)
			.then((res) => {
				setState(res);
			})
			// .catch(err => {
			//     notification.error({message: t('project:messages.load_fail')});
			// })
			.finally(() => setLoading(false));
	}, [project_id]);

	const { project, userCan, settings } = state;

	return {settings, loading, project, userCan, projectData: state, setProjectData: setState };
}
