import React from "react";
import {Col, Row} from "antd";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import TicketInput from "./TicketInput";
import TicketSelect from "./TicketSelect";
import FollowTicket from "../FollowTicket";
import FileItem from "./FileItem";
import UpdateFile from "./UpdateFile";
import moment from "moment";
import RichEditor from "../../../../components/RichEditor/index";
import CreateUsTaiga from "../CreateUsTaiga";

function TicketDetail({updateTicket, data, userCan, reloadFollower}) {
    const {
        ticket,
        attachments,
        type,
        creator
    } = data;
    let {t} = useTranslation();

    return (
        <div className="ticket-detail">
            <TicketInput
                ticket={ticket}
                field="subject"
                updateTicket={updateTicket}
                userCan={userCan}
            />

            <div className="mb-4 mt-2 d-flex justify-content-start align-items-end flex-wrap">
                <div className="mr-2 mb-md-2">
                    <TicketSelect
                        ticket={ticket}
                        current={type}
                        field="type_id"
                        updateTicket={updateTicket}
                        type="type"
                        value={(type && type.is_active) ? type.id : undefined}
                        userCan={userCan}
                    />
                </div>
                <div className="mr-2 mb-md-2">
                    <FollowTicket
                        data={data}
                        reloadFollower={reloadFollower}
                    />
                </div>
                <div className="mr-2 mb-md-2">
                    {
                        userCan && userCan.createUSTaiga &&
                        <CreateUsTaiga
                            ticket={ticket}
                        />
                    }
                </div>
            </div>
            <div className="ticket-detail-creator mt-2 text-right font-italic">
                Tạo bởi <strong>{creator.name}</strong> tại
                <strong>
                    {
                        _.isEmpty(ticket.created_at) ? t("common:noText") : moment(ticket.created_at).format("DD/MM/YYYY HH:mm")
                    }
                </strong>
            </div>

            <h3 className="sperator pt-2 pb-2 pl-3 pr-3 mt-1 mb-3 text-uppercase">{t("ticket:label.description")}</h3>
            <div>
                <RichEditor
                    field="description"
                    ticket={ticket}
                    updateTicket={updateTicket}
                    userCan={userCan}
                />
            </div>

            <h3 className="sperator pt-2 pb-2 pl-3 pr-3 mt-3 mb-2 d-flex justify-content-between align-items-center">
                <span className="text-uppercase">{t("ticket:label.file")}</span>
                {
                    userCan && userCan.updateTicket &&
                    <UpdateFile
                        ticket={ticket}
                        updateTicket={updateTicket}
                    />
                }
            </h3>
            <div>
                {
                    attachments && attachments.length > 0 ?
                        attachments.map(attachment => {
                            return (
                                <FileItem
                                    key={attachment.id}
                                    attachment={attachment}
                                    ticket={ticket}
                                    updateTicket={updateTicket}
                                />
                            );
                        }) :
                        <p className="text-center mt-3 mb-4">Không có đính kèm nào !</p>
                }
            </div>
        </div>
    );
}

export default TicketDetail;
