import React, {useState} from "react";
import UploadMulti from "../../../../../App/Upload/UploadMulti";
import api from "../../../../services/api";
import _ from "lodash";
import {Form, notification} from "antd";
import {useTranslation} from "react-i18next";
import {events} from "../../../../../../system/events";
import {EVENTS} from "../../../../services/constants";
import {PlusOutlined} from "@ant-design/icons";
import {Input} from "antd/lib/input";

const maxFileSize = 10;
const fileType = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "image/jpeg",
    "image/png",
    "video/mp4"
];

function UpdateFile({ticket, updateTicket}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // Khi upload nhiều file thì onChange của antd xử lý update mỗi 1 là 1 sự kiện
    // function handleUpload(files) {
    //     if (handler.current)
    //         clearInterval(handler.current);
    //     handler.current = setInterval(() => {
    //         setLoading(true);
    //         let formData = new FormData();
    //         files.forEach(item => formData.append("file[]", item));
    //         api.updateAttachments(ticket.id, formData)
    //             .then(res => {
    //                 let attachments = _.get(res, "data.attachments", []);
    //                 updateTicket({attachments});
    //                 events.dispatch(EVENTS.UPLOAD_FILE);
    //             })
    //             .catch(err => {
    //                 let response = _.get(err, "response", {});
    //                 switch (response.status) {
    //                     case 404:
    //                         notification.error({message: t("common:message.404")});
    //                         return;
    //                     case 403:
    //                         notification.error({message: t("common:message.403")});
    //                         return;
    //                     default:
    //                         notification.error({message: t("common:message.server_error")});
    //                         return;
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //         clearInterval(handler.current);
    //     }, 500);
    // }

    function uploadFile() {
        form.validateFields()
            .then(values => {
                let formData = new FormData();
                for (let prop in values) {
                    if (values[prop]) {
                        values[prop].forEach(image => formData.append(prop + "[]", image))
                    }
                }

                setLoading(true);
                api.updateAttachments(ticket.id, formData)
                    .then(res => {
                        let attachments = _.get(res, "data.attachments", []);
                        updateTicket({attachments});
                        events.dispatch(EVENTS.UPLOAD_FILE);
                        notification.success({message: t("ticket:messages.add_attachment_success")});
                        form.resetFields();
                    })
                    .catch(err => {
                        let response = _.get(err, "response", {});
                        switch (response.status) {
                            case 404:
                                notification.error({message: t("common:message.404")});
                                return;
                            case 403:
                                notification.error({message: t("common:message.403")});
                                return;
                            default:
                                notification.error({message: t("common:message.server_error")});
                                return;
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });


    }

    function beforeUpload(file) {
        if (fileType) {
            if (fileType.indexOf(file.type.toLowerCase()) < 0) {
                return false;
            }
        }

        if (maxFileSize) {
            const validMaxSize = (file.size / 1024 / 1024) > maxFileSize;
            if (validMaxSize) {
                return false;
            }
        }
        return true;
    }

    function handleUpload(files) {
        let listFile = [];
        let error = false;
        files.forEach(file => {
            const validFile = beforeUpload(file);
            if (validFile) {
                listFile.push(file);
            } else {
                error = true;
            }
        });
        if (!error) {
            if (listFile.length > 0) {
                form.setFields([{name: "file", value: listFile}]);
            }
            uploadFile();
        } else {
            form.resetFields();
        }
    }

    return (
        <div style={{cursor: "pointer"}}>
            <Form size="default" form={form}>
                <Form.Item name="file" className="mb-0">
                    <UploadMulti
                        onChange={handleUpload}
                        accept=".doc,.docx,.xls,.xlsx,.pdf,image/jpeg,image/png,.mp4"
                        fileType={fileType}
                        maxFileSize={maxFileSize}
                        showUploadList={false}
                        loading={loading}
                    ><PlusOutlined/> THÊM </UploadMulti>
                </Form.Item>
            </Form>
        </div>
    );
}

export default UpdateFile;
