import React, {useEffect, useState} from "react";
import {Button, Layout, Col, Row, notification, Checkbox} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {arrayMove} from "react-sortable-hoc";
import SortableList from "./SortableList";
import {t} from "../../../../../system/i18n";
import api from "../../../../TicketScopes/services/api";
import apiProject from "../../../../Project/services/api";
import _ from "lodash";
import ModalUpdateTicketScope from "../../../../TicketScopes/components/ModalUpdateTicketScope";
import {events} from "../../../../../system/events";
import {EVENTS as EVENTS_PROJECT} from "../../../services/constants";

const {Header, Content} = Layout;

export default function ScopeSetting({project_id, title, updateCategoryService, settings}) {
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasDefault, setHasDefault] = useState(false);
    const [currentData, setCurrentData] = useState(false);
    const [typeForm, setTypeForm] = useState("create");
    const [isOpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {
        fetchScopes();
    }, [project_id]);

    function fetchScopes() {
        setLoading(true);
        api.getScopes(project_id)
            .then(res => {
                const scopes = _.get(res, "data.scopes", []);
                const indexHasDefault = _.findIndex(scopes, "is_default");
                setHasDefault(indexHasDefault >= 0 ? scopes[indexHasDefault] : false);
                setItems(scopes);
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("ticket:label.scope_id").toLowerCase()})});
            })
            .finally(() => setLoading(false));
    }

    function updateItem(updatedItem) {
        let newItems = items.map(item => {
            if (item.id === updatedItem.id)
                return updatedItem;
            else
                return updatedItem.is_default ? {...item, is_default: false} : item;
        });

        setItems(newItems);
    }

    function updateScope(scope_id, params) {
        api.updateScope(scope_id, params)
            .then(res => {
                updateCategoryService();
                updateItem(_.get(res, "data.ticketScope", {}));
                notification.success({message: t("common:message.update_success_by_attribute", {attribute: t("ticket:label.scope_id").toLowerCase()})})
            })
            .catch(err => {
                notification.error({message: t("common:message.update_failed_by_attribute", {attribute: t("ticket:label.scope_id").toLowerCase()})})
            });
    }

    function onSortEnd({oldIndex, newIndex}) {
        if (items && items.length === 1) return false;

        let newItems = arrayMove(items, oldIndex, newIndex);
        let params = newItems.map((item, index) => ({id: item.id, position: index}));
        setItems(newItems);
        api.updatePosition(project_id, params)
            .then(res => {
                updateCategoryService();
                notification.success({message: t("common:message.update_success_by_attribute", {attribute: t("ticket:label.scope_id").toLowerCase()})})
            })
            .catch(err => {
                notification.error({message: t("common:message.update_failed_by_attribute", {attribute: t("ticket:label.scope_id").toLowerCase()})})
            });
    }

    function shouldCancelStart(e) {
        if (e.target.tagName === "SPAN" && e.target.className === "pointer-sort")
            return false;

        return true;
    }

    const openModal = (type = "create", record = false) => {
        setIsOpenModal(!isOpenModal);
        setTypeForm(type);
        setCurrentData(record);
    };

    function handleOnChangeHiddenOnTicket(e) {
        apiProject.updateSettings(project_id, {ACTIVE_SCOPE: e.target.checked ? 0 : 1})
            .then((res) => {
                events.dispatch(EVENTS_PROJECT.FETCH_PROJECT_INFO_AGAIN);
                notification.success({message: t("common:message.update_success_by_attribute", {attribute: t("ticket:label.scope_id").toLowerCase()})});
            }).catch(() => notification.error({message: t("common:message.update_failed_by_attribute", {attribute: t("ticket:label.scope_id").toLowerCase()})}))
    }

    return (
        <div>
            <Header
                className='title-page bg-white border-bottom d-flex align-items-center pl-4 pr-4'>
                <h2>{title}</h2>
                {
                    !_.isEmpty(settings) &&
                    <Checkbox
                        className="ml-4"
                        onChange={handleOnChangeHiddenOnTicket}
                        defaultChecked={!_.get(settings, "ACTIVE_SCOPE", 1)}
                    >
                        <i>{t("settings:btn.hidden_on_ticket")}</i>
                    </Checkbox>
                }
                <Button className="ml-auto" type="primary" onClick={() => openModal("create")}>
                    <PlusOutlined/>{t("common:btn.create")}
                </Button>
            </Header>
            <Content className="category-page">
                <div className="category-header">
                    <Row>
                        <Col span={3}>{t("settings:label.active")}</Col>
                        <Col xs={{span: 10}}
                             md={{span: 6}}>{t("settings:label.name", {attribute: t("settings:title.scope_name")})}</Col>
                        <Col span={6} className="d-none d-md-flex">{t("settings:label.description")}</Col>
                        <Col xs={{span: 5}} md={{span: 3}}
                             className="text-center">{t("settings:label.is_default")}</Col>
                        <Col span={6} className="text-right"><span
                            className="pr-2">{t("settings:label.position")}</span></Col>
                    </Row>
                </div>

                {
                    loading ?
                        <div className="p-4 text-center" style={{fontSize: "24px"}}>
                            <LoadingOutlined/>
                        </div> :
                        <SortableList
                            items={items}
                            onSortEnd={onSortEnd}
                            shouldCancelStart={shouldCancelStart}
                            onShow={updateScope}
                            openModalUpdate={openModal}
                        />
                }

            </Content>
            <ModalUpdateTicketScope
                visible={isOpenModal}
                handleModal={openModal}
                typeForm={typeForm}
                currentData={currentData}
                projectId={project_id}
                onSucess={fetchScopes}
                hasDefault={hasDefault}
                updateCategoryService={updateCategoryService}
            />
        </div>
    );

}
