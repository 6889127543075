import DefaultLayout from './layouts/Default';
import Dashboard from './layouts/Dashboard';
import Error404 from './modules/App/Error/404';
import Error403 from './modules/App/Error/403';
import Login from './modules/Auth/Login';
import LoginCallback from './modules/Auth/LoginCallback';
import CreateProject from "./modules/Project/screens/CreateProject";
import ListTickets from "./modules/Ticket/screens/ListTickets";
import authenticate from './modules/Auth/middleware/authenticate';
import loadAuthUser from './modules/Auth/middleware/loadAuthUser';
import ManageProject from "./modules/Project/screens/ManageProject";
import DetailTicket from "./modules/Ticket/screens/DetailTicket";
import ManageMember from "./modules/Member/screens/ManageMember";
import AccountSettings from "./modules/User/screens/AccountSettings";
import Settings from "./modules/Project/screens/Settings";
import ManageRoles from "./modules/Project/screens/ManageRoles";
import ConfigProject from "./modules/Project/screens/ConfigProject";
import ConfigNotification from "./modules/Project/screens/ConfigNotification";
import ListDepartment from "./modules/Department/screens/ListDepartment";
import DepartmentDetail from "./modules/Department/screens/DepartmentDetail/index";

export default {
    routes: [
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            name: 'error-404',
            path: '/error-404',
            component: Error404,
        },
        {
            name: 'error-403',
            path: '/error-403',
            component: Error403,
        },
        {
            name: 'login.callback',
            path: '/login/callback',
            component: LoginCallback,
        },
        {
            group: {
                layout: Dashboard,
                middleware: [authenticate, loadAuthUser],
                routes: [
                    {
                        name: 'home',
                        path: '/',
                        component: ManageProject
                    },
                    {
                        name: 'create-project',
                        path: '/projects/create',
                        component: CreateProject
                    },
                    {
                        name: 'manage-project',
                        path: '/projects/manage',
                        component: ManageProject
                    },
                    {
                        name: 'list-tickets',
                        path: '/projects/:id/tickets',
                        component: ListTickets,
                        layout: DefaultLayout
                    },
                    {
                        name: 'manage-member',
                        path: '/projects/:id/members',
                        component: ManageMember,
                        layout: DefaultLayout
                    },
                    {
                        name: 'detail-ticket',
                        path: '/tickets/:id',
                        component: DetailTicket,
                        layout: DefaultLayout
                    },
                    {
                        name: 'account-setting',
                        path: '/account/setting',
                        component: AccountSettings
                    },
                    {
                        name: 'category-settings',
                        path: '/projects/:id/:type-settings',
                        component: Settings,
                        layout: DefaultLayout
                    },
                    {
                        name: 'manage-roles',
                        path: '/projects/:id/manage-roles',
                        component: ManageRoles,
                        layout: DefaultLayout
                    },
                    {
                        name: 'project-settings',
                        path: '/projects/:id/settings',
                        component: ConfigProject,
                        layout: DefaultLayout
                    },
                    {
                        name: 'notification-settings',
                        path: '/projects/:id/notification-settings',
                        component: ConfigNotification,
                        layout: DefaultLayout
                    },
                    {
                        name: 'departments',
                        path: '/projects/:id/departments',
                        component: ListDepartment,
                        layout: DefaultLayout
                    },
                    {
                        name: 'department-detail',
                        path: '/projects/:id/departments/:department_id',
                        component: DepartmentDetail,
                        layout: DefaultLayout
                    }
                ],
            },
        },
    ],
    defaultRoute: {
        component: Error404,
    },
};
