import React, {useEffect, useState} from 'react';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import TicketKanban from './TicketKanban';
import {EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import {notification, Tooltip} from "antd";
import api from "../../../services/api";
import {useTranslation} from "react-i18next";
import _ from "lodash";

function KanbanColumn({status, tickets, filters, addFilter}) {
    const [statusTickets, setStatusTickets] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        if (!_.isEmpty(tickets)) {
            setStatusTickets(tickets.filter(item => {
                let {ticket} = item;
                return ticket.status_id === status.id;
            }));
        } else {
            setStatusTickets([]);
        }
    }, [tickets]);

    function toggleClosedStatus(e) {
        e.preventDefault();
        if (filters.show_closed == 1)
            addFilter({show_closed: ""});
        else
            addFilter({show_closed: 1});
    }

    return (
        <Droppable droppableId={status.id.toString()}>
            {(provided) => (
                <div
                    className="kanban-column"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    <div className='kanban-column-wrap'>
                        <div
                            className={`kanban-column-header`}
                            style={{
                                color: status.color ? status.color : "#0048ad",
                                borderTopColor: status.color ? status.color : "#0048ad"
                            }}
                        >
                            {status.name} ({statusTickets ? statusTickets.length : 0})
                            {
                                status.is_closed &&
                                <a onClick={toggleClosedStatus} className="float-right">
                                    <Tooltip title={filters.show_closed ? t("ticket:title.hidden_ticket") : t("ticket:title.show_ticket")}>
                                        {
                                            filters.show_closed ? <EyeInvisibleOutlined/> : <EyeOutlined/>
                                        }
                                    </Tooltip>
                                </a>
                            }
                        </div>
                        <div className="kanban-column-body">
                            {
                                !_.isEmpty(statusTickets) &&
                                statusTickets.map((item, index) => {
                                    const {ticket} = item;
                                    return (
                                        <div key={ticket.id}>
                                            <Draggable
                                                draggableId={ticket.id.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <TicketKanban
                                                            ticketData={item}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    );
                                })
                            }
                            {
                                status.is_closed && !filters.show_closed &&
                                <div style={{fontSize: "12px", fontWeight: 500}}>
                                    {t("ticket:placeholder.hidden_ticket_close_status")}
                                </div>
                            }
                        </div>
                    </div>
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
}

export default KanbanColumn;
