import React, {useRef, useState} from "react";
import {AutoComplete, Input, notification, Spin, Form} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import lodash from "lodash";
import api from "../services/api";
import {useTranslation} from "react-i18next";

function AddMember({userCan, projectId, getMembers}) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    let formRef = useRef();

    const isEmail = (keyword) => {
        return keyword.includes("@");
    };

    function addMemberToProject(data) {
        setLoading(true);
        api.addMember(projectId, data).then(res => {
            getMembers();
            setOptions([]);
            formRef.current.resetFields();
            notification.success({message: t("member:message.add_member_success")})
        }).catch((error) => {
            const responseData = lodash.get(error, "response.data", undefined);
            if (responseData && responseData.code === "INPUT_INVALID") {
                notification.error({message: t("member:error." + (lodash.keys(responseData.data.member)[0]).toLowerCase())})
            } else {
                notification.error({message: t("message.server_error")})
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const getListUserAddMember = lodash.debounce((keyword) => {
        if (!keyword) {
            setOptions([]);
            return;
        }
        setLoading(true);
        api.searchUser(projectId, {keyword: keyword}).then(res => {
            let users = lodash.get(res, "data.users", []);
            if (users.length > 0) {
                let options = users.map(user => ({value: user.email}));
                setOptions(options);
            } else {
                const isValid = isEmail(keyword);
                if (isValid) {
                    setOptions([{value: keyword}])
                } else {
                    setOptions([])
                }
            }
        }).catch((error) => {
            notification.error({message: t("message.server_connection_error")})
        }).finally(() => {
            setLoading(false)
        })
    }, 500);

    const handleOnSelect = (email) => {
        handleSelectMemberAddProject(email);
    };

    const handleSelectMemberAddProject = (email) => {
        const isValid = isEmail(email);
        if (isValid) {
            let data = {emails: [email], role: "MEMBER"};
            addMemberToProject(data);
        } else {
            notification.error({message: t("validation:email", {attribute: "Email thành viên muốn thêm"})});
        }
    };

    // Nếu không có quyền thêm thành viên
    if (userCan === null || (userCan && !userCan.addMember))
        return null

    return (
        <Spin spinning={loading}>
            <Form ref={formRef}>
                <Form.Item name="search_member">
                    <AutoComplete
                        options={options}
                        style={{width: "100%"}}
                        onChange={getListUserAddMember}
                        onSelect={handleOnSelect}
                    >
                        <Input
                            suffix={<SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                            size="large"
                            placeholder={t("member:placeholder.add_member")}
                            loading={loading.toString()}
                        />
                    </AutoComplete>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default AddMember;
