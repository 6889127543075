import React, {useEffect, useState} from "react";
import {Form, Row, Col, Button, DatePicker, Input, notification} from "antd";
import {SearchOutlined, UndoOutlined, DownloadOutlined} from "@ant-design/icons";
import SelectMembers from "../../../../Member/components/SelectMembers";
import SelectTypes from "../../../../TicketTypes/components/SelectTypes";
import SelectPriorities from "../../../../TicketPriorities/components/SelectPriorities";
import moment from "moment";
import {useTranslation} from "react-i18next";
import SelectScopes from "../../../../TicketScopes/components/SelectScopes";
import withSizes from "react-sizes";
import SelectDepartment from "../../../../Department/components/SelectDepartment";
import _ from 'lodash';
import api from "../../../services/api";

const {RangePicker} = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const mapSizesToProps = ({width}) => ({
    isMobile: width < 580
});

function FormSearch({project_id, addFilter, filters, expand, isMobile, settings}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const {creator_id, handler_id, type_id, priority_id, expired_at_from, expired_at_to, scope_id, department_id, keyword, follower_id} = input || {};
    let countDisplay = 0;
    if (!!_.get(settings, "ACTIVE_PRIORITY", 1)) countDisplay += 1;
    if (!!_.get(settings, "ACTIVE_SCOPE", 1)) countDisplay += 1;
    if (!!_.get(settings, "ACTIVE_DEPARTMENT", 1)) countDisplay += 1;
    const offsetSubmit = countDisplay === 0 ? 6 : (countDisplay === 1 ? 0 : (countDisplay === 2 ? 18 : 12));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function handleChange(name, value) {
        setInput({...input, [name]: value});
    }

    function handleChangeDate(dates) {
        setInput({
            ...input,
            expired_at_from: dates ? dates[0].format("YYYY-MM-DD") : "",
            expired_at_to: dates ? dates[1].format("YYYY-MM-DD") : ""
        });
    }

    function handleSubmit() {
        addFilter(input);
    }

    function handleDownload() {
        addFilter(input);
        setLoading(true);
        api.exportTickets(project_id, input)
            .then(res => {
                notification.success({message: t("message.export_excel_success")})
            }).catch(err  =>  {
            notification.error({message: t("message.export_excel_failed")})
        }).finally(() => setLoading(false));
    }

    function handleReset() {
        addFilter({
            creator_id: "",
            handler_id: "",
            type_id: "",
            priority_id: "",
            expired_at_from: "",
            expired_at_to: "",
            status_id: [],
            sort: "",
            sortBy: "",
            page: "",
            per_page: "",
            scope_id: "",
            department_id: "",
            follower_id: "",
            keyword: ""
        });
    }

    return (
        <div className={`search-form-tickets border-bottom ${!expand && isMobile ? "d-none" : ""}`}>
            <Form
                className="pl-4 pr-4 pt-3 pb-4"
                form={form}
                colon=""
                layout="vertical"
            >
                <Row gutter={20} className="d-flex flex-wrap">
                    <Col xs={{span: 24}} lg={{span: 24}}>
                        <Row gutter={20}>

                            <Col xs={{span: 12}} lg={{span: 6}}>
                                <Form.Item
                                    className="mb-2 mb-md-0"
                                    label="Người tạo"
                                >
                                    <SelectMembers
                                        placeholder="Chọn người tạo"
                                        project_id={project_id}
                                        allowClear={true}
                                        value={creator_id ? parseInt(creator_id) : undefined}
                                        onChange={value => handleChange("creator_id", value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 12}} lg={{span: 6}}>
                                <Form.Item
                                    className="mb-2 mb-md-0"
                                    label="Người xử lý"
                                >
                                    <SelectMembers
                                        placeholder="Chọn người xử lý"
                                        project_id={project_id}
                                        allowClear={true}
                                        value={handler_id ? (handler_id === "not_assigned" ? handler_id : parseInt(handler_id)) : undefined}
                                        onChange={value => handleChange("handler_id", value)}
                                        has_not_assigned={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{span: 12}} lg={{span: 6}}>
                                <Form.Item
                                    className="mb-2 mb-md-0"
                                    label="Loại ticket"
                                >
                                    <SelectTypes
                                        placeholder="Chọn loại ticket"
                                        project_id={project_id}
                                        allowClear={true}
                                        value={type_id ? parseInt(type_id) : undefined}
                                        onChange={value => handleChange("type_id", value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 12}} lg={{span: 6}}>
                                <Form.Item
                                    className="mb-2 mb-md-0"
                                    label="Ngày hết hạn"
                                >
                                    <RangePicker
                                        value={(expired_at_from && expired_at_to) ? [moment(expired_at_from), moment(expired_at_to)] : undefined}
                                        format={dateFormat}
                                        placeholder={[t("common:label.time_start"), t("common:label.time_end")]}
                                        onChange={handleChangeDate}
                                        style={{width: "100%"}}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 12}} lg={{span: 6}}>
                                <Form.Item
                                    className="mb-0 mt-md-2"
                                    label="Người theo dõi"
                                >
                                    <SelectMembers
                                        placeholder="Chọn người theo dõi"
                                        project_id={project_id}
                                        allowClear={true}
                                        value={follower_id ? parseInt(follower_id) : undefined}
                                        onChange={value => handleChange("follower_id", value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{span: 12}} lg={{span: 6}}>
                                <Form.Item
                                    className="mb-0 mt-md-2"
                                    label="Tiêu đề/Nội dung"
                                >
                                    <Input
                                        placeholder="Nhập từ khóa tiêu đề/nội dung"
                                        allowClear={true}
                                        onChange={e => handleChange("keyword", e.target.value)}
                                        value={keyword}
                                        onPressEnter={handleSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            {
                                !!_.get(settings, "ACTIVE_PRIORITY", 1) &&
                                <Col xs={{span: 12}} lg={{span: 6}}>
                                    <Form.Item
                                        className="mb-0 mt-md-2"
                                        label="Độ ưu tiên"
                                    >
                                        <SelectPriorities
                                            placeholder="Chọn độ ưu tiên"
                                            project_id={project_id}
                                            allowClear={true}
                                            value={priority_id ? parseInt(priority_id) : undefined}
                                            onChange={value => handleChange("priority_id", value)}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                !!_.get(settings, "ACTIVE_SCOPE", 1) &&
                                <Col xs={{span: 12}} lg={{span: 6}}>
                                    <Form.Item
                                        className="mb-0 mt-md-2"
                                        label="Phạm vi liên quan"
                                    >
                                        <SelectScopes
                                            placeholder="Chọn phạm vi liên quan"
                                            project_id={project_id}
                                            allowClear={true}
                                            value={scope_id ? parseInt(scope_id) : undefined}
                                            onChange={value => handleChange("scope_id", value)}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                !!_.get(settings, "ACTIVE_DEPARTMENT", 1) &&
                                <Col xs={{span: 12}} lg={{span: 6}}>
                                    <Form.Item
                                        className="mb-0 mt-md-2"
                                        label="Phòng ban - Đối tác"
                                    >
                                        <SelectDepartment
                                            placeholder="Chọn phòng ban - Đối tác"
                                            project_id={project_id}
                                            allowClear={true}
                                            value={department_id ? parseInt(department_id) : undefined}
                                            onChange={value => handleChange("department_id", value)}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row className="d-flex mt-3">
                            <Button
                                loading={loading}
                                className="mr-auto"
                                type="primary"
                                onClick={handleDownload}
                            ><DownloadOutlined/> Kết xuất Excel</Button>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                            ><SearchOutlined/> Tìm kiếm</Button>
                            <Button
                                className="ml-2"
                                onClick={handleReset}
                            ><UndoOutlined/> {t("common:btn.reset")}</Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default withSizes(mapSizesToProps)(FormSearch);
