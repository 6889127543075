import React, {useEffect, useState} from 'react';
import lodash from 'lodash';
import api from "../services/api";
import {notification, Select} from 'antd';
import {t} from "../../../system/i18n";

const {Option} = Select;

export default function SelectMembersDepartment(props) {
    let [members, setMembers] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const {departmentId} = props;

    useEffect(() => {
        setIsLoading(true);
        api.getMembersOfDepartment(departmentId).then((res) => {
            setMembers(lodash.get(res, "data.members", []));
        }).catch((err) => {
            notification.error({
                message: t('common:message.fetch_fail', {
                    attribute: t('ticket:label.handler_id').toLowerCase(),
                }),
            });
        }).finally(() => {
            setIsLoading(false);
        });

    }, [departmentId]);
    return (
        <Select
            loading={isLoading}
            {...props}
            value={members.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {members.length > 0 &&
            members.map((opt) => <Option key={opt.id} value={opt.id}>{opt.name}</Option>)
            })
        </Select>
    );
}
