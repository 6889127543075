import {useEffect, useState} from "react";
import _ from "lodash";
import queryString from "query-string";

export default function useFilter(history, location) {
    const [filters, setFilters] = useState(null);
    const currentFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});

    useEffect(() => {
        let tmpFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});
        let {status_id} = tmpFilters;
        if (status_id && status_id.length > 0) {
            status_id = status_id.map(item => parseInt(item));
            setFilters({...tmpFilters, status_id});
        } else {
            setFilters(tmpFilters);
        }
    }, [location.search]);

    function addFilter(filter) {
        let filters = _.omitBy({...currentFilters, ...filter}, value => value === "");
        history.push(location.pathname + '?' + queryString.stringify(filters, {arrayFormat: 'bracket'}));
    }

    return {filters, addFilter};
}
