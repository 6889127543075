import React, {useState} from 'react';
import api from "../services/api";
import {notification, Select} from "antd";
import lodash from "lodash";
import {useTranslation} from "react-i18next";

const Option = Select.Option;

function AddRole({projectId, memberInfo, roles, handleMembers, userCan}) {
    const [loading, setLoading] = useState(false);
    const disabled = handleDisabled();
    const [roleId, setRoleId] = useState(memberInfo.project_member.role_id || undefined);
    const {t} = useTranslation();

    const setRole = (data) => {
        setLoading(true);
        api.setRole(projectId, data).then(res => {
            handleMembers();
            setRoleId(data.role_id);
            return notification.success({message: t("member:message.update_role")});
        }).catch(error => {
            const responseData = lodash.get(error, "response.data", undefined);
            if (responseData) {
                switch (responseData.code) {
                    case "INPUT_INVALID":
                        if (responseData.data.owner && responseData.data.owner.REQUIRED) {
                            notification.error({message: t("member:message.owner_required")});
                        } else {
                            notification.error({message: t("message.server_error")});
                        }
                        break;
                    case 403:
                        notification.error({message: t("member:message.403")});
                        break;
                    default:
                        notification.error({message: t("message.server_error")});
                        break;
                }
            } else {
                notification.error({message: t("message.server_error")})
            }

        }).finally(() => {
            setLoading(false);
        })
    };

    function handleChange(role_id) {
        setRole({role_id, user_id: memberInfo.member.id});
    }

    function handleDisabled() {
        // Nếu không có quyền thêm thành viên
        if (userCan && !userCan.addMember)
            return true;

        return false;
    }

    return (
        <Select
            loading={loading}
            value={roleId}
            onChange={handleChange}
            // dropdownMatchSelectWidth={200}
            placeholder={t("member:placeholder.update_role")}
            disabled={disabled}
            style={{width: "80%"}}
        >
            {
                roles.length > 0 && roles.map((item, index) => {
                    return <Option key={index} value={item.id}>{item.name}</Option>
                })
            }
        </Select>

    );
}

export default AddRole;
