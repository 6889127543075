import {useEffect, useState} from "react";
import api from "../services/api";
import _ from "lodash";
import {notification} from "antd";
import {t} from "../../../system/i18n";

export default function useStatus(project_id) {
    let [statuses, setStatuses] = useState(null);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStatus();
    }, [project_id]);

    function fetchStatus() {
        setLoading(true);
        api.getStatuses(project_id)
            .then(res => {
                let data = _.get(res, "data.statuses", []);
                setStatuses(data);
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("status:label.status").toLowerCase()})});
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return {loading, statuses};
}
