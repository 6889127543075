import React, {useEffect, useState} from "react";
import {Layout, Divider, notification, Tooltip} from "antd";
import {
    LeftCircleOutlined,
    RightCircleOutlined,
    EyeOutlined,
    EyeInvisibleOutlined
} from "@ant-design/icons";
import api from "../../../services/api";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import withSizes from "react-sizes";

const {Sider} = Layout;
const mapSizesToProps = ({width}) => ({
    isMobile: width < 580
});

function StatusFilter({project_id, addFilter, filters, expand, isMobile}) {
    const [collapsed, setCollapsed] = useState(false);
    const [stats, setStats] = useState(null);
    const {t} = useTranslation();
    let statusIds = filters.status_id || [];

    useEffect(() => {
        fetchStats();
    }, [project_id, filters]);

    function fetchStats() {
        let params = _.pick(filters, ["creator_id", "handler_id", "type_id", "priority_id", "expired_at_from", "expired_at_to", "scope_id", "department_id", "follower_id", "keyword"]);

        api.getStats(project_id, _.omitBy(params, value => value === null && value === undefined))
            .then(res => {
                    setStats(_.get(res, "data.stats", {}))
            })
            .catch(err => {
                notification.success({message: t("ticket:messages.fetch_fail")});
            })
            .finally(() => {});
    }

    function handleCollapsed(e) {
        e.preventDefault();
        setCollapsed(!collapsed);
    }

    function handleClick(e, statusId) {
        e.preventDefault();

        if (statusId === "") {
            addFilter({status_id: "", is_mine: ""});
            return;
        }

        if (statusIds.includes(statusId))
            statusIds = statusIds.filter(id => id !== statusId)
        else
            statusIds.push(statusId);

        addFilter({status_id: statusIds, is_mine: ""});
    }

    function handleMine(e) {
        e.preventDefault();
        addFilter({is_mine: 1, status_id: ""});
    }

    function toggleClosedStatus(e) {
        e.preventDefault();
        if (filters.show_closed == 1)
            addFilter({show_closed: ""});
        else
            addFilter({show_closed: 1});
    }

    let closedStatusName = "";
    if (stats) {
        stats.forEach(item => {
            let {status} = item;
            if (status && status.is_closed === true)
                closedStatusName = status.name;
        });
    }

    return (
        <>
            {
                stats && !_.isEmpty(stats) &&
                <Sider className={`bg-white status-filter-tickets border-right ${!expand && isMobile ? "d-none" : ""}`} width={collapsed ? "auto" : 240}>

                    <ul>
                        <li>
                            {
                                collapsed ?
                                    <Tooltip title="Tất cả">
                                        <a
                                            href="#"
                                            onClick={e => handleClick(e, "")}
                                            className={!filters.status_id && !filters.is_mine ? "active" : ""}
                                        >
                                            <span className="status-ticket"></span>
                                            <strong className="ml-1">({stats.reduce((total, item) => total+(item.status === "MINE" ? 0 : item.count), 0)})</strong>
                                        </a>
                                    </Tooltip>:
                                    <a
                                        href="#"
                                        onClick={e => handleClick(e, "")}
                                        className={!filters.status_id && !filters.is_mine ? "active" : ""}
                                    >
                                        <span className="status-ticket"></span>
                                        <strong className="ml-2">Tất cả ({stats.reduce((total, item) => total+(item.status === "MINE" ? 0 : item.count), 0)})</strong>
                                    </a>
                            }
                            {
                                closedStatusName !== "" &&
                                <a
                                    href="#"
                                    className={`all_closed_status${filters.show_closed == 1 ? " active" : ""}`}
                                    onClick={toggleClosedStatus}
                                >
                                    {
                                        filters.show_closed == 1 ?
                                            <Tooltip title={"Click để ẩn ticket ở trạng thái " + closedStatusName}><EyeOutlined /></Tooltip> :
                                            <Tooltip title={"Click hiển thị ticket ở trạng thái " + closedStatusName}><EyeInvisibleOutlined /></Tooltip>
                                    }
                                </a>
                            }
                        </li>
                        <li>
                            {
                                collapsed ?
                                    <Tooltip title="Ticket của tôi">
                                        <a href="#" onClick={handleMine} className={filters.is_mine ? "active" : ""}>
                                            <span className="status-ticket"></span>
                                            <strong className="ml-1">({stats && stats.find(item => item.status === "MINE").count})</strong>
                                        </a>
                                    </Tooltip> :
                                    <a href="#" onClick={handleMine} className={filters.is_mine ? "active" : ""}>
                                        <span className="status-ticket"></span>
                                        <strong className="ml-2">Ticket của tôi ({stats && stats.find(item => item.status === "MINE").count})</strong>
                                    </a>
                            }
                        </li>
                        {
                            stats &&
                            stats
                                .filter(item => {
                                    let {status, count} = item;
                                    return status !== "MINE" && (status.is_show || (!status.is_show && count > 0));
                                })
                                .map(item => {
                                    let {status, count} = item;
                                    return  (
                                        <li key={status.id}>
                                            {
                                                collapsed ?
                                                    <Tooltip title={status.name}>
                                                        <a
                                                            href="#"
                                                            onClick={e => handleClick(e, status.id)}
                                                            className={`${statusIds.includes(status.id) ? "active" : ""} ${!status.is_show ? "disabled" : ""}`}
                                                        >
                                                            <span className="status-ticket" style={{backgroundColor: status.color}}></span>
                                                            <strong className="ml-1">({count})</strong>
                                                        </a>
                                                    </Tooltip> :
                                                    <a
                                                        href="#"
                                                        onClick={e => handleClick(e, status.id)}
                                                        className={`${statusIds.includes(status.id) ? "active" : ""} ${!status.is_show ? "disabled" : ""}`}
                                                    >
                                                        <span className="status-ticket" style={{backgroundColor: status.color}}></span>
                                                        <strong className="ml-2">{status.name} ({count})</strong>
                                                    </a>
                                            }
                                        </li>
                                    );
                                })
                        }
                    </ul>

                    <Divider style={{color: "#dddddd", fontSize: "20px"}} className="mt-0 mb-0 d-none d-md-flex">
                        <a href="#" onClick={handleCollapsed}>
                            {
                                collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />
                            }
                        </a>
                    </Divider>
                </Sider>
            }
        </>
    );
}

export default withSizes(mapSizesToProps)(StatusFilter);
