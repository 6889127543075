import React from "react";
import ChangeStatus from "./ChangeStatus";
import TicketSelect from "./TicketSelect";
import TicketDateTime from "./TicketDateTime";
import {useTranslation} from "react-i18next";
import TicketCheckbox from "./TicketCheckbox";
import _ from 'lodash';

export default function TicketMiddle({data, updateTicket, userCan, getTicket, settings}) {
    const {
        ticket,
        project,
        priority,
        handler,
        scope,
        ticketStatus,
        department
    } = data;
    const {t} = useTranslation();

    return (
        <div className="middle-detail-ticket">
            <div className="middle-item">
                <ChangeStatus
                    project_id={project.id}
                    ticket={ticket}
                    ticketStatus={ticketStatus}
                    updateTicket={updateTicket}
                    userCan={userCan}
                    getTicket={getTicket}
                />
            </div>

            <div className="middle-item">
                <p className="p-label">Người xử lý</p>
                <TicketSelect
                    ticket={ticket}
                    current={handler}
                    field="handler_id"
                    updateTicket={updateTicket}
                    type="handler"
                    value={handler ? handler.id : undefined}
                    userCan={userCan}
                />
            </div>
            {
                !!_.get(settings, "ACTIVE_PRIORITY", 1) &&
                <div className="middle-item">
                    <p className="p-label">Mức độ ưu tiên</p>
                    <TicketSelect
                        ticket={ticket}
                        current={priority}
                        field="priority_id"
                        updateTicket={updateTicket}
                        type="priority"
                        value={(priority && priority.is_active) ? priority.id : undefined}
                        userCan={userCan}
                    />
                </div>
            }

            <div className="middle-item">
                <p className="p-label">Ngày hết hạn</p>
                <TicketDateTime
                    ticket={ticket}
                    field="expired_at"
                    updateTicket={updateTicket}
                    userCan={userCan}
                />
            </div>
            {
                !!_.get(settings, "ACTIVE_SCOPE", 1) &&
                <div className="middle-item">
                    <p className="p-label">Phạm vi liên quan</p>
                    <TicketSelect
                        ticket={ticket}
                        current={scope}
                        field="scope_id"
                        updateTicket={updateTicket}
                        type="scope"
                        value={(scope && scope.is_active) ? scope.id : undefined}
                        userCan={userCan}
                    />
                </div>
            }
            {
                !!_.get(settings, "ACTIVE_DEPARTMENT", 1) &&
                <div className="middle-item">
                    <p className="p-label">Phòng ban / Đối tác</p>
                    <TicketSelect
                        ticket={ticket}
                        current={department}
                        field="department_id"
                        updateTicket={updateTicket}
                        type="department"
                        value={(department && department.is_active) ? department.id : undefined}
                        userCan={userCan}
                    />
                </div>
            }
            
            {
                ticket.department_id &&
                <div className="middle-item">
                    <TicketCheckbox
                        ticket={ticket}
                        field="is_shared_internal"
                        updateTicket={updateTicket}
                        userCan={userCan}
                    />
                </div>
            }
            
        </div>
    );
}
