import React, {useEffect, useState} from "react";
import {Row, Col, notification} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {t} from "../../../../system/i18n";
import {events} from "../../../../system/events";
import {EVENTS} from "../../../../layouts/constants";
import {url} from "../../../../system/routing";
import ListRoles from "./ListRoles";
import RoleDetail from "./RoleDetail";
import api from "../../services/api";
import _ from "lodash";
import useProject from "../../hooks/useProject";
import Error403 from "../../../App/Error/403";

export default function ManageRoles({match}) {
    const {params} = match;
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentRole, setCurrentRole] = useState(null);
    const { project, userCan } = useProject(params.id);

    useEffect(() => {
        if (project && project.id)
            fetchRoles();
    }, [project])

    useEffect(() => {
        if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
			document.title = `${project.name} - ${t('menu.manage_roles')}`;
            events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
            events.dispatch(EVENTS.HEADER_BREADCRUMB, [
                {
                    name: t('ticket:breadcrumb.manage_project'),
                    url: url.to('manage-project'),
                },
                {
                    name: t('ticket:label.project_id') + ': ' + project.name,
                    url: url.to('list-tickets', { id: project.id }),
                },
                {
                    name: t("breadcrumb.manage_roles")
                }
            ]);
        }
    }, [project, userCan]);

    function fetchRoles() {
        setLoading(true);
        api.getRoles(project.id)
            .then(res => {
                let roles = _.get(res, "data.roles");

                if (roles && roles.length > 0)
                    setCurrentRole(roles[0]);

                setRoles(roles);
            })
            .catch(err => {
                notification.error({message: t("roles:messages.load_fail")})
            })
            .finally(() => setLoading(false));
    }

    function updateRoles(role, isUpdate = true) {
        if (isUpdate) {
            setRoles(roles.map(item => item.id === role.id ? role : item));
        } else {
            setRoles(roles.filter(item => item.id !== role.id));
            setCurrentRole(roles.find(item => item.is_owner));
        }
    }

    function addRoles(role) {
        setCurrentRole(role);
        setRoles([...roles, role]);
    }

    if (loading || !currentRole) {
        return (
            <div className="site-content text-center pt-5 pb-5">
                <LoadingOutlined/>
            </div>
        );
    }

    if (userCan === null)
        return null;

    if (userCan && !userCan.configRole)
        return <Error403 />;

    return (
        <div className="site-content">
            <Row gutter={30}>
                <Col xs={{span: 24}} md={{span: 8}}  className="mb-3">
                    <ListRoles
                        roles={roles}
                        setCurrentRole={setCurrentRole}
                        currentRole={currentRole}
                        addRoles={addRoles}
                    />
                </Col>
                <Col xs={{span: 24}} md={{span: 16}}>
                    <div className="list-permission">
                        <h2 className="mb-3">Phân quyền</h2>

                        <RoleDetail
                            currentRole={currentRole}
                            setCurrentRole={setCurrentRole}
                            updateRoles={updateRoles}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
