import React from "react";
import {SortableContainer} from "react-sortable-hoc";
import SortableItem from "./SortableItem";

function SortableList({items, onShow, onClosed}) {
    return (
        <ul>
            {
                items &&
                items.map((status, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            status={status}
                            onShow={onShow}
                            onClosed={onClosed}
                        />
                    );
                })
            }
        </ul>
    );
}

export default SortableContainer(SortableList);
