import React from "react";
import {SortableElement} from "react-sortable-hoc";
import {Checkbox, Col, Row, Button, Radio} from "antd";
import {useTranslation} from "react-i18next";

function SortableItem({item, onShow, openModalUpdate}) {
    const {t} = useTranslation();

    function onChange(e) {
        onShow(item.id, {is_active: e.target.checked ? 1 : 0});
    }

    function onClick(e) {
        onShow(item.id, {is_default: 1});
    }

    return (
        <li className="setting-item">
            <Row>
                <Col span={3}>
                    <Checkbox
                        checked={item.is_active}
                        onChange={onChange}
                    />
                </Col>
                <Col xs={{span: 10}} md={{span: 6}}><Button className="pl-0" type="link" onClick={() => openModalUpdate("update", item)}>{item.name}</Button></Col>
                <Col className="d-none d-md-flex" span={6}>{item.description ? item.description : t("common:noText")}</Col>
                <Col xs={{span: 5}} md={{span: 3}} className="text-center">
                    <Radio
                        checked={item.is_default}
                        onClick={onClick}
                    />
                </Col>
                <Col span={6} className="text-right pr-4">
                    <span className="pointer-sort"></span>
                </Col>
            </Row>
        </li>
    );
}

export default SortableElement(SortableItem);
