import React, {useRef, useState} from "react";
import {AutoComplete, Input, notification, Spin, Form} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import lodash from "lodash";
import api from "../../services/api";
import {useTranslation} from "react-i18next";
import {events} from "../../../../system/events";
import {EVENTS_DEPARTMENT} from "../../services/constants";

function AddMember({userCan, projectId, getMembersOfDepartment, departmentId}) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    let formRef = useRef();

    const isEmail = (keyword) => {
        return keyword.includes("@");
    };
    
    function addMemberToDepartment(data) {
        setLoading(true);
        api.addMemberToDepartment(departmentId, data).then(res => {
            getMembersOfDepartment();
            setOptions([]);
            formRef.current.resetFields();
            notification.success({message: t("member:message.add_member_department_success")})
        }).catch((error) => {
            const responseData = lodash.get(error, "response.data", undefined);
            if (responseData && responseData.code === "INPUT_INVALID") {
                notification.error({message: t("member:error." + (lodash.keys(responseData.data.email)[0]).toLowerCase())})
            } else {
                notification.error({message: t("message.server_error")})
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const getListUserAddMember = lodash.debounce((keyword) => {
        if (!keyword) {
            setOptions([]);
            return;
        }
        setLoading(true);
        api.searchUser(departmentId, {keyword: keyword})
            .then(res => {
                let users = lodash.get(res, "data.users", []);
                if (users.length > 0) {
                    let options = users.map(user => ({value: user.email}));
                    setOptions(options);
                } else {
                    const isValid = isEmail(keyword);
                    if (isValid) {
                        setOptions([{value: keyword}])
                    } else {
                        setOptions([])
                    }
                }
            })
            .catch((error) => {
                notification.error({message: t("message.server_connection_error")})
            })
            .finally(() => {
                setLoading(false)
            })
    }, 500);
    const handleOnSelect = (email) => {
        handleSelectMemberAddDepartment(email);
    };

    const handleSelectMemberAddDepartment = (email) => {
        const isValid = isEmail(email);
        if (isValid) {
            addMemberToDepartment({email: email});
        } else {
            notification.error({message: t("validation:email", {attribute: "Email thành viên muốn thêm"})});
        }
    };


    return (
        <Spin spinning={loading}>
            <Form ref={formRef}>
                <Form.Item name="search_member">
                    <AutoComplete
                        options={options}
                        style={{width: "100%"}}
                        onChange={getListUserAddMember}
                        onSelect={handleOnSelect}
                    >
                        <Input
                            size="large"
                            suffix={<SearchOutlined style={{color: 'rgba(0,0,0,.45)'}}/>}
                            placeholder={t("member:placeholder.add_member_department")}
                            loading={loading.toString()}
                        />
                    </AutoComplete>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default AddMember;
