import React from "react";
import {SortableContainer} from "react-sortable-hoc";
import SortableItem from "./SortableItem";

function SortableList({items, onShow, openModalUpdate, project_id}) {
    return (
        <ul>
            {
                items &&
                items.map((item, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            item={item}
                            onShow={onShow}
                            openModalUpdate={openModalUpdate}
                            project_id={project_id}
                        />
                    );
                })
            }
        </ul>
    );
}

export default SortableContainer(SortableList);
