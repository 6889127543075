import React, {useState, useEffect} from "react";
import {Col, Row, Button, Table, notification} from "antd";
import {CloseSquareOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import api from "../../services/api";
import {auth} from "../../../Auth/services";
import {url} from "../../../../system/routing";
import lodash from "lodash";
import AddMember from "../../components/AddMember";
import {events} from "../../../../system/events";
import {EVENTS} from "../../../../layouts/constants";
import Error404 from "../../../App/Error/404";
import AddRole from "../../components/AddRole";
import useProject from "../../../Project/hooks/useProject";

function ManageMember(props) {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRowKeys, setSelectRowKeys] = useState([]);
    const [error, setError] = useState(false);
    const {t} = useTranslation();
    const projectId = lodash.get(props.match, "params.id", undefined);
    const hasSelected = selectedRowKeys.length > 0;
    const { project, userCan } = useProject(projectId);

    useEffect(() => {
        if (!lodash.isEmpty(project) && !lodash.isEmpty(userCan)) {
			document.title = `${project.name} - ${t('menu.member_management')}`;
            events.dispatch(EVENTS.MENU_LEFT, {project, userCan});
            events.dispatch(EVENTS.HEADER_BREADCRUMB, [
                {
                    name: t('ticket:breadcrumb.manage_project'),
                    url: url.to('manage-project'),
                },
                {
                    name: t('ticket:label.project_id') + ': ' + project.name,
                    url: url.to('list-tickets', { id: project.id }),
                },
                {
                    name: t("menu.member_management"),
                },
            ]);
        }
    }, [project, userCan]);

    useEffect(() => {
        if (project && userCan && project.id && userCan.showListMember) {
            getRoles();
            getMembers();
        }
    }, [project, userCan]);

    const columns = [
        {
            title: t("member:label.account_name"),
            dataIndex: 'name',
            width: "43%",
            render: (dataIndex, record) => <p>{record.member.name || record.member.user_name || record.member.email}</p>
        },
        {
            title: t("member:label.role"),
            dataIndex: 'role',
            width: "30%",
            render: (dataIndex, record) => {
                return <AddRole
                    memberInfo={record}
                    projectId={projectId}
                    userCan={userCan}
                    roles={roles}
                    handleMembers={getMembers}
                />
            }
        },
        {
            title: t("member:label.remove"),
            dataIndex: 'id',
            width: "23%",
            render: (dataIndex, record) => {
                if (userCan === null || (userCan && !userCan.removeMember))
                    return null;

                const roleInfo = lodash.find(roles, ['id', record.project_member.role_id]);
                let canRemove = roleInfo && !roleInfo.is_owner;
                return (
                    <div className={(auth.user().id !== record.member.id) ? "action-manage-member" : ""}>
                        {
                            canRemove &&
                            <a onClick={(e) => removeMember([record.member.id])} style={{color: "red"}}>
                                {
                                    (auth.user().id === record.member.id) ? t("member:label.leave_project") :
                                        <CloseSquareOutlined/>
                                }
                            </a>
                        }
                    </div>
                )
            },
        },
    ];

    function getMembers() {
        setLoading(true);
        api.getMembers(project.id).then(res => {
            const members = lodash.orderBy(lodash.get(res.data, "members", []), ['member.id'], ['desc']);
            if (members.length > 0) {
                let newMembers = [];
                let userLogin = {};
                members.map(user => {
                    const {member, project_member} = user;
                    const roleInfo = lodash.find(roles, ['id', project_member.role_id]);
                    if (member.id === auth.user().id) {
                        userLogin = user;
                    } else {
                        (roleInfo && roleInfo.is_owner) ? newMembers.unshift(user) : newMembers.push(user)
                    }
                });
                if (!lodash.isEmpty(userLogin))
                    newMembers.unshift(userLogin);
                setMembers(newMembers);
            }
        }).catch((error) => {
            let responseData = lodash.get(error, "response", undefined);
            if (responseData) {
                switch (responseData.data.code) {
                    case 403:
                        setError(403);
                        break;
                    case 404:
                        setError(404);
                        break;
                    default:
                        notification.error({message: t("message.server_connection_error")});
                        break;
                }
            } else {
                notification.error({message: t("message.server_connection_error")})
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    function getRoles() {
        setLoading(true);
        api.getRoles(project.id).then(res => {
            setRoles(lodash.get(res.data, "roles", []));
        }).catch((error) => {
            let responseData = lodash.get(error, "response", undefined);
            if (responseData) {
                switch (responseData.data.code) {
                    case 403:
                        setError(403);
                        break;
                    case 404:
                        setError(404);
                        break;
                    default:
                        notification.error({message: t("message.server_connection_error")});
                        break;
                }
            } else {
                notification.error({message: t("message.server_connection_error")})
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const removeMember = (userIds = []) => {
        let data = userIds.length > 0 ? {userIds} : {userIds: selectedRowKeys};
        setLoading(true);
        api.removeMember(project.id, data).then(res => {
            if (userIds.includes(auth.user().id)) {
                url.redirectTo("home");
            } else {
                setSelectRowKeys([]);
                getMembers();
                notification.success({message: t("member:message.remove_member_success")})
            }
        }).catch((error) => {
            notification.error({message: t("message.server_error")})
        }).finally(() => {
            setLoading(false);
        })
    };


    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => {
            const roleInfo = lodash.find(roles, ['id', record.project_member.role_id]);
            return ({
                disabled: (roleInfo && roleInfo.is_owner) || record.member.id === auth.user().id,
                className: ((roleInfo && roleInfo.is_owner) || record.member.id === auth.user().id) && "disabled-checkbox-remove-member"
            });
        },
    };

    if (userCan === null)
        return null;

    if (userCan && !userCan.showListMember)
        return <Error404 />;

    const renderManagerMember =
        <>
            <div className="mb-3">
                <Col md={{offset: 8, span: 8}} xs={{offset: 0, span: 24}}>
                    <AddMember
                        projectId={projectId}
                        getMembers={getMembers}
                        userCan={userCan}
                    />
                </Col>
            </div>
            <div>
                <Col md={{offset: 2, span: 20}}>
                    {
                        userCan && userCan.removeMember &&
                        <Row style={{marginBottom: 16}}>
                            <Button type="primary" onClick={() => removeMember()} disabled={!hasSelected}
                                    loading={loading}>
                                {t("member:label.remove_member")}
                            </Button>
                        </Row>
                    }
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        rowKey={(record) => record.member.id}
                        dataSource={members}
                        pagination={false}
                        loading={loading}
                    />
                </Col>
            </div>
        </>;


    return (
        <div className="site-content">
            {
                error ? <Error404/> : renderManagerMember
            }
        </div>
    );
}

export default ManageMember;
