import React, {useEffect, useState} from "react";
import {Button, Layout, Col, Row, notification} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {arrayMove} from "react-sortable-hoc";
import SortableList from "./SortableList";
import api from "../../../../TicketStatus/services/api";
import _ from "lodash";
import {t} from "../../../../../system/i18n";
import StatusForm from "./StatusForm";

const {Header, Content} = Layout;

export default function StatusSetting({ project_id, updateCategoryService }) {
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStatuses();
    }, [project_id]);

    function addNewItem(newItem) {
        let newItems = items;
        if (newItem.is_closed)
            newItems = items.map(item => ({...item, is_closed: false}));

        setItems([...newItems, {...newItem}]);
    }

    function updateItem(updatedItem) {
        let newItems = items.map(item => {
            if (item.id === updatedItem.id)
                return updatedItem;
            else
                return updatedItem.is_closed ? {...item, is_closed: false} : item;
        });

        setItems(newItems);
    }

    function fetchStatuses() {
        setLoading(true);
        api.getStatuses(project_id)
            .then(res => {
                setItems(_.get(res, "data.statuses", []));
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("ticket:label.type_id").toLowerCase()})});
            })
            .finally(() => setLoading(false));
    }

    function onShow(status_id, params) {
        api.isShow(project_id, status_id, params)
            .then(res => {
                updateCategoryService();
                updateItem(_.get(res, "data.ticketStatus", {}));
                notification.success({message: t("common:message.update_success_by_attribute", { attribute: t("status:label.status").toLowerCase() })})
            })
            .catch(err => {
                notification.error({message: t("common:message.update_failed_by_attribute", { attribute: t("status:label.status").toLowerCase() })})
            });
    }

    function onClosed(status_id, params) {
        api.isClosed(project_id, status_id, params)
            .then(res => {
                updateCategoryService();
                updateItem(_.get(res, "data.ticketStatus", {}));
                notification.success({message: t("common:message.update_success_by_attribute", { attribute: t("status:label.status").toLowerCase() })})
            })
            .catch(err => {
                notification.error({message: t("common:message.update_failed_by_attribute", { attribute: t("status:label.status").toLowerCase() })})
            });
    }

    function onSortEnd({oldIndex, newIndex}) {
        if (items && items.length === 1) return false;

        let newItems = arrayMove(items, oldIndex, newIndex);
        let params = newItems.map((item, index) => ({id: item.id}));
        setItems(newItems);
        api.updatePosition(project_id, {statuses: params})
            .then(res => {
                notification.success({message: t("common:message.update_success_by_attribute", { attribute: t("status:label.status").toLowerCase() })})
            })
            .catch(err => {
                notification.error({message: t("common:message.update_failed_by_attribute", { attribute: t("status:label.status").toLowerCase() })})
            });
    }

    function shouldCancelStart(e) {
        if (e.target.tagName === "SPAN" && e.target.className === "pointer-sort")
            return false;

        return true;
    }

    return (
        <div>
            <Header className='title-page bg-white border-bottom d-flex justify-content-between align-items-center pl-4 pr-4'>
                <h2>{t("settings:title.status_list")}</h2>

                <StatusForm
                    project_id={project_id}
                    addNewItem={addNewItem}
                    updateItem={updateItem}
                    updateCategoryService={updateCategoryService}
                />
            </Header>
            <Content className="category-page">
                <div className="category-header">
                    <Row>
                        <Col xs={{span: 2}} md={{span: 2}}>{t("settings:label.active")}</Col>
                        <Col xs={{span: 4}} md={{span: 2}} className="d-none d-md-flex">{t("settings:label.color")}</Col>
                        <Col xs={{span: 13}} md={{span: 5}}>{t("settings:label.name", {attribute: t("settings:title.status_name")})}</Col>
                        <Col className="d-none d-md-block" md={{span: 6}}>{t("settings:label.description")}</Col>
                        <Col xs={{span: 5}} md={{span: 3}} className="text-center" style={{width: "250px"}}>{t("settings:label.final_status")}</Col>
                        <Col xs={{span: 4}} md={{span: 6}} className="text-right"><span className="pr-2">{t("settings:label.position")}</span></Col>
                    </Row>
                </div>

                {
                    loading ?
                        <div className="p-4 text-center" style={{fontSize: "24px"}}>
                            <LoadingOutlined />
                        </div> :
                        <SortableList
                            items={items}
                            onSortEnd={onSortEnd}
                            shouldCancelStart={shouldCancelStart}
                            onShow={onShow}
                            onClosed={onClosed}
                        />
                }

            </Content>
        </div>
    );

}
