import React, { useState, useEffect } from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Input, notification, Button, Col, Checkbox, Row } from 'antd';
import useValidatedMessages from '../../../system/hooks/useValidatedMessages';
import api from '../services/api';
import { trans } from '../../../system/i18n';

const Texarea = Input.TextArea;

export default function ModalUpdateDepartment(props) {
	const { t } = useTranslation();
	const validateMessages = useValidatedMessages();

	const {
		typeForm,
		visible,
		currentData,
		projectId,
		onSuccess,
		handleModal,
		hasDefault,
		refeshDepartmentData,
	} = props;

	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const isOpenConfirm = lodash.isEmpty(hasDefault) ? false : hasDefault.id !== currentData.id;

	useEffect(() => {
		if (typeForm === 'update') {
			const { name, description, is_active, is_default } = currentData;
			form.setFieldsValue({ name, description, is_active, is_default });
		}
	}, [typeForm]);

	function apiUpdateTicketType(params) {
		if (typeForm === 'create') {
			return api.createDepartment(projectId, params);
		} else {
			return api.updateDepartment(currentData.id, params);
		}
	}

	const updateTicketType = (params) => {
		apiUpdateTicketType(params)
			.then((res) => {
				refeshDepartmentData();
				onSuccess();
				notification.success({
					message: t('settings:messages.department.success', { action: t(`btn.${typeForm}`) }),
				});
			})
			.catch((error) =>
				notification.error({
					message: t('settings:messages.department.error', { action: t(`btn.${typeForm}`) }),
				})
			)
			.finally(() => {
				handleModal();
				setLoading(false);
			});
	};

	function handleOk() {
		form.validateFields().then((values) => {
			setLoading(true);
			if (isOpenConfirm && values['is_default']) {
				return Modal.confirm({
					title: t('settings:messages.confirm_set_default', { name: hasDefault.name }),
					onOk: () => updateTicketType(values),
					onCancel: () => cancelConfirm(),
					confirmLoading: true,
					okText: t('btn.ok'),
					cancelText: t('btn.cancel'),
				});
			} else {
				updateTicketType(values);
			}
		});
	}

	function handleCancel() {
		handleModal();
	}

	function cancelConfirm() {
		setLoading(false);
		form.setFieldsValue({ is_default: false });
	}

	function handleReset() {
		if (typeForm === 'update') {
			const { name, description, is_active, is_default } = currentData;
			form.setFieldsValue({ name, description, is_active, is_default });
		} else {
			form.resetFields();
		}
	}
	return (
		<Modal
			title={t(`settings:title.update_department`, {
				action: t(`btn.${typeForm}`),
			}).toUpperCase()}
			visible={visible}
			onOk={handleOk}
			onCancel={handleCancel}
			destroyOnClose
			confirmLoading={loading}
			okText={t('common:btn.save')}
			cancelText={t('common:btn.cancel')}
			footer={[
				<Button key='back' onClick={() => handleReset()}>
					{t(`btn.reset_form`)}
				</Button>,
				<Button key='submit' type='primary' loading={loading} onClick={() => handleOk()}>
					{t(`btn.${typeForm}`)}
				</Button>,
			]}
		>
			<Form
				name='update_department'
				form={form}
				validateMessages={validateMessages}
				preserve={false}
				layout='vertical'
			>
				<Form.Item
					className='mb-3'
					label={trans('settings:label.name', {
						attribute: 'Phòng ban/Đối tác',
					})}
					name='name'
					rules={[{ max: 255, required: true }]}
				>
					<Input />
				</Form.Item>

				<Form.Item className='mb-3' label={t('settings:label.description')} name='description'>
					<Texarea rows={5} />
				</Form.Item>

				<Row>
					{typeForm === 'update' && (
						<Col xs={{ span: 10 }} md={{ span: 6 }}>
							<Form.Item name='is_active' valuePropName='checked'>
								<Checkbox>{t('settings:label.active')}</Checkbox>
							</Form.Item>
						</Col>
					)} 
				</Row>
			</Form>
		</Modal>
	);
}
