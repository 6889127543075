import React, {useState} from "react";
import {CloudDownloadOutlined, DeleteOutlined} from "@ant-design/icons";
import api from "../../../../services/api";
import {notification} from "antd";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Modal} from "antd";
import {events} from "../../../../../../system/events";
import {EVENTS} from "../../../../services/constants";

function FileItem({ticket, attachment, updateTicket}) {
    const {t} = useTranslation();
    const [isShown, setIsShown] = useState(false);

    function handleClick() {
        api.deleteAttachment(ticket.id, attachment.id)
            .then(res => {
                let attachments = _.get(res, "data.attachments", []);
                updateTicket({attachments});
                events.dispatch(EVENTS.DELETE_FILE);
                notification.success({message: t("ticket:messages.delete_attachment_success")});
            })
            .catch(err => {
                let response = _.get(err, "response", {});
                switch (response.status) {
                    case 404:
                        notification.error({message: t("common:message.404")});
                        return;
                    case 403:
                        notification.error({message: t("common:message.403")});
                        return;
                    default:
                        notification.error({message: t("common:message.server_error")});
                        return;
                }
            })
    }

    function handleCancel() {
        setIsShown(false);
    }

    function handleShow(e) {
        e.preventDefault();
        setIsShown(true);
    }

    return (
        <>
            <div className="mb-2 file-item" key={attachment.id}>
                {
                    [".jpeg",".png"].some(item => attachment.name.indexOf(item) > -1) ?
                        <>
                            <a onClick={handleShow} href={attachment.url}><CloudDownloadOutlined /> {attachment.name}</a>
                            <Modal
                                visible={isShown}
                                onCancel={handleCancel}
                                style={{textAlign: "center", padding: "0px"}}
                                centered
                                footer={null}
                                width={1000}
                                maskClosable={false}
                            >
                                <img src={attachment.url} style={{maxWidth: "100%"}} alt={attachment.name} />
                            </Modal>
                        </> :
                        <a href={attachment.url}><CloudDownloadOutlined /> {attachment.name}</a>
                }
                <span onClick={handleClick} className="ml-2"><DeleteOutlined /></span>
            </div>
        </>
    )

}

export default FileItem;
