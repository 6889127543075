import React, { useEffect, useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input, Button, Col, Row, notification } from 'antd';

import useProject from '../../hooks/useProject';
import UploadAvatar from '../../../App/Upload/UploadAvatar';
import useValidatedMessages from '../../../../system/hooks/useValidatedMessages';
import Error403 from '../../../App/Error/403';

import { events } from '../../../../system/events';
import { url } from '../../../../system/routing';
import { EVENTS } from '../../../../layouts/constants';
import api from '../../services/api';
import ServicesProject from '../../services/ServicesProject';

const { Option } = Select;
const { TextArea } = Input;

// list of rules for every input
const validatedRules = {
	name: [{ required: true, max: 255, type: 'string' }],
	is_public: [{ required: true }],
};

const SETTINGS = (t) => [
	{
		groupName: t('project:label.ding_talk_configuration'),
		configs: [
			{
				label: 'APP_KEY',
				name: 'DING_APP_KEY',
			},
			{
				label: 'APP_SECRET',
				name: 'DING_APP_SECRET',
			},
			{
				label: 'AGENT_ID',
				name: 'DING_AGENT_ID',
			},
		],
	},
	{
		groupName: t('project:label.dingtalk_chatbot_configuration'),
		configs: [
			{
				label: 'ACCESS_TOKEN',
				name: 'DING_CHAT_BOT_ACCESS_TOKEN',
			},
			{
				label: 'SECRET_KEY',
				name: 'DING_CHAT_BOT_SECRET',
			},
		],
	},
	{
		groupName: t('project:label.zalo_configuration'),
		configs: [
			{
				label: 'OA_ID',
				name: 'ZALO_OA_ID',
			},
			{
				label: 'OA_TOKEN',
				name: 'ZALO_OA_TOKEN',
			},
		],
	},
];

function ConfigProject({ match }) {
	const { t } = useTranslation();
	const { params } = match;
	const {
		projectData: { creator, settings, project, userCan },
		projectData,
		setProjectData,
	} = useProject(params.id);

	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState('');
	const validateMessages = useValidatedMessages();

	useLayoutEffect(() => {
		const { name, description, is_public, logo } = project || {};
		const {
			DING_APP_KEY,
			DING_APP_SECRET,
			DING_AGENT_ID,
			DING_CHAT_BOT_ACCESS_TOKEN,
			DING_CHAT_BOT_SECRET,
			ZALO_OA_ID,
			ZALO_OA_TOKEN,
		} = settings || {};
		setImageUrl(logo);
		form.setFields([
			{ name: 'name', value: name },
			{ name: 'description', value: description || '' },
			{ name: 'is_public', value: is_public ? 1 : 0 },
			{ name: 'DING_APP_KEY', value: DING_APP_KEY },
			{ name: 'DING_APP_SECRET', value: DING_APP_SECRET },
			{ name: 'DING_AGENT_ID', value: DING_AGENT_ID },
			{ name: 'DING_CHAT_BOT_ACCESS_TOKEN', value: DING_CHAT_BOT_ACCESS_TOKEN },
			{ name: 'DING_CHAT_BOT_SECRET', value: DING_CHAT_BOT_SECRET },
			{ name: 'ZALO_OA_ID', value: ZALO_OA_ID },
			{ name: 'ZALO_OA_TOKEN', value: ZALO_OA_TOKEN },
		]);
	}, [project, settings]);

	useEffect(() => {
		if (!_.isEmpty(project) && !_.isEmpty(userCan)) {
			document.title = `${project.name} - ${t('menu.project_settings')}`;
			dispatchEvent(project, userCan);
		}
	}, [project, userCan]);

	function dispatchEvent(project, userCan) {
		events.dispatch(EVENTS.MENU_LEFT, { project, userCan });
		events.dispatch(EVENTS.HEADER_BREADCRUMB, [
			{
				name: t('ticket:breadcrumb.manage_project'),
				url: url.to('manage-project'),
			},
			{
				name: t('ticket:label.project_id') + ': ' + project.name,
				url: url.to('list-tickets', { id: project.id }),
			},
			{
				name: t('breadcrumb.project_settings'),
			},
		]);
	}

	function handleSubmit() {
		form.validateFields().then((values) => {
			setIsSubmitting(true);
			let formData = new FormData();
			for (const prop in values) {
				if (values[prop] !== undefined) formData.append(prop, values[prop]);
			}

			api
				.configProject(project.id, formData)
				.then((response) => {
					const responseData = _.get(response, 'data', {});

					dispatchEvent(responseData.project, userCan);
					ServicesProject.updateData({ ...ServicesProject.data, ...responseData });
					setProjectData({ ...projectData, ...responseData });

					notification.success({ message: t('project:messages.update_success') });
				})
				.catch(() => notification.error({ message: t('project:messages.update_fail') }))
				.finally(() => setIsSubmitting(false));
		});
	}

	function handleUpload(file) {
		form.setFields([{ name: 'logo', value: file }]);
	}

	if (userCan === null) return null;

	if (userCan && !userCan.configProject) return <Error403 />;

	return (
		<div className='site-content'>
			<div className='project-settings'>
				<h1 className='a-list--title a-text--uppercase text-center'>
					{t('project:label.config_project')}: {project.name}
				</h1>
				<div className='project-settings-creator-info text-center mt-2 mb-5'>
					Được tạo bởi <span>{creator.name || creator.username || creator.email}</span> ngày{' '}
					<span>{moment(project.created_at).format('DD/MM/YYYY')}</span>
				</div>

				<Form
					labelCol={{ span: 6 }}
					labelAlign='left'
					wrapperCol={{ span: 18 }}
					validateMessages={validateMessages}
					size='default'
					form={form}
				>
					<div className='project-settings-label-block'>{t('project:label.project_info')}</div>
					<Row>
						<Col xs={{ span: 24 }} md={{ span: 18 }}>
							<Form.Item
								name='name'
								label={t('project:label.name')}
								rules={validatedRules.name}
								colon={false}
							>
								<Input placeholder={t('project:placeholder.name')} name='name' />
							</Form.Item>

							<Form.Item
								name='is_public'
								label={t('project:label.is_public')}
								rules={validatedRules.is_public}
								colon={false}
							>
								<Select
									name='is_public'
									placeholder={t('project:placeholder.is_public')}
									defaultValue={form.getFieldValue('is_public')}
								>
									<Option value={0}>Private</Option>
									<Option value={1}>Public</Option>
								</Select>
							</Form.Item>

							<Form.Item
								name='description'
								label={t('project:label.description')}
								rules={[{ type: 'string' }]}
								colon={false}
							>
								<TextArea
									row={4}
									placeholder={t('project:placeholder.description')}
									name='description'
									style={{ height: 150, resize: 'none' }}
								/>
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 6 }}>
							<Form.Item name='logo' className='pl-md-4'>
								<UploadAvatar
									onChange={handleUpload}
									imageUrl={imageUrl}
									setImageUrl={setImageUrl}
									accept='image/jpeg, image/png'
									listType='picture-card'
									showUploadList={false}
									maxFileSize={5}
									className='upload-logo'
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col xs={{ span: 24 }} md={{ span: 18 }}>
							{SETTINGS(t).map((setting) => (
								<>
									<div className='project-settings-label-block'>{setting.groupName}</div>
									{setting.configs.map((config) => (
										<Form.Item
											name={config.name}
											label={config.label}
											rules={[{ type: 'string' }]}
											colon={false}
										>
											<Input placeholder={config.label} name={config.name} />
										</Form.Item>
									))}
								</>
							))}

							<Row>
								<Col xs={{ span: 24 }} md={{ span: 18, offset: 6 }}>
									<Button
										type='primary'
										htmlType='submit'
										onClick={handleSubmit}
										loading={isSubmitting}
										className='pl-4 pr-4'
									>
										{t('btn.update')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
}

export default ConfigProject;
