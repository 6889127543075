import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import config from '../../config/i18n';
import vi from '../../locales/vi';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {vi},
        lng: config.language,
        fallbackLng: config.fallbackLanguage,
        defaultNS: 'common',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
