import React from "react";
import {url} from "../../../system/routing";
import {
	AppstoreOutlined,
	SettingOutlined,
	TeamOutlined,
	UnorderedListOutlined,
	UserOutlined,
    BuildOutlined,
    AlertOutlined,
    ApartmentOutlined
} from '@ant-design/icons';
import {t} from "../../../system/i18n";

export default function(id) {
    return (
        [
            {
                name: t("ticket:menu.manage_list"),
                url: url.to("list-tickets", {id}),
                icon: <AppstoreOutlined />,
                permissions: ['showListTicket']
            },
            {
                name: t("menu.member_management"),
                url: url.to("manage-member", {id}),
                icon: <TeamOutlined />,
                permissions: ['showListMember']
            },
            {
                name: t('menu.project_settings'),
                icon: <SettingOutlined />,
                permissions: ['configCategory', 'configRole'],
                submenu: [
                    {
                        name: t('menu.project_settings'),
                        url: url.to('project-settings', { id }),
                        icon: <BuildOutlined />,
                        permissions: ['configProject']
                    },
                    {
                        name: t('menu.category_settings'),
                        url: url.to('category-settings', {id, type: "status"}),
                        icon: <UnorderedListOutlined />,
                        permissions: ['configCategory']
                    },
                    {
                        name: t('menu.notification_settings'),
                        url: url.to('notification-settings', { id }),
                        icon: <AlertOutlined />,
                        permissions: ['configProject']
                    },
                    {
                        name: t('menu.departments'),
                        url: url.to('departments', { id }),
                        icon: <ApartmentOutlined />,
                        permissions: ['configDepartment']
                    },
                    {
                        name: t('menu.manage_roles'),
                        url: url.to('manage-roles', {id}),
                        icon: <UserOutlined />,
                        permissions: ['configRole']
                    }
                ]
            }
        ]
    );
}
