import React, {useEffect, useState} from "react";
import ServicesMember from "../../../../../Member/services/ServicesMember";
import {Avatar, notification} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import api from "../../../../services/api";
import _ from "lodash";
import {t} from "../../../../../../system/i18n";

export default function ListMember({project_id, ticket, updateTickets, setTicket, keyword}) {
    const [members, setMembers] = useState([]);
    const [filterMembers, setFilterMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMembers();
    }, [project_id])

    useEffect(() => {
        // Nếu có từ khóa tìm kiếm thì hiển thị member theo từ khóa
        // Nếu không thì hiển thị 5 member được thêm mới nhất
        if (keyword !== "")
            setFilterMembers(members.filter(member => {
                let {name, username} = member;
                return (name.indexOf(keyword) > -1 || username.indexOf(keyword) > -1);
            }));
        else
            setFilterMembers(members.filter((member, index) => index < 5));
    }, [members, keyword])

    function fetchMembers() {
        ServicesMember.list(project_id)
            .then(res => {
                let resMembers = res.map(item => {
                    let {member, project_member} = item;
                    return {...member, added_at: project_member.created_at};
                });
                setMembers(_.sortBy(resMembers, ["added_at"]).reverse());
            });
    }

    function handleClick(value) {
        setLoading(true);
        api.updateTicket(ticket.id, {handler_id: value})
            .then(res => {
                let newTicket = _.get(res, "data.ticket", {});
                let newHandler = _.get(res, "data.handler", {});
                // Cập nhật lại danh sách tickets với ticket vừa thay đổi
                updateTickets({ticket: newTicket, handler: newHandler});
                // Ẩn clear ticket đang được cập nhật và ẩn popup
                setTicket(null);
                notification.success({message: t("ticket:messages.update_success", {attribute: t(`ticket:label.handler_id`)})});
            })
            .catch(err => {
                notification.error({message: t("ticket:messages.update_fail", {attribute: t(`ticket:label.handler_id`)})});
            })
            .finally(() => setLoading(false));
    }

    return (
        <div className="list-member-modal mt-3 pl-2 pr-2">
            {
                loading ?
                    <p className="text-center">
                        <LoadingOutlined />
                    </p> :
                    filterMembers.map(item => {
                        return (
                            <p className="p-2" onClick={() => handleClick(item.id)}>
                                <Avatar shape="square" size={36} src={item.avatar}>{item.name || item.username}</Avatar>
                                <span className="ml-2">{item.name || item.username}</span>
                            </p>
                        );
                    })
            }
        </div>
    );
}
