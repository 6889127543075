import {api} from '../../../system/api';
import {PREFIX_URL} from "../../../system/api/constants";

export default {
    createProject: (params) => api.post(PREFIX_URL.PROJECT, params),
    getProject: (project_id) => api.get(`projects/${project_id}`),
    getListProject: (params) => api.get(PREFIX_URL.PROJECT, {
        params,
        singleRequest: true,
        loading: false
    }),
    setProjectPriorities: (data) => api.put("/auth/user/setting", data),
    connectDingTalk: (project_ids) => api.post(`projects/connect-providers`, project_ids),
    disConnectDingTalk: (project_id) => api.delete(`projects/${project_id}/connect-providers/ding`),
    getRoles: (project_id) => api.get(`projects/${project_id}/roles`),
    updateRole: (role_id, data) => api.put(`roles/${role_id}`, data),
    addRole: (project_id, params) => api.post(PREFIX_URL.PROJECT + `/${project_id}/roles`, params),
    deleteRole: (role_id) => api.delete(`roles/${role_id}`),
    getPermission: (role_id) => api.get(`/roles/${role_id}`, {
        singleRequest: true,
        loading: false
    }),
    changePermission: (role_id, data) => api.put(`/roles/${role_id}/permissions`, data),
    configProject: (project_id, data) => api.post(`/projects/${project_id}`, data),
    disConnectZalo: (project_id) => api.delete(`projects/${project_id}/connect-providers/zalo`),
    updateSettings: (project_id, data) => api.put(`/projects/${project_id}/settings`, data),
};
