import React, {useEffect, useState} from "react";
import {Modal, Row, Col} from "antd";
import {events} from "../../../../../../system/events";
import {EVENTS} from "../../../../services/constants";
import TicketInput from "../../../DetailTicket/components/TicketDetail/TicketInput";
import {t} from "../../../../../../system/i18n";
import TicketSelect from "../../../DetailTicket/components/TicketDetail/TicketSelect";
import TicketDateTime from "../../../DetailTicket/components/TicketDetail/TicketDateTime";
import FollowTicket from "../../../DetailTicket/components/FollowTicket";
import RichEditor from "../../../../components/RichEditor";
import _ from 'lodash';

export default function EditFastly({project_id, updateTickets, userCan, settings}) {
    const [ticket, setTicket] = useState(null);

    const handleOk = () => {
        setTicket(null);
    };

    const handleCancel = () => {
        setTicket(null);
    };

    useEffect(() => {
        return events.listen([EVENTS.EDIT_TICKET_FASTLY], (event, payload) => setTicket(payload));
    }, []);

    // Update tickets on Kanban screen and ticketDetail in EditFastly modal
    function updateTicket(data) {
        setTicket({...ticket, ...data});
        updateTickets(data);
    }

    if (ticket === null)
        return null;

    return (
        <Modal
            title="CẬP NHẬT TICKET"
            visible={ticket !== null}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={700}
            maskClosable={false}
        >
            <TicketInput
                ticket={ticket.ticket}
                field="subject"
                updateTicket={updateTicket}
                userCan={userCan}
            />
            <Row className="mt-3" gutter={20}>
                {
                    !!_.get(settings, "ACTIVE_PRIORITY", 1) &&
                    <Col span={8}>
                        <div className="middle-item">
                            <p className="p-label">Mức độ ưu tiên</p>
                            <TicketSelect
                                ticket={ticket.ticket}
                                current={ticket.priority}
                                field="priority_id"
                                updateTicket={updateTicket}
                                type="priority"
                                value={(ticket.priority && ticket.priority.is_active) ? ticket.priority.id : undefined}
                                userCan={userCan}
                            />
                        </div>
                    </Col>
                }
                <Col span={8}>
                    <div className="middle-item">
                        <p className="p-label">Ngày hết hạn</p>
                        <TicketDateTime
                            ticket={ticket.ticket}
                            field="expired_at"
                            updateTicket={updateTicket}
                            userCan={userCan}
                        />
                    </div>
                </Col>
                <Col span={8}>
                    <p style={{marginTop: "32px"}}>
                        <FollowTicket
                            data={ticket}
                            reloadFollower={() => {}}
                            updateTicket={updateTicket}
                        />
                    </p>
                </Col>
            </Row>
            <h3 className="sperator pt-2 pb-2 pl-3 pr-3 mt-4 mb-3 text-uppercase">{t("ticket:label.description")}</h3>
            <div>
                <RichEditor
                    field="description"
                    ticket={ticket.ticket}
                    updateTicket={updateTicket}
                    userCan={userCan}
                />
            </div>
        </Modal>
    );
}
