import common from './common.json';
import validation from './validation.json';
import auth from './auth.json';
import project from './project.json';
import ticket  from './ticket.json';
import member from './member.json';
import status from './status.json';
import user from './user.json';
import settings from './settings.json';
import roles from './roles';

export default {
    common,
    validation,
    auth,
    project,
    ticket,
    member,
    status,
    user,
    settings,
    roles
};
