import React, {useEffect} from "react";
import _ from "lodash";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {t, trans} from "../../../../../../system/i18n";
import {LIST_FIELD_IS_FORMAT_DATE} from "../../../../../../system/api/constants";

function LogItem({log}) {
    const {t} = useTranslation();
    const creator = _.get(log, "creator", {});
    const {payload} = log;

    function renderMessage() {
        switch (log.action.trim()) {
            case "TICKET.UPDATE":
                const changes = _.get(payload, "changes", {});
                let messages = [];
                if (changes) {
                    for (let prop in changes) {
                        let {to, from} = changes[prop];
                        let trans_from = "";
                        let trans_to = "";
                        if (prop === "is_shared_internal") {
                            trans_from = t("ticket:messages.is_shared_internal." + from);
                            trans_to = t("ticket:messages.is_shared_internal." + to);
                        } else {
                            trans_from = (from instanceof Object) ? from.name :
                            (LIST_FIELD_IS_FORMAT_DATE.includes(prop) ? moment(from).format("DD/MM/YYYY") : from);

                            trans_to = (to instanceof Object) ? to.name :
                            (LIST_FIELD_IS_FORMAT_DATE.includes(prop) ? moment(to).format("DD/MM/YYYY") : to);
                        }

                        if (from === null) {
                            messages.push(trans("common:logs.create", {
                                attribute: <strong>{t(`ticket:label.${prop}`)}</strong>,
                                to: prop != "description" ? trans_to : <strong dangerouslySetInnerHTML={{__html: trans_to ? trans_to.replace(/(<([^>]+)>)/gi, " ") : ""}}></strong>
                            }));
                        } else {
                            messages.push(trans("common:logs.update", {
                                attribute: <strong>{t(`ticket:label.${prop}`)}</strong>,
                                to: prop != "description" ? trans_to : <strong dangerouslySetInnerHTML={{__html: trans_to ? trans_to.replace(/(<([^>]+)>)/gi, " ") : ""}}></strong>,
                                from: prop != "description" ? trans_from : <strong dangerouslySetInnerHTML={{__html: trans_from ? trans_from.replace(/(<([^>]+)>)/gi, " ") : ""}}></strong>
                            }));
                        }
                    }
                }
                return messages.map(item => item);

            case "TICKET.ADD_ATTACHMENT":
                return trans(`common:logs.${log.action.trim()}`, {message: <strong>{payload.name}</strong>});

            case "TICKET.REMOVE_ATTACHMENT":
                return trans(`common:logs.${log.action.trim()}`, {message: <strong>{payload.name}</strong>});

            case "TICKET.CHANGE_STATUS":
                const from_status = _.get(payload, "from_status", {});
                const to_status = _.get(payload, "to_status", {});
                const note = _.get(payload, "note", "");
                return trans(`common:logs.${log.action}`, {
                    attribute: <strong>{t(`ticket:label.status`)}</strong>,
                    from: <strong>{from_status.name}</strong>,
                    to: <strong>{to_status.name}</strong>,
                    note: note ? <> {t(`ticket:label.reason`).toLowerCase()} <strong>"{note}"</strong></> : ''
                });

            case "TICKET.DELETE_COMMENT":
                return trans(`common:logs.${log.action}`, {message: <strong>"{payload.content}"</strong>});

            case "TICKET.RATE_STAR":
                return trans(`common:logs.${log.action}`, {
                    rate: <strong>{payload.rate_star}</strong>,
                    rate_note: payload.rate_note ? <> {t(`ticket:label.rate_note`)} <strong>"{payload.rate_note}"</strong></> : ''
                });

            case "TICKET.FOLLOW":
                const follower = _.get(payload, 'follower') || {};
                if (creator.id === follower.id) {
                    return t('ticket:btn.follow');
                }
                return trans(`common:logs.${log.action}`, {
                    name: <strong>{follower.name || follower.username || follower.email}</strong>,
                });

            case "TICKET.UNFOLLOW":
                const unfollower = _.get(payload, 'unfollower') || {};
                if (creator.id === unfollower.id) {
                    return t('ticket:btn.unfollow');
                }
                return trans(`common:logs.${log.action}`, {
                    name: <strong>{unfollower.name || unfollower.username || unfollower.email}</strong>,
                });
            default:
                return trans(`common:logs.${log.action}`);
        }
    }

    return (
        <div className="log-item">
            {
                creator.avatar ?
                    <span><img src={creator.avatar} alt={creator.name || creator.username}/></span> :
                    <span>{(creator.name || creator.username).substr(0, 1)}</span>
            }
            <div>
                <p><i><strong>{creator.name || creator.username}</strong>, {moment(log.created_at).format("HH:mm DD/MM/YYYY")}</i></p>
                <div>{ renderMessage() }</div>
            </div>
        </div>
    );
}

export default LogItem;
