import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";
import {url} from "../../../system/routing";
import {events} from "../../../system/events";
import {EVENTS} from "../services/constants";

class ServicesProject extends BaseServiceData {
    constructor() {
        super();
        events.listen(EVENTS.FETCH_PROJECT_INFO_AGAIN, (event, payload) => {
            this.fetchData()
        });
    }
    runApi(resolve) {
        api.getProject(this.project_id)
            .then(res => {
                let data = _.get(res, "data", {});
                this.data = data;
                resolve(data);
            })
            .catch(err => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    url.redirectTo('error-403');
                else if (status === 404)
                    url.redirectTo('error-404');
                else
                    notification.error({message: t('project:messages.load_fail')});
            })
            .finally(() => {
                this.fetching = false;
            });
    }
}

export default new ServicesProject();
