import React from 'react';
import {useTranslation} from 'react-i18next';
import {Result, Button} from 'antd';
import {url} from '../../../system/routing';

export default function Error404() {
    const {t} = useTranslation();

    return (
        <div className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
            <Result
                status="404"
                title="404"
                subTitle={t('message.404')}
                extra={<Button type="primary" onClick={() => url.redirect('/')}>{t('btn.back_home')}</Button>}
            />
        </div>
    );
}
