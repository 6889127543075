import React, {useState, useEffect, useRef} from 'react';
import {message} from 'antd';
import {Upload, Button} from "antd";
import {FileImageOutlined, UploadOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

function UploadMulti(props) {
    const {t} = useTranslation();
    const {onChange} = props;
    const files = useRef([]);

    function beforeUpload(file) {
        if (props.fileType) {
            if (!props.fileType.includes((file.type).toLowerCase())) {
                message.error(t("validation:mimetypes", {
                    attribute: (file.name || t('common:label.avatar')),
                    values: props.accept
                }));
                return false;
            }
        }

        if (props.maxFileSize) {
            const validMaxSize = (file.size / 1024 / 1024) > props.maxFileSize;
            if (validMaxSize) {
                message.error(t("validation:max.file", {
                    attribute: (file.name || t('common:label.avatar')),
                    max: props.maxFileSize
                }));
                return false;
            }
        }

        return false;
    }

    function handleChange(event) {
        const {file} = event;
        if (file instanceof Blob) {
            files.current = [...files.current, file];
        } else {
            if (file.uid)
                files.current = files.current.map(item => item.uid !== file.uid);
        }

        onChange(files.current);

        // Dùng để clear dữ liệu sau khi chọn vẫn đc lưu tạm khi dùng để upload file ngay (Màn hình chi tiết ticket)
        if (props.clearData)
            files.current = [];
    }

    return (
        <div>
            <Upload
                {...props}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                multiple={true}
            >
                {
                    props.children ?
                        props.children :
                        <Button
                            loading={props.loading}
                            icon={<UploadOutlined />}
                        >Chọn file tải lên</Button>
                }
            </Upload>
        </div>
    )
}

export default UploadMulti;
