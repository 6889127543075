import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import BaseServiceData from "../../App/services/BaseServiceData";
import {t} from "../../../system/i18n";
import {events} from "../../../system/events";
import {EVENTS_MEMBERS} from "./constants";
class ServicesMember extends BaseServiceData {
    constructor() {
        super();
        events.listen(EVENTS_MEMBERS.FETCH_MEMBERS_AGAIN, (event, payload) => {
            const {projectId} = payload;
            if (this.project_id === null) {
                this.project_id = projectId;
            }
            this.fetchData()
        });
    }

    runApi(resolve) {
        api.getLoginedMembers(this.project_id)
            .then(res => {
                let data = _.get(res, "data.members", []);
                this.data = data;
                resolve(data);
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("ticket:label.member").toLowerCase()})});
            })
            .finally(() => {
                this.fetching = false;
            });
    }
}

export default new ServicesMember();
